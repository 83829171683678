import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PasswordChangeRequest } from '../../model/casa/casa-model';

class FormData {
  username: string;
  password: string;
  passwordVerify: string;
}

@Component({
    selector: 'personal-profile-password-change',
    templateUrl: './personal-profile-password-change.component.html',
    styleUrls: ['./personal-profile-password-change.component.scss'],
    standalone: false
})
export class PersonalProfilePasswordChangeComponent implements OnInit, DoCheck {

  formData = new FormData()
  invalid: boolean = true
  @Input() email: string
  @Input() busy: boolean
  @Output() changePassword = new EventEmitter<PasswordChangeRequest>();
  @Input()
  saved: boolean = false

  constructor() {}

  ngOnInit(): void {
    this.formData.password = ''
    this.formData.passwordVerify = ''
  }

  ngDoCheck(): void {
    this.invalid = this.formData.password !== this.formData.passwordVerify? true : false
  }

  onSubmit() {
    const request = new PasswordChangeRequest()
    request.email = this.email
    request.password = this.formData.password
    return this.changePassword.emit(request)
  }

  isBusy() {
    return this.busy
  }

  isInvalid() {
    return this.invalid
  }

  getButtonContent() {
    return this.saved? 'BUTTON.PASSWORD_SAVED' : 'BUTTON.SAVE'
  }
}
