import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { environment } from 'src/environments/environment';
import { AppVersionService } from './app-version.service';
import {AuthorizationServiceProvider} from "../auth_profile/services/authorization-service.provider";
import {filter, repeat, switchMap, take, zip} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  constructor(appVersion: AppVersionService,
              authProvider: AuthorizationServiceProvider) {
    if(environment.logToSentry)
    {
      authProvider.getAuthDetailsService().pipe(
        switchMap(api =>
            zip(api.getUserEmail(), api.getSubject(), appVersion.getVersion())
        ),
        repeat({delay: 100}),
        filter(([email, subject, _]) => !!email && !!subject),
        take(1)
      ).subscribe(([email, subject, version]) =>
        Sentry.getCurrentScope().addEventProcessor(event => {
          Sentry.setTag("userEmail", email)
          Sentry.setTag("subject", subject)
          return new Promise(resolve =>
            resolve({
              ...event,
              release: version
            })
          )
        })
      )
    }
   }

  reportError(e: Error) {
    Sentry.captureException(e);
  }

  static async initSentry() {
    if(environment.logToSentry)
      Sentry.init({
        dsn: environment.sentryDsn,
        sampleRate: 0.3,
        integrations: [
          // Registers and configures the Tracing integration,
          // which automatically instruments your application to monitor its
          // performance, including custom Angular routing instrumentation
          Sentry.browserTracingIntegration(),
          // Registers the Replay integration,
          // which automatically captures Session Replays
          Sentry.replayIntegration(),
          Sentry.feedbackIntegration({
            autoInject: false
          }),
        ],
        tracePropagationTargets: ["localhost", environment.sentryTarget],
        environment: environment.envName,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
      });
  }
}
