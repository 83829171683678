import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactUs } from 'src/app/books/model/book-model';
import { ContactUsService } from '../contact-us.service';
import { finalize, tap } from 'rxjs'

@Component({
    selector: 'contact-us-page',
    templateUrl: './contact-us-page.component.html',
    styleUrls: ['./contact-us-page.component.scss'],
    standalone: false
})
export class ContactUsPageComponent implements OnInit {

  busy = false

  constructor(private contactUsService: ContactUsService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  receiveMsg($event: ContactUs) {

    console.log($event)
    this.contactUsService.sendContactUs($event).pipe(
      tap(_ => this.busy = true),
      finalize( () => {
        this.busy = false
      })
    )
    .subscribe( _ => {
      this.router.navigate(
        ['./result'],
        {
          relativeTo: this.route,
          queryParamsHandling: 'merge'
        }
      )
    })
  }

  onCancel() {
    this.router.navigate(['in-app','login'])
  }
}
