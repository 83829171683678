import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { IconRegistryService } from 'src/app/services/icons/IconRegistryService';
import {SessionInfoService} from "../../../services/session-info.service";
import {ExamSession, ExerciseSession} from "../../model/cspa/personal";
import {ThemeService} from "../../../services/theme.service";
import { FontSizeService } from 'src/app/services/font-size.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-instruction',
    templateUrl: './instruction.component.html',
    styleUrls: ['./instruction.component.scss'],
    standalone: false
})
export class InstructionComponent implements OnInit, OnDestroy {
  @Input() withSettings: boolean
  @Input() isOffcanvas: boolean
  @Input()
  get exerciseType() {
    return this._exerciseType
  }
  set exerciseType(type: string) {
    this._exerciseType = type.toLowerCase()
    this.getTranslations()
  }
  theme: string
  fontSize: number
  exerciseName: string
  progressBarType: string
  htmlContent: SafeHtml
  session: ExamSession | ExerciseSession
  private _exerciseType: string
  sessionInfoSubscription: Subscription

  constructor(
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private iconRegistryService: IconRegistryService,
    private sessionInfoService: SessionInfoService,
    public themeService: ThemeService,
    public fontSizeService: FontSizeService
  ) {
    themeService.getTheme().subscribe(theme =>
      this.theme = theme
    )
  }

  ngOnInit() {
    if(this.exerciseType == null) this.getExerciseType()
    localStorage.getItem('fontSize') ? this.fontSize = +localStorage.getItem('fontSize') : localStorage.setItem('fontSize', '2')
  }

  ngOnDestroy(): void {
    if(this.sessionInfoSubscription) this.sessionInfoSubscription.unsubscribe()
  }

  getExerciseType() {
  this.sessionInfoSubscription = this.sessionInfoService.getSessionInfo().subscribe(i => {
      this.session = i.session
      this.exerciseType = this.getQuestions()[+i.questionNb - 1]?.question.definition['@type']
    })
  }

  isExam() {
    return this.session?.path.includes("exam")
  }

  getQuestions() {
    return this.isExam() ?
      (this.session as ExamSession)?.currentPart.questions
      : (this.session as ExerciseSession)?.questions
  }

  setExerciseTranslations(){
    switch(this.exerciseType){
      case 'qa':
        this.exerciseName = 'QUESTION_ANSWER'
        this.progressBarType = "PROGRESS_BAR_DEFAULT"
        break;
      case 'dict':
        this.exerciseName = 'DICTATION'
        this.progressBarType = "PROGRESS_BAR_DICTATION"
        break;
      case 'exams':
        this.exerciseName = 'EXAMS'
        this.progressBarType = "PROGRESS_BAR_EXAM"
        break;
      default:
        this.exerciseName = 'REVISION_TESTS'
        this.progressBarType = "PROGRESS_BAR_DEFAULT"
        break;
    }
  }

  setExamTranslations(){
    switch(this.exerciseType){
      case 'qa':
        this.exerciseName = 'QUESTION_ANSWER'
        this.progressBarType = "PROGRESS_BAR_DEFAULT"
        break;
      case 'dict':
        this.exerciseName = 'DICTATION'
        this.progressBarType = "PROGRESS_BAR_DICTATION"
        break;
      case 'exams':
        this.exerciseName = 'EXAMS'
        this.progressBarType = "PROGRESS_BAR_EXAM"
        break;
      case 'ef':
        this.exerciseName = 'EF'
        this.progressBarType = "PROGRESS_BAR_EXAM"
        break;
      case 'jmb':
        this.exerciseName = 'JMB'
        this.progressBarType = "PROGRESS_BAR_EXAM"
        break;
      case 'cor':
        this.exerciseName = 'COR'
        this.progressBarType = "PROGRESS_BAR_EXAM"
        break;
      case 'pic':
        this.exerciseName = 'PIC'
        this.progressBarType = "PROGRESS_BAR_EXAM"
        break;
      case 'audio':
        this.exerciseName = 'AUDIO'
        this.progressBarType = "PROGRESS_BAR_EXAM"
        break;
    }
  }

  getTranslations(){
    this.isExam() ? this.setExamTranslations() : this.setExerciseTranslations()
    let translationBase = this.isExam() ? 'EXAM' : 'EXERCISES'
    this.translateService.get(`${translationBase}.INSTRUCTIONS.${this.exerciseName}.INSTR`, this.fillIcons())
    .subscribe((value) => this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(value) )
  }

  icons = [
    ['speaker_icon', 'speaker-icon'],
    ['mic_icon', 'icon-mic'],
    ['stop_icon', 'stop-circle'],
    ['play_icon', 'play-icon'],
    ['pause_icon', 'pause-icon'],
    ['right_arrow_icon','next-arrow-icon'],
    ['left_arrow_icon','back-arrow-icon'],
    ['text_message_icon', 'text-icon']
  ]

  fillIcons() {
    let iconsList = {}
    this.icons.forEach(icon => iconsList[icon[0]] = this.iconRegistryService.getIcon(icon[1]))
    return iconsList
  }

  getDismiss() {
    return this.isOffcanvas && "offcanvas"
  }

  isExamType() {
    return this.exerciseType == 'exams'
  }

  changeFontSize(change: number){
    //increase
    (change == 1 && this.fontSize < 4) && this.fontSizeService.changeFontSize(++this.fontSize);
    //decrease
    (change == -1 && this.fontSize > 1) && this.fontSizeService.changeFontSize(--this.fontSize);
  }
}
