import { Component, Input, OnInit } from '@angular/core';
import { ApiProduct } from 'src/app/col/model/products';
import {StudentShoppingCartService} from "../../../../services/student-shopping-cart.service";

@Component({
    selector: 'app-product-box',
    templateUrl: './product-box.component.html',
    styleUrls: ['./product-box.component.scss'],
    standalone: false
})
export class ProductBoxComponent implements OnInit {
  @Input()
  product: ApiProduct;
  @Input()
  desc: String = '';
  @Input()
  notAvailable: String = null;

  constructor(private shoppingCart: StudentShoppingCartService) {}

  addItem() {
    this.shoppingCart.addItem(this.product);
  }

  ngOnInit(): void {}

  getPricePerLesson() {
    if (!this.product) return null;
    const price = this.product.defaultPrice.price;
    let weeks = 0;
    if (this.product?.defaultVariantName?.description)
      weeks = +this.product.defaultVariantName.description.split(" ")[0];
    else if (this.isPlanFlexible())
      weeks = +this.product.defaultVariantName.name.split(" ")[0];
    const currency = this.product.defaultPrice.currency;
    return `${(price / weeks).toFixed(2)} ${currency}`;
  }

  isPlanFlexible() {
    return this.product && this.product?.code.includes("pag");
  }

  isEnbus() {
    return this.product?.productCode.includes("en.bs");
  }

  getProductType() {
    let type = this.product?.variantLabels[0].split(".").reverse()[0]
    if(type == 'light') type = 'LIGHT'
    else if(type == 'std') type = 'STANDARD'
    else if(type == 'int') type = 'INTENSIVE'
    return `COL.COURSES.PRODUCTS.PLANS.${type}.TITLE`;
  }

  getEnbusType() {
    let type = this.product?.variantLabels[0].split(".").reverse()[0].toUpperCase()
    return `COL.COURSES.PRODUCTS.PLANS.ENBUS_TYPE.${type}`;
  }
  getWeeksAmount() {
    return +this.product?.defaultVariantName?.name.split(" ")[2];
  }

  getWeeksAmountTranslation() {
    let amount = this.getWeeksAmount();
    let translation = "COL.COURSES.PRODUCTS.PLANS.WEEKS_PLURAL."
    if(amount === 1) translation += 'ONE'
    else if(amount === 12 || amount === 72) translation += 'MANY'
    else translation += 'OTHER'
    return translation
  }

  getLessonsAmount() {
    return +this.product?.defaultVariantName?.description.split(" ")[0];
  }

  getLessonsAmountTranslation() {
    let amount = this.getLessonsAmount();
    let translation = "COL.COURSES.PRODUCTS.PLANS.LESSONS_PLURAL."
    if(amount === 1) translation += 'ONE'
    else if(amount === 72) translation += 'MANY'
    else translation += 'OTHER'
    return translation
  }
  getCreditsAmount() {
    return this.product?.defaultVariantName.name.split(" ")[0];
  }
  getCourseName() {
    let name
    if (this.product?.productCode.includes("en.bs")) name = "ENBUS";
    else if (this.product?.productCode.includes("sp")) name = "SP";
    else name = "EN";
    return `COL.COURSES.${name}`
  }
}
