<div class="d-flex flex-column justify-content-between h-100">
  <a
        class="navbar-brand d-none d-md-flex flex-column mb-5 align-items-center"
      >
        <app-icon name="callan-logo" class="mb-3 logo-sm"></app-icon>
      </a>
  <ul
    class="navbar-nav flex-column h-100 overflow-auto"
    #main
    [ngClass]="isHidden(main) ? 'd-none' : 'd-flex'"
  >
    <div>
      <div
        class="d-md-none header d-flex align-items-center justify-content-between mb-4"
      >
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn btn-primary rounded-circle btn-circle me-3 pe-none p-0"
            [class]="profilePhoto ? 'avatar' : ''"
            [ngStyle]="getAvatar()"
          >
            <app-icon *ngIf="!profilePhoto" name="smile"></app-icon>
          </button>
          <div class="d-flex flex-column mb-2 pe-4 right-border">
            <span class="small">{{ getTeacherName() }}</span>
            <span class="xsmall muted">{{ getTeacherRole() }}</span>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary rounded-circle btn-circle text-reset p-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          (click)="onClose()"
        >
          <app-icon name="close" class="icon-white"></app-icon>
        </button>
      </div>
      <!-- <li class="nav-item" [attr.data-bs-dismiss]="getDismiss()" [ngClass]="{active: isActivePath('dashboard')}" routerLink="./dashboard">
          <app-icon name="dashboard"></app-icon>
          <a class="nav-link" aria-current="page">{{ 'NAVIGATION.DASHBOARD' | translate }}</a>
      </li> -->
      <li
        *ngIf="isCallanOnlineTeacher() && isColFunctionalityOn()"
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('panel') }"
        routerLink="./panel/worktimes"
      >
        <app-icon name="calendar-edit"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.CALENDAR" | translate
          }}</a>
      </li>
      <li
        class="nav-item"
        *ngIf="!disableVideo && !isColFunctionalityOn()"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('lessons') }"
        routerLink="./lessons"
      >
        <app-icon name="calendar"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.LESSONS" | translate
          }}</a>
      </li>
      <li
        class="nav-item"
        *ngIf="isColFunctionalityOn()"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('lessons') }"
        [routerLink]="getLessonLink()"
      >
        <app-icon name="calendar"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.LESSONS" | translate
          }}</a>
      </li>
      <li
        class="nav-item py-1 my-0 py-md-2"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('student')}"
        routerLink="./students"
      >
        <app-icon name="person" class="icon-secondary"></app-icon>
        <a class="nav-link">Students</a>
      </li>
      <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('ebooks') }"
        routerLink="./ebooks"
      >
        <app-icon name="book"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.EBOOKS" | translate
        }}</a>
      </li>
      <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('exercises') }"
        routerLink="./exercises"
      >
        <app-icon name="edit"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.EXERCISES" | translate
        }}</a>
      </li>
      <li
        *ngIf="!isCallanOnlineTeacher() && !isAppNative()"
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('groups') }"
        routerLink="./groups/school/{{schoolId}}"
      >
        <app-icon name="dashboard"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.GROUPS" | translate
        }}</a>
      </li>
      <!-- <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('notifications') }"
        routerLink="./notifications"
      >
        <app-icon name="notification"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.NOTIFICATIONS" | translate
        }}</a>
      </li>
      <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('faq') }"
        routerLink="./faq"
      >
        <app-icon name="faq"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.FAQ" | translate
        }}</a>
      </li> -->
      <li
        *ngIf="!isAppNative()"
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('documents') }"
        routerLink="./documents"
      >
        <app-icon name="file"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.DOCUMENTS" | translate
          }}</a>
      </li>
      <li
        class="nav-item"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('contact-us') }"
        routerLink="./contact-us"
      >
        <app-icon name="email"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.CONTACT_US" | translate
        }}</a>
      </li>
    </div>
    <div class="mt-auto">

      <hr class="dropdown-divider">
      <li *ngIf="theme !== 'light'" class="nav-item" [attr.data-bs-dismiss]="getDismiss()" (click)="themeService.changeTheme('light')">
        <app-icon [name]="'light-mode'"></app-icon>
        <span class="nav-link">{{ 'NAVIGATION.LIGHT_MODE' | translate }}</span>
      </li>
      <li *ngIf="theme === 'light'" class="nav-item" [attr.data-bs-dismiss]="getDismiss()" (click)="themeService.changeTheme('dark')">
        <app-icon [name]="'dark-mode'"></app-icon>
        <span class="nav-link">{{ 'NAVIGATION.DARK_MODE' | translate }}</span>
      </li>
      <li
        class="nav-item"
      >
      <app-icon name="sentry-speaker"></app-icon>
        <a class="nav-link" aria-current="page" [attr.data-bs-dismiss]="getDismiss()" id="reportABugButton">{{ 'BUTTON.REPORT_BUG' | translate }}</a>
      </li>
      <hr class="dropdown-divider d-md-none" />
      <li
        class="nav-item d-md-none"
        [attr.data-bs-dismiss]="getDismiss()"
        [ngClass]="{ active: isActivePath('profile') }"
        routerLink="./profile/profile-form"
      >
        <app-icon name="profile"></app-icon>
        <a class="nav-link" aria-current="page">{{
          "NAVIGATION.EDIT_PROFILE" | translate
        }}</a>
      </li>
      <hr class="dropdown-divider" />

      <li class="nav-item" (click)="onLogout()">
        <app-icon name="log-out"></app-icon>
        <span class="nav-link">{{ "BUTTON.LOG_OUT" | translate }}</span>
      </li>
    </div>
    <span class="text-muted text-end xsmall opacity-50 pe-sm-1">
    v: {{ appVersionService.getVersion() | async }}
    </span>
  </ul>
</div>
