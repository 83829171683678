import {Component, OnInit} from '@angular/core';
import {Chapter, DefinitionBase, ExerciseSet} from "../../../cspa/model/cspa/struct";
import {Student} from "../../../auth_profile/model/casa/casa-model";
import {ExerciseSession, ItemAvailability} from "../../../cspa/model/cspa/personal";
import {Page, Pageable} from "../../../utils/pageable";
import {ActivatedRoute} from "@angular/router";
import {combineLatest, from, map, mergeAll, Observable, of, switchMap, tap} from "rxjs";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {SpinnerService} from "../../../utils/services/spinner.service";

@Component({
    selector: 'app-teacher-student-cspa-monitor',
    templateUrl: './teacher-student-cspa-monitor.component.html',
    styleUrls: ['./teacher-student-cspa-monitor.component.scss'],
    standalone: false
})
export class TeacherStudentCspaMonitorComponent implements OnInit {

  schoolId: number;
  exerciseSets: Map<string, ExerciseSet> = null;
  chaptersCache = new Map<string, Chapter[]>();
  setAvailabilities: ItemAvailability[] = [];
  innerAvailabilities: Map<string, ItemAvailability> = new Map<string, ItemAvailability>();
  private student: Student;
  private setSessions: Map<string, Page<ExerciseSession>> = new Map<string, Page<ExerciseSession>>();

  constructor(
    private route: ActivatedRoute,
    private provider: NativeServiceApiProvider,
    private authService: AuthorizationServiceProvider,
    private spinnerService: SpinnerService
  ) {
  }

  ngOnInit() {
    this.loadSchoolId().pipe(
      switchMap(_ => this.loadStudent()),
      switchMap(_ => this.loadExerciseSets()),
      switchMap(_ => this.loadData())
    ).subscribe();
  }


  private loadSchoolId() {
    return this.authService.getAuthDetailsService().pipe(
      switchMap(api => api.getSelfSchoolId()),
      tap(schoolId => this.schoolId = schoolId)
    )
  }

  private loadStudent() {
    return this.route.parent.paramMap.pipe(
      switchMap(params =>
        this.getStudent(this.schoolId, +params.get('studentId'))
      ),
      tap<Student>(student => this.student = student)
    )
  }

  private getStudent(schoolId: number, studentId: number) {
    return this.provider.video().pipe(
      switchMap(api => api.getStudent(schoolId, studentId))
    )
  }

  private loadData() {
    return this.spinnerService.trace(this.getAvailabilities('', 1)).pipe(
      map<ItemAvailability[], ItemAvailability[]>(allSetAvailabilities =>
        allSetAvailabilities.filter(it => it.assigned || it.available && this.exerciseSets.has(it.path))
      ),
      tap<ItemAvailability[]>(setAvailabilities =>
        this.setAvailabilities = setAvailabilities
      ),
      switchMap<ItemAvailability[], Observable<Observable<[Chapter[], ItemAvailability[], Page<ExerciseSession>]>>>(availabilities =>
        from(availabilities.map(availability =>
          combineLatest([
            this.loadExerciseSetStructure(availability.path),
            this.loadInnerAvailabilities(availability.path),
            this.loadSetSessionsPage(availability.path)
          ])
        ))
      ),
      mergeAll()
    )
  }

  loadInnerAvailabilities(path: string) {
    return this.getAvailabilities(path, 3).pipe(
      tap(setInnerAvailabilities => setInnerAvailabilities.forEach(av => this.innerAvailabilities.set(av.path, av)))
    );
  }

  getAvailabilities(path: string, depth: number) {
    return this.provider.cspa().pipe(
      switchMap(api => api.listPersonAvailabilities(this.schoolId, this.student.person.id, path, depth))
    )
  }

  loadExerciseSetStructure(path: string): Observable<Chapter[]> {
    if (this.chaptersCache.has(path))
      return of(this.chaptersCache.get(path));

    return this.provider.cspa().pipe(
      switchMap(api => api.getChapters(path)),
      tap(setChapters => this.chaptersCache.set(path, setChapters))
    )
  }

  private loadExerciseSets(): Observable<any> {
    if (this.exerciseSets && this.exerciseSets.size > 0) return of(this.exerciseSets)
    return this.provider.cspa().pipe(
      switchMap(api => api.listExerciseSets()),
      map(sets =>
        sets.reduce((res, current) => res.set(current.path, current), new Map<string, ExerciseSet>())
      ),
      tap(setsMap => this.exerciseSets = setsMap)
    )
  }

  getSetName(availability: ItemAvailability) {
    return this.exerciseSets.get(availability.path).name;
  }

  getItemLastSubmit(availability: ItemAvailability) {
    return availability.lastSubmit;
  }

  listAvailableStages(path: string): Chapter[] {
    const setChapters = this.chaptersCache.get(path);
    if (!setChapters) return [];
    return setChapters.filter(
      chapter => {
        const av = this.innerAvailabilities.get(chapter.path);
        if (!av) return false;
        return av.available || av.assigned;
      }
    );
  }

  getItemShortName(item: DefinitionBase) {
    return item.shortName;
  }

  getItemByPath(path: string) {
    return this.innerAvailabilities.get(path);
  }

  getStatusClass(path: string) {
    const availability = this.innerAvailabilities.get(path);

    if (!availability) return 'locked';
    if (!availability.assigned || !availability.available)
      return 'locked';
    if (availability.score === 0) return 'ready';
    else if (availability.score < 0.5) return 'bad';
    else if (availability.score < 0.95) return 'enough';
    else return 'perfect';
  }

  buildPath(...items: DefinitionBase[]) {
    return items.map(defItem => defItem.path).join("_");
  }

  private loadSetSessionsPage(path: string): Observable<Page<ExerciseSession>> {
    return this.provider.cspa().pipe(
      switchMap(api => api.findSessions(this.schoolId, this.student.person.id, `${path}_`, Pageable.of(0, 5, ["finishDate,DESC"]))),
      tap<Page<ExerciseSession>>(setSessions => this.setSessions.set(path, setSessions))
    );
  }

  countSetSessions(availability: ItemAvailability) {
    if (!availability) return 0;
    const setSessionsPage = this.setSessions.get(availability.path);
    if (!setSessionsPage) return 0;
    return setSessionsPage.totalElements;
  }

  getSetSessions(setAvailability: ItemAvailability): ExerciseSession[] {
    const setSessions = this.setSessions.get(setAvailability.path);
    if (!setSessions) return [];
    return setSessions.content;
  }

  getChapterHeader(setAvailability: ItemAvailability) {
    return this.getSetSessions(setAvailability)[0].chapterName.split(' ')[0]
  }

  getExerciseNameHeader(setAvailability: ItemAvailability) {
    return this.getSetSessions(setAvailability)[0].exerciseName.split(' ')[0]
  }

  getChapter(session: ExerciseSession) {
    return session.chapterName.split(' ')[1]
  }

  getSection(session: ExerciseSession) {
    return session.sectionName.split(',')[0]
  }

  getExerciseName(session: ExerciseSession) {
    return session.exerciseName.split(' ')[1]
  }

  getSessionDate(session: ExerciseSession) {
    return session.finishDate;
  }

  isExam(path: string) {
    return path.includes("exam")
  }
}
