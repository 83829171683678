import {Component, OnInit} from '@angular/core';
import {ExerciseSetEnum} from "../../model/cspa/struct";
import {ExamSession, ExamSessionPart} from "../../model/cspa/personal";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {SpinnerService} from "../../../utils/services/spinner.service";
import {of, switchMap, tap} from "rxjs";
import {CspaApi} from "../../services/api/cspa.api";
import {SessionInfoService} from "../../../services/session-info.service";
import { FontSizeService } from 'src/app/services/font-size.service';

@Component({
    selector: 'app-exam-part-details',
    templateUrl: './exam-part-details.component.html',
    styleUrls: ['./exam-part-details.component.scss'],
    standalone: false
})
export class ExamPartDetailsComponent implements OnInit {
  uuId: string
  section: string
  chapter: string
  exerciseSet: string
  sessionPartId: number
  session: ExamSession
  sessionParts: ExamSessionPart[]

  constructor(
    private provider: NativeServiceApiProvider,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: SpinnerService,
    private progressInfoService: SessionInfoService,
    public fontSizeService: FontSizeService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      tap( (params: ParamMap) => {
        this.uuId = params.get("sessionId")
        this.section = params.get("section");
        this.chapter = params.get("chapter");
        this.exerciseSet = params.get("exerciseSet");
        this.sessionPartId = +params.get("sessionPartId") - 1;
      }),
      switchMap( _ => this.loadData()),
    ).subscribe()
    this.fontSizeService.setFromLocalStorage()
  }

  loadData() {
    return this.spinner.trace(
      this.provider.cspa().pipe(
        switchMap( (api: CspaApi) => api.startExamSessionPart(this.uuId, this.sessionPartId)),
        tap(session => {
          this.session = session
          this.sessionParts = session.parts
          this.progressInfoService.setSessionInfo({questionNb: "1", session: this.session})
        })
      )
    )
  }

  ngOnDestroy(){
    this.fontSizeService.setToDefault()
  }

  getAccent() {
    return `accent stg-${this.session?.chapterName.slice(-2).trim()}`
  }

  getExerciseType() {
    return this.session?.sectionName.split(',')[0]
  }

  getChapter() {
    return this.session?.chapterName
  }

  getExamName() {
    return this.session?.examName
  }

  getExamPartName() {
    return this.session?.currentPart?.partName
  }

  getExerciseSet() {
    return ExerciseSetEnum[this.exerciseSet]
  }

  getCurrentPartNr() {
    return this.session?.parts.findIndex(it => it.path == this.session.currentPart.path) + 1
  }

  goToExerciseSetsWithFilter() {
    this.router.navigate(
      ['../../../../../../'],
      {
        relativeTo: this.route,
        queryParams: { language: `${this.exerciseSet}`},
        queryParamsHandling: 'merge'
      }
    ).then()
  }

  finishSession() {
    return this.provider.cspa().pipe(
      switchMap( api => api.finishExamSessionPart(this.uuId).pipe(
          switchMap(session =>
            session.partsDone == session.nbOfParts ? api.finishExamSession(this.uuId) : of(session))
        )
      )
    );
  }

  goBack() {
    this.finishSession().subscribe(_ => {
      this.router.navigate(
        ['../../'],
        {
          relativeTo: this.route,
          queryParamsHandling: 'merge'
        }
      ).then()
    });
  }

  isSampleExam() {
    return this.session?.examName.split(" ")[1] == "0"
  }
}
