import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionInfoService } from 'src/app/services/session-info.service';
import {ExamSession, ExerciseSession} from "../../model/cspa/personal";
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-exercises-options',
    templateUrl: './exercises-options.component.html',
    styleUrls: ['./exercises-options.component.scss'],
    standalone: false
})
export class ExercisesOptionsComponent implements OnInit, OnDestroy {
  exerciseType: string
  exerciseName: string
  questionType: string
  sessionInfoSubscription: Subscription
  constructor(
    private sessionInfoService: SessionInfoService,
  ) { }

  ngOnInit(): void {
    this.sessionInfoSubscription = this.sessionInfoService.getSessionInfo().subscribe(i => {
      this.exerciseType = i.session?.sectionName.split(',')[0]
      this.exerciseName = i.session?.chapterName.concat(', ', this.getExerciseName(i.session))
      this.questionType = this.getQuestions(i.session)[+i.questionNb - 1]?.question.definition['@type']
    })
  }

  ngOnDestroy(): void {
    if(this.sessionInfoSubscription) this.sessionInfoSubscription.unsubscribe()
  }

  isExam(session: ExamSession | ExerciseSession) {
    return session.path.includes("exam")
  }

  getExerciseName(session: ExamSession | ExerciseSession) {
    return this.isExam(session) ?
      (session as ExamSession)?.examName
      : (session as ExerciseSession)?.exerciseName
  }

  getQuestions(session: ExamSession | ExerciseSession) {
    return this.isExam(session) ?
      (session as ExamSession)?.currentPart.questions
      : (session as ExerciseSession)?.questions
  }
}
