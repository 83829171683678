import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Dates } from 'src/app/utils/calendar-utils';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import {
  ApiCourseContext,
  ApiProductContext, ApiLessonInstanceBase,
} from '../../../model/rest-model';
import {StudentRestServiceImpl} from "../../../services/student/student-rest-impl.service";
import {Subscription, switchMap} from "rxjs";
import {CourseFilterComponent} from "../../../../utils/course-filter/course-filter.component";
import { ProductContextService } from 'src/app/col/services/product-context.service';

@Component({
    selector: 'app-progress-card',
    templateUrl: './progress-card.component.html',
    styleUrls: ['./progress-card.component.scss'],
    standalone: false
})
export class ProgressCardComponent implements OnInit, OnDestroy {
  private _studentId: number;
  private _lang: string;
  private courseContexts: ApiCourseContext[];
  private productContext: ApiProductContext;
  private currentIndex: number = null;
  isLoading = true;
  private _showNoContext = true
  CourseFilterComponent = CourseFilterComponent
  productContextSubscription: Subscription

  @Input()
  set studentId(value: number) {
    this._studentId = value;
    this.fireUpdate();
  }
  get studentId() {
    return this._studentId;
  }
  @Input()
  public set langCode(lang: string) {
    this._lang = lang;
    this.fireUpdate();
  }
  public get langCode() {
    return this._lang
  }
  @Input()
  public set showNoContext(showNoContext: boolean) {
    this._showNoContext = showNoContext;
  }
  get showNoContext() {
    return this._showNoContext
  }

  constructor(
    private studentRest: StudentRestServiceImpl,
    private spinner: SpinnerService,
    private productContextService: ProductContextService
  ) {}

  ngOnInit(): void {
    this.productContextSubscription = this.productContextService.productContext.subscribe(_ => this.fireUpdate())
  }

  ngOnDestroy(): void {
    if(this.productContextSubscription) this.productContextSubscription.unsubscribe()
  }

  fireUpdate() {
    if (!this.studentId || !this.langCode) return;
    this.spinner
      .trace<ApiProductContext>(
        this.studentRest.getProvaContext(this.studentId, this.langCode).pipe(
          switchMap(_ => this.studentRest.getProductContext(this.studentId, this.langCode))
        )
      )
      .subscribe((context) => this.afterDataLoad(context));
  }

  private afterDataLoad(context: ApiProductContext) {
    this.currentIndex = null;
    this.isLoading = false;
    this.productContext = context
    if (!context) return;
    this.courseContexts = context.courseContexts;
    if (this.courseContexts.length > 0) {
      this.currentIndex = this.courseContexts.length - 1;
    }
  }

  moveCourse(number: number) {
    this.currentIndex += number;
  }

  private getContext() {
    return this.courseContexts[this.currentIndex];
  }

  private widthStyle(width: number) {
    return { width: `${width}%` };
  }

  private getLessonStartDate(lesson: ApiLessonInstanceBase) {
    if (!lesson || !lesson.lessonMetric || !lesson.lessonMetric.started)
      return null;
    return Dates.parse(String(lesson.lessonMetric.started));
  }

  getCurrentCourseName() {
    return this.getContext().course.name;
  }

  getProgress() {
    return this.getContext().lessonsProgress
      ? this.getContext().lessonsProgress * 100
      : 0;
  }

  getRevisionProgress() {
    return this.getContext().revisionProgress
      ? this.getContext().revisionProgress * 100
      : 0;
  }

  getExamProgressWidth() {
    const progress = this.getContext().exams ? 100 : 0;
    return this.widthStyle(progress);
  }

  getRevisionProgressWidth() {
    const progress = this.getRevisionProgress();
    return this.widthStyle(progress);
  }

  getLessonProgressWidth() {
    const progress = this.getProgress();
    return this.widthStyle(progress);
  }

  getExamProgressDescription() {
    return this.getContext().exams ? '✓' : '---';
  }

  getFirstLessonDate() {
    return this.getLessonStartDate(this.getContext().firstLesson);
  }

  getLastLessonDate() {
    const context = this.getContext();
    const allDates = [
      this.getLastExamDate(),
      this.getLessonStartDate(context.lastQuickStageRevision),
      this.getLessonStartDate(context.lastRegularLesson),
      this.getLessonStartDate(context.lastRevision),
    ]
      .filter((el) => el != null)
      .sort((l, r) => l.getTime() - r.getTime());
    if (allDates.length === 0) return null;
    return allDates[allDates.length - 1];
  }

  getLastExamDate() {
    return this.getLessonStartDate(this.getContext().lastExam);
  }

  getNumberOverallLessons() {
    return this.getContext().overall;
  }

  hasAnyProgress() {
    return this.currentIndex != null;
  }

  hasAnyLesson() {
    return this.getContext().lessons > 0;
  }

  hasPrev() {
    return this.currentIndex != null && this.currentIndex > 0;
  }

  hasNext() {
    return (
      this.currentIndex != null &&
      this.currentIndex < this.courseContexts.length - 1
    );
  }

  isDisplayed() {
    return this.courseContexts?.length > 0 || this.showNoContext
  }

  isCourseActive() {
    return !!this.productContext
  }
}
