import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'circular-progress',
    templateUrl: './circular-progress.component.html',
    styleUrls: ['./circular-progress.component.scss'],
    standalone: false
})
export class CircularProgressComponent implements OnInit {

  @Input() progress = 0
  constructor() { }

  ngOnInit(): void {
  }

}
