import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import { LessonType } from 'src/app/col/model/calendar';
import {ApiLearningUnitTeacher, ApiLessonInstance, ApiPerson, ApiPersonalProfile} from 'src/app/col/model/rest-model';
import { TeacherRestServiceImpl } from 'src/app/col/services/teacher/teacher-rest-impl.service';
import { LessonTypeUtils } from 'src/app/col/utils/lesson-utils';
import { Pageable, Page } from 'src/app/utils/pageable';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-teacher-lessons',
    templateUrl: './teacher-lessons.component.html',
    styleUrls: ['./teacher-lessons.component.scss'],
    standalone: false
})
export class TeacherLessonsComponent implements OnInit {
  private _lessonType: LessonType;
  private _teacherId: number;
  isLoading = true;
  pageable: Pageable;
  lessons: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  langCode: string;

  @Input()
  set teacherId(value: number) {
    this._teacherId = value;
    this.getLessons(this.pageable).subscribe();
  }
  get teacherId() {
    return this._teacherId;
  }

  @Input()
  public set lessonType(lessonType: LessonType) {
    this._lessonType = lessonType;
    if (this._lessonType === LessonType.Past) {
      this.pageable = new Pageable(0, 5, [
        'lesson.teacher.metricDetails.plannedStartDate,DESC',
      ]);
    } else {
      this.pageable = new Pageable(0, 5, [
        'lesson.teacher.metricDetails.plannedStartDate,ASC',
      ]);
    }
    this.getLessons(this.pageable).subscribe();
  }
  get lessonType() {
    return this._lessonType;
  }

  constructor(
    private spinner: SpinnerService,
    private teacherRest: TeacherRestServiceImpl,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  getLessonTypeName() {
    switch (this._lessonType) {
      case LessonType.Upcoming: {
        return 'COL.LESSONS.LIST.FUTURE_LESSONS';
      }
      case LessonType.Past: {
        return 'COL.LESSONS.LIST.PAST_LESSONS';
      }
      case LessonType.Incomplete: {
        return 'COL.LESSONS.LIST.INCOMPLETE_LESSONS';
      }
    }
  }

  onPageChange($event) {
    this.getLessons($event).subscribe();
  }

  getLessons(pageable: Pageable): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    if (
      this.lessonType === null ||
      this.lessonType === undefined ||
      !this.teacherId
    )
      return of(null);
    this.pageable = pageable;
    return this.spinner.trace<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(
      this.teacherRest
        .getLessons(
          this.teacherId,
          this.pageable,
          this.lessonType,
          this.langCode
        )
        .pipe(
          tap((lessons) => (this.lessons = lessons)),
          tap((_) => (this.isLoading = false))
        )
    );
  }

  public hasResults(): boolean {
    return !!this.lessons;
  }

  public getTotalResults(): number {
    if (!this.lessons) return 0;
    return this.lessons.totalElements;
  }

  getLessonsToDisplay() {
    return this.lessons.content;
  }

  getInitials(person: ApiPerson<ApiPersonalProfile>) {
    return person ? PersonUtils.getInitials(person) : '';
  }

  public getLessonStudentPhotoUrl(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    let studentPerson = lesson?.students[0]?.person?.personalProfile;
    return studentPerson?.profilePhoto
      ? environment.fileEndpoint +
          '/img/' +
          studentPerson?.profilePhoto.id +
          '?fileAccessToken=' +
          studentPerson.profilePhoto.accessToken
      : null;
  }

  public lessonBadgeClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson ? LessonTypeUtils.getStudentLessonColorClass(lesson) : '';
  }

  getLessonStatus(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return LessonTypeUtils.getStudentLessonStatus(lesson);
  }

  public getLessonDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): Date {
    return lesson.lessonMetric.plannedStartDate;
  }

  public getLessonStudent(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    return lesson.students
      .map((s) => s.person)
      .map((p) => PersonUtils.getPersonName(p))
      .join(', ');
  }

  openDetails(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    this.router.navigate(
      [`lesson/${lesson.id}/student/${lesson.students[0].id}`],
      {
        relativeTo: this.route,
      }
    ).then();
  }


  buildVersionNumber(courseCode: string, productVersion: string) {
    if (!courseCode.startsWith("en.s") && !productVersion) return null;
    if (!productVersion) return '2nd Ed.';
    const intVer = parseInt(productVersion);
    if (isNaN(intVer)) return `v: ${productVersion}`;
    const lastDigit = intVer % 10;
    let postfix: string;
    if (intVer > 3 && intVer < 20) postfix = 'th';
    else if (lastDigit == 1) postfix = 'st';
    else if (lastDigit == 2) postfix = 'nd';
    else if (lastDigit == 3) postfix = 'rd';
    else postfix = 'th';
    return `${intVer}${postfix} Ed.`;
  }
  getProductVersion(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return this.buildVersionNumber(lesson.course.code, lesson.productVersion);
  }


  isRedLabel(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.course.code.startsWith('en.s') && lesson.productVersion == null
  }
}
