import { Component, OnInit } from '@angular/core';
import {ExamSession, ExerciseSession} from 'src/app/cspa/model/cspa/personal';
import { SessionInfoService } from 'src/app/services/session-info.service';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    standalone: false
})
export class ProgressBarComponent implements OnInit {

  questionNb: string
  session: ExerciseSession | ExamSession
  progressPercent?: number

  constructor(
    private progressInfoService: SessionInfoService
  ) {}

  ngOnInit(): void {
  }

  getExerciseProgressPercent() {
    this.progressInfoService.getSessionInfo().subscribe(i => {
      this.questionNb = i.questionNb
      this.session = i.session
      this.progressPercent = i.progressPercent
    })

    return this.progressPercent != null ?
      this.progressPercent.toFixed()
      : ((+this.questionNb - 1) / this.getQuestions()?.length * 100).toFixed()
  }

  isExam() {
    return this.session?.path?.includes("exam")
  }

  getQuestions() {
    return this.isExam() ?
      (this.session as ExamSession)?.currentPart?.questions
      : (this.session as ExerciseSession)?.questions
  }
}
