import { formatDate } from '@angular/common';
import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {tap, switchMap, Observable, forkJoin, map, take} from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { Pageable, Page } from 'src/app/utils/pageable';
import { ScheduleRow, IdentifiedRoomTemplate, RoomDef } from '../../models/video.model';
import { RoomsListProvider } from '../../components/rooms-list/rooms-list.component';
import {SchedulesListComponent, SchedulesListProvider} from '../../components/schedules-list/schedules-list.component';
import { TemplatesListProvider } from '../../components/templates-list/templates-list.component';
import { DateUtils } from '../../utils/date-utils';
import {VideoApi} from "../../services/video-rest.service";
import {LoadComponentDirective} from "../../../utils/load-component/load-component.directive";
import {LessonsListComponent} from "../../components/lessons-list/lessons-list.component";
import {LessonsCalendarComponent} from "../../components/lessons-calendar/lessons-calendar.component";
import {UserRole} from "../../../auth_profile/services/api/authorization.api";
import {ProfileService} from "../../../auth_profile/services/student/profile.service";
import {Person, PersonDetails} from "../../../auth_profile/model/casa/casa-model";

@Component({
    selector: 'app-teacher-lessons-planer',
    templateUrl: './teacher-lessons-planer.component.html',
    styleUrls: ['./teacher-lessons-planer.component.scss'],
    standalone: false
})
export class TeacherLessonsPlanerComponent implements OnInit {

  @ViewChild(LoadComponentDirective, { static: true}) loadComponent!: LoadComponentDirective

  schoolId: number
  teacherId: number
  teacher: Person<PersonDetails>
  dayStart = new Date(DateUtils.weekStart(DateUtils.getTodayDate()))
  dayEnd = new Date(DateUtils.weekEnd(DateUtils.getTodayDate()))
  weekOffset = 0
  scheduleListsProvider: SchedulesListProvider
  templateListsProvider: TemplatesListProvider
  roomListsProvider: RoomsListProvider
  selectedSection: string

  constructor(private route: ActivatedRoute,
    private auth: AuthorizationServiceProvider,
    private apiProvider: NativeServiceApiProvider,
    private router: Router,
    private authProvider: AuthorizationServiceProvider,
    private profileService: ProfileService) {}

  ngOnInit(): void {
    this.prepareDates()
    this.auth.getAuthDetailsService().pipe(
      switchMap( authDetailsApi =>
        forkJoin([authDetailsApi.getSelfTeacherId(), authDetailsApi.getSelfSchoolId()])
      ),
      tap(([teacherId, schoolId]) => {
        this.teacherId = teacherId
        this.schoolId = schoolId
      }),
      switchMap(_ => this.redirectToTeacherPlaner()),
      switchMap(_ => this.loadTeacher()),
      switchMap(_ => this.apiProvider.video()),
      tap(api => this.prepareProviders(api)),
      switchMap(_ => this.onSectionChanges())
    ).subscribe()
  }

  private redirectToTeacherPlaner() {
    return this.route.paramMap.pipe(
      tap(params => {
        if(!(+params.get("teacherId") && +params.get("schoolId") && params.get("selectedSection"))) {
          this.router.navigate(
            [`school/${this.schoolId}/teacher/${this.teacherId}/upcoming`],
            {
              relativeTo: this.route,
              queryParamsHandling: 'merge'
            }
          ).then()
        }
      })
    )
  }

  private loadTeacher(){
    return this.profileService.getSelfPerson().pipe(
      take(1),
      tap(teacher => this.teacher = teacher)
    )
  }

  private onSectionChanges() {
    return this.route.paramMap.pipe(
      tap(params => {
        this.selectedSection = params.get("selectedSection")
        this.onLoadComponent()
      })
    )
  }

  private prepareProviders(api: VideoApi) {
    const schoolId = this.schoolId
    const teacherId = this.teacherId
    const teacher = this.teacher
    this.scheduleListsProvider = new class implements SchedulesListProvider {
      listSchedules(focusDate: number): Observable<ScheduleRow[]> {
        return api.listTeacherSchedules(schoolId, teacherId, focusDate).pipe(
          map(schedules => schedules.filter(schedule => schedule.schedule.details.participants.find(parti => parti.role === UserRole.Teacher && parti.email === teacher.registrationEmail)))
        )
      }
    }
    this.templateListsProvider = new class implements TemplatesListProvider {
      listGroups(pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
        return api.listTeacherGroups(schoolId, teacherId, pageable)
      }
    }
    this.roomListsProvider = new class implements RoomsListProvider {
      listRooms(pageable: Pageable): Observable<Page<RoomDef>> {
        return api.listTeacherRooms(schoolId, teacherId, pageable)
      }
    }
  }

  onLoadComponent() {
    this.loadComponent.viewContainerRef.clear()
    let componentRef;
    if(this.selectedSection === 'upcoming') {
      componentRef = this.loadComponent.viewContainerRef.createComponent(SchedulesListComponent)
      componentRef.instance.dayStart = this.dayStart
      componentRef.instance.dayEnd = this.dayEnd
      componentRef.instance.redirectPathPrefix = "../schedule"
      componentRef.instance.schedulesProvider = this.scheduleListsProvider
    }
    else if(this.selectedSection === 'lessons-list') {
      componentRef = this.loadComponent.viewContainerRef.createComponent(LessonsListComponent)
      componentRef.instance.dayStart = this.dayStart
      componentRef.instance.schedulesProvider = this.scheduleListsProvider
    }
    else if(this.selectedSection === 'lesson-calendar') {
      componentRef = this.loadComponent.viewContainerRef.createComponent(LessonsCalendarComponent)
      componentRef.instance.schoolId = this.schoolId
      componentRef.instance.dayStart = this.dayStart
      componentRef.instance.schedulesProvider = this.scheduleListsProvider
    }
  }

  isActive(path: string) {
    return this.selectedSection === path
  }

  getDate() {
    return `${formatDate(this.dayStart, 'd', 'en_US')} -
    ${formatDate(this.dayEnd, 'd', 'en_US')}
    ${this.dayEnd.toLocaleString('en-us', { month: 'short' })}`
  }

  goToToday() {
    this.weekOffset = 0
    this.prepareDates()
  }

  getNextWeek() {
    this.weekOffset++
    this.prepareDates()
  }

  getPreviousWeek() {
    this.weekOffset--
    this.prepareDates()
  }

  prepareDates() {
    this.dayStart = new Date(DateUtils.weekStart(DateUtils.queryDate(this.weekOffset)))
    this.dayEnd = new Date(DateUtils.weekEnd(DateUtils.queryDate(this.weekOffset)))
    this.onLoadComponent()
  }
}
