import { Component, OnInit } from '@angular/core';
import {RoomDef, RoomProgressReport, ScheduleRow} from "../../models/video.model";
import {ActivatedRoute} from "@angular/router";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {map, switchMap, tap} from "rxjs";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {SpinnerService} from "../../../utils/services/spinner.service";
import {Page, Pageable} from "../../../utils/pageable";

@Component({
    selector: 'app-teacher-group-schedule-details-page',
    templateUrl: './teacher-group-schedule-details-page.component.html',
    styleUrls: ['./teacher-group-schedule-details-page.component.scss'],
    standalone: false
})
export class TeacherGroupScheduleDetailsPageComponent implements OnInit {

  teacherId: number;
  schoolId: number;
  scheduleId: number;
  schedule: ScheduleRow
  room: RoomDef
  progressPageable = new Pageable(0, 5, ['createDate,desc'])
  progressHistory: Page<RoomProgressReport>
  groupId: number

  constructor(private route: ActivatedRoute,
              private provider: NativeServiceApiProvider,
              private authProvider: AuthorizationServiceProvider,
              private spinnerService: SpinnerService) {
    this.authProvider.getAuthDetailsService().pipe(
      switchMap(service => service.getSelfTeacherId()),
      tap(teacherId => this.teacherId = teacherId),
      switchMap(_ => this.route.paramMap),
      map(params => {
        this.schoolId = +params.get('schoolId')
        this.scheduleId = +params.get('scheduleId')
      })
    ).subscribe()
  }

  ngOnInit(): void {
    this.spinnerService.trace(
      this.provider.video().pipe(
        switchMap(api => api.getTeacherScheduleDetails(
          this.schoolId, this.teacherId, this.scheduleId)
        ),
        tap<ScheduleRow>(schedule => {
          this.schedule = schedule
          this.room = schedule.room
          this.groupId = this.schedule.template.id
        }),
        tap(_ => this.loadTemplateProgressHistory(null))
      )
    ).subscribe()
  }


  loadTemplateProgressHistory(pageable?: Pageable) {
    if(pageable) this.progressPageable = pageable
    this.spinnerService.trace(
      this.provider.video().pipe(
        switchMap(api => api.getProgressHistory(this.schoolId, this.groupId, this.progressPageable))
      )
    ).subscribe(progressHistory => this.progressHistory = progressHistory)
  }

  getScheduleName() {
    return this.room?.name
  }

}
