import {Component} from '@angular/core';
import {ProductsUtils} from 'src/app/col/model/products';
import {StudentRestServiceImpl} from 'src/app/col/services/student/student-rest-impl.service';
import {CoreProductsBase} from 'src/app/col/utils/core-products-base';
import {LangProductMapper} from 'src/app/col/utils/lang-mappers';
import {SpinnerService} from 'src/app/utils/services/spinner.service';
import {StudentShoppingCartService} from "../../../../services/student-shopping-cart.service";

@Component({
    selector: 'app-english-products',
    templateUrl: './english-products.component.html',
    styleUrls: ['./english-products.component.scss'],
    standalone: false
})
export class EnglishProductsComponent extends CoreProductsBase {
  constructor(studentRest: StudentRestServiceImpl,
              spinner: SpinnerService,
              shoppingCart: StudentShoppingCartService) {
    super(studentRest, spinner, shoppingCart);
  }

  getLangName() {
    return LangProductMapper.mapLangCodeToLangName('en');
  }

  getEbookCategoryLabel(): string {
    return 'category:ebook.en';
  }
  getPaygCategoryLabel(): string {
    return 'category:lessons.en.pag';
  }
  getIntensiveCategoryLabel(): string {
    return 'category:lessons.en.int';
  }
  getStdCategoryLabel(): string {
    return 'category:lessons.en.std';
  }
  getLightCategoryLabel(): string {
    return 'category:lessons.en.light';
  }
  getProductsLabel(): string {
    return ProductsUtils.EN_PRODUCT_LABEL;
  }
}
