import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../../../../utils/modal/modal.component";
import {ApiTeacherProfile} from "../../../model/rest-model";
import {StudentRestServiceImpl} from "../../../services/student/student-rest-impl.service";
import {SpinnerService} from "../../../../utils/services/spinner.service";
import {Observable, tap} from "rxjs";
import {PersonUtils} from "../../../../auth_profile/utils/person-utils";

@Component({
    selector: 'app-calendar-teacher-filter-modal',
    templateUrl: './calendar-teacher-filter-modal.component.html',
    styleUrls: ['./calendar-teacher-filter-modal.component.scss'],
    standalone: false
})
export class CalendarTeacherFilterModalComponent implements OnInit {
  @ViewChild('filterTeacherModal', { static: true }) filterModal: ModalComponent;
  teachers: ApiTeacherProfile[];
  selectedTeacherIds: number[] = [];
  private _availableTeachersIds: number[];
  private _studentId: number;
  @Input()
  set studentId(value: number) {
    this._studentId = value;
    this.updateTeachersList();
  }
  get studentId() {
    return this._studentId;
  }
  @Input()
  set availableTeachersIds(teachersIds: number[]) {
    this._availableTeachersIds = teachersIds;
    this.updateTeachersList();
  }
  @Output()
  selectedTeachersUpdate = new EventEmitter<number[]>();

  constructor(
    private studentRest: StudentRestServiceImpl,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {}

  open() {
    this.filterModal.show(true)
  }

  updateTeachersList(): any {
    if (!this.studentId) return;
    if (!this._availableTeachersIds || this._availableTeachersIds.length == 0) {
      this.teachers = null
    } else {
      this.getTeachers().subscribe();
    }
  }

  getTeachers(): Observable<ApiTeacherProfile[]> {
    return this.spinner.trace<ApiTeacherProfile[]>(
      this.studentRest.getTeachers(this.studentId, this._availableTeachersIds).pipe(
        tap((teachers) => (this.teachers = teachers))
      )
    );
  }

  selectTeacher(profile: ApiTeacherProfile) {
    if (!profile) {
      this.selectedTeacherIds = [];
      return;
    }
    const teacherFoundIndex = this.selectedTeacherIds.indexOf(
      profile.teacher.id
    );
    if (teacherFoundIndex >= 0) {
      this.selectedTeacherIds.splice(teacherFoundIndex, 1);
      return;
    }
    this.selectedTeacherIds.push(profile.teacher.id);
  }

  isActive(teacher: ApiTeacherProfile) {
    return this.selectedTeacherIds.indexOf(teacher.teacher.id) >= 0;
  }

  getTeacherName(teacherProfile: ApiTeacherProfile) {
    return teacherProfile && teacherProfile.teacher
      ? PersonUtils.getPersonName(teacherProfile.teacher.person)
      : '';
  }

  getTeacherPhoto(teacherProfile: ApiTeacherProfile) {
    return teacherProfile && teacherProfile.teacher
      ? PersonUtils.getPersonProfilePhoto(teacherProfile.teacher.person)
      : '';
  }

  getSelectedTeachers() {
    return this.selectedTeacherIds;
  }

  getInitials(teacherProfile: ApiTeacherProfile) {
    return PersonUtils.getInitials(teacherProfile.teacher.person);
  }

  applyFilters() {
    this.selectedTeachersUpdate.emit(this.selectedTeacherIds);
    this.filterModal.hide()
  }

  clearFilters() {
    this.selectTeacher(null)
    this.filterModal.hide()
  }
}
