<div class="toast-list d-flex align-items-center flex-column"
      [ngClass]="isOnTop ? 'zindex-toast' : ''">
  <div 
    class="toast fade show" 
    *ngFor="let notification of messages; let i = index" 
    [ngClass]="getClasses(notification)">
    <a class="text-decoration-none" 
      data-bs-toggle="collapse" 
      href="#idx{{i}}" 
      role="button" 
      aria-expanded="false">
      <div class="toast-header"
        [class]="closeBtnShown? 'justify-content-between':'justify-content-center'">
        <strong>{{ getTitle(notification) }}</strong>
        <button 
          type="button" 
          class="btn btn-close btn-close-white ms-auto" 
          *ngIf="closeBtnShown"
          (click)="onClose(notification)">
        </button>
      </div>
    </a>
    
    <div class="collapse" id="idx{{i}}" [ngClass]="isMsgShown(notification)? 'show' : ''">
      <div class="toast-body rounded-bottom">
        <p>{{ getMessage(notification) }}</p>
        <p class="float-end mb-0">{{ getDuration(notification) }}</p>
        <div *ngIf="notification.isReportButtonShown" class="d-flex justify-content-end">
          <button class="btn btn-primary rounded-pill" (click)="reportAnError(notification)">{{ 'BUTTON.REPORT_ERROR' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
