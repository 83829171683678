import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfirmDialogService } from "src/app/utils/services/confirm-dialog.service";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    standalone: false
})
export class ConfirmDialogComponent implements OnInit {

  @ViewChild("confirmDialog", {static: true})
    confirmDialog: ModalComponent;
  private serviceOpenState = false;
  
  constructor(
    public confirmDialogService: ConfirmDialogService
  ) { }

  ngOnInit(): void {
    this.confirmDialogService.show.subscribe(show => {
      if (show) {
        this.serviceOpenState = true;
        this.confirmDialog.show(false);
      } else {
        this.serviceOpenState = false;
        this.confirmDialog.hide();
      }
    });
    this.confirmDialog.state.subscribe(state => {
      if (state != this.serviceOpenState) {
        if (!state) {
          this.confirmDialogService.cancel();
        }
      }
    })
  }

  getTitle() {
    return this.confirmDialogService.title
  }

  getMessage() {
    return this.confirmDialogService.message
  }

  getClasses() {
    return this.confirmDialogService.classes
  }

  getConfirmText() {
    return this.confirmDialogService.confirmText
  }

  getCancelText() {
    return this.confirmDialogService.cancelText
  }

  onConfirm() {
    this.confirmDialogService.confirm()
  }

  onCancel() {
    this.confirmDialogService.cancel()
  }
}