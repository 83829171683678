import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import { ProfileService } from 'src/app/auth_profile/services/student/profile.service';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import { ThemeService } from 'src/app/services/theme.service';
import {NativeServiceApiProvider} from "../../services/native-api-provider.service";
import {forkJoin, switchMap, take, zip} from "rxjs";
import {FunctionalAccessService} from "../../utils/services/functional-access.service";
import { AppVersionService } from 'src/app/services/app-version.service';
import {LogsService} from "../../utils/services/logs.service";
import {environment} from "../../../environments/environment";
import * as Sentry from "@sentry/angular";

@Component({
    selector: 'student-main-nav',
    templateUrl: './student-main-nav.component.html',
    styleUrls: ['./student-main-nav.component.scss'],
    standalone: false
})
export class StudentMainNavComponent implements OnInit, AfterViewInit {

  @ViewChild('main', {static: true})
    public mainMenuRef: ElementRef;

  // @ViewChild('profileMenu', {static: true})
  //   public profileMenu: ElementRef;

  @Input() activeSegments: string[]
  @Input() isOffcanvas: boolean = false
  @Input()
  set theme(value: string) {
    this._theme = value
  }
  get theme() {
    return this._theme
  }
  @Input() profilePhoto?: string
  public hasBack: boolean = false;
  private nestedStack: HTMLElement[] = [];
  studentFullName: string
  links = document.getElementsByClassName('nav-item')
  schoolName: string
  casaSupported = false;
  disableVideo = true;
  schoolId: number
  studentId: number
  private _theme: string
  private _version: string

  private isAppNative = true;
  private colFunctionality = environment.colFunctionality

  constructor(private authService: AuthorizationServiceProvider,
              private profileService: ProfileService,
              public themeService: ThemeService ,
              private provider: NativeServiceApiProvider,
              private authProvider: AuthorizationServiceProvider,
              functionalityAccessService: FunctionalAccessService,
              public appVersionService : AppVersionService,
              private logs: LogsService
              ) {
    functionalityAccessService.getAccessState().subscribe(
      accessList => {
        this.disableVideo = accessList.videoDisabled
      }
    )
    this.authProvider.getCommonRoutines().subscribe(auth =>
      this.isAppNative = auth.isNativeAuthorization()
    )
    this._version = "m";
    appVersionService.getVersion().pipe(take(1)).subscribe(v => this._version = v);
  }

  /**
   * if native supports the casa profile use profile to get student name and school
   * @private
   */
  private loadProfileData() {
    forkJoin([
      this.profileService.getSelfPerson().pipe(take(1)),
      this.profileService.getSelfSchool().pipe(take(1))
    ]).subscribe({
      next: ([self, school]) => {
        this.studentFullName = PersonUtils.getPersonName(self.details)
        this.schoolName = school.details.name
      },
      error: err => {
        this.logs.error("got error when asking for self profile data in student dashboard", err);
        this.loadAppData();
      }
    })
  }

  /**
   * if native doesn't support casa just load in an old way
   * @private
   */
  private loadAppData() {
    this.authService.getAuthDetailsService().pipe(
      switchMap( api => api.getUserName())
    ).subscribe(
      userName => {
        this.studentFullName = userName
        this.schoolName = ""
      }
    )
  }

  ngOnInit(): void {
    this.authService.getAuthDetailsService().pipe(
      switchMap(api =>
        zip(api.getSelfSchoolId(), api.getSelfStudentId())
      ),
    ).subscribe(([schoolId, studentId]) => {
          this.schoolId = schoolId
          this.studentId = studentId
    })

    for (let i = 0; i < this.links.length; i++) {
      this.links[i].addEventListener("click", () => {
        if(this.nestedStack) this.nestedStack.pop();
        this.hasBack = false
      })
    }

    this.provider.casa().subscribe( api => {
        if (api.isImplemented()) {
          if (api.isSupportsProfileEdit()) {
            this.casaSupported = true;
          }

          this.loadProfileData();
        } else {
          this.loadAppData();
        }
      }
    )
  }

  ngAfterViewInit(): void {
      const feedback = Sentry.feedbackIntegration();
      const buttons = document.querySelectorAll("#reportABugButton")
      if (buttons.length && !Array.from(buttons).every(button => button.hasAttribute("data-feedback-attached"))) {
        buttons.forEach(button => {
          feedback.attachTo(button)
          button.setAttribute("data-feedback-attached", "true")
        })
      }
    }

  getStudentName() {
    return this.studentFullName
  }

  getStudentRole() {
    return this.schoolName? `${this.schoolName} student` : ''
  }

  getDismiss() {
    return this.isOffcanvas && "offcanvas"
  }

  getLessonLink() {
    if(this.schoolId == 1)
      return `./col-lessons/school/${this.schoolId}/student/${this.studentId}`
    else
      return `./lessons/school/${this.schoolId}/student/${this.studentId}`
  }

  isHidden(menu: HTMLElement) {
    if (!this.nestedStack.length && menu === this.mainMenuRef.nativeElement)
      return false;
    return this.nestedStack[this.nestedStack.length - 1] !== menu;
  }

  isActivePath(segment: string) {
    return (this.activeSegments[1] === 'student' &&
      this.activeSegments[2].includes(segment))
  }

  onOpen(menu: HTMLElement) {
    this.nestedStack.push(menu);
    this.hasBack = true;
  }

  onBack() {
    this.nestedStack.pop();
    this.hasBack = this.nestedStack.length > 0;
  }

  onClose(): void {
    if(this.nestedStack)
      this.nestedStack.pop();
    this.hasBack = false
  }

  onLogout() {
    this.authService.getCommonRoutines().subscribe(service => service.logout())
  }

  getAvatar() {
    return {background: this.profilePhoto? `url('${this.profilePhoto}')` : null}
  }

  isCallanOnlineStudent() {
    return this.schoolId === 1
  }

  getVersion() {
    return this._version;
  }

  isColFunctionalityOn() {
    return this.colFunctionality && !this.isAppNative
  }
}
