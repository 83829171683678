import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, from, map, mergeMap, Observable, Subscription, switchMap, tap} from 'rxjs';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import {ChapterAvailability, ExerciseSetAvailability, ItemAvailability} from '../../model/cspa/personal';
import { Chapter, ExerciseSet } from '../../model/cspa/struct';
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {defaultIfEmpty, take} from "rxjs/operators";
import {CspaApi, CspaMobileBridgeServicesApi} from "../../services/api/cspa.api";
import {LogsService} from "../../../utils/services/logs.service";
import {FilterDefinition} from "../../../model/CourseFilters";

@Component({
    selector: 'exercise-list',
    templateUrl: './exercise-list.component.html',
    styleUrls: ['./exercise-list.component.scss'],
    standalone: false
})
export class ExerciseListComponent implements OnInit, OnDestroy, AfterViewInit {
  availabilitiesMap = new Map<string, ChapterAvailability | ExerciseSetAvailability>()
  exerciseSetsMap = new Map<string, ExerciseSet>()
  chaptersMap = new Map<string, Chapter>()
  chapters: Chapter[]
  chosenCourse: FilterDefinition
  private updateSubscription: Subscription
  private refreshSubscription: Subscription
  private loadSubscription: Subscription
  private refreshDataSubscription: Subscription

  constructor(
    private provider: NativeServiceApiProvider,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private logs: LogsService,
    private cdr: ChangeDetectorRef) {}

  private api<T>(apiCallback: ([MobileBridgeServices, CspaRestService]) => Observable<T>): Observable<T> {
    return forkJoin([this.provider.cspaBridge(),this.provider.cspa()]).pipe(
      switchMap(apis => apiCallback(apis))
    )
  }

  syncData: (args) => Observable<any> = ([bridge, _]: [CspaMobileBridgeServicesApi, CspaApi]) =>
  {
    return bridge.syncSessions().pipe(
      defaultIfEmpty(_ => 'sync is done'),
      switchMap( _ => bridge.syncTopStructure())
    )
  }

  ngOnInit(): void {
    this.logs.log("loading exercise list screen");

    this.refreshSubscription = this.provider.books().pipe(
      take(1),
      map(api => api.subscribeForRefreshingEvents()),
      switchMap(taskProgressEmitter => taskProgressEmitter),
      switchMap(observable => this.spinner.trace(observable))
    ).subscribe()

    this.updateSubscription = this.provider.books().pipe(
      switchMap(api => api.listenForDataUpdates()),
      defaultIfEmpty("anything"),
      switchMap( _ => this.loadData())
    ).subscribe()

    this.loadSubscription = this.api(this.syncData).pipe(
      switchMap(_ => this.loadData())
    ).subscribe()
  }


  ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe()
    this.refreshSubscription?.unsubscribe()
    this.loadSubscription?.unsubscribe()
    this.refreshDataSubscription?.unsubscribe()
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  loadData(){
    return this.spinner.trace(
      this.provider.cspa().pipe(
        switchMap( api =>
          this.listMappedExerciseSets(api).pipe(
            switchMap( _ => this.listMappedTopAvailabilities(api)),
            mergeMap(set => this.getMappedChapters(api, set))
          )
        )
      )
    )
  }

  private listMappedExerciseSets(api: CspaApi): Observable<ExerciseSet[]> {
    return api.listExerciseSets().pipe(
      tap(sets => this.mapExerciseSets(sets))
    )
  }

  private listMappedTopAvailabilities(api: CspaApi): Observable<ItemAvailability> {
    return api.listTopAvailabilities().pipe(
      tap((availabilities: Array<ChapterAvailability | ExerciseSetAvailability>) =>
        this.mapAvailabilities(availabilities)),
      map(avs => avs.filter(av =>
        !av.path.includes('_') && av.assigned && av.available
      )),
      mergeMap((sets: ItemAvailability[]) => from(sets))
    )
  }

  private getMappedChapters(api: CspaApi, set: ItemAvailability) {
    return api.getChapters(`${set.path}_`).pipe(
      map(chapters => {
        this.mapChapters(this.getAvailableChapters(chapters))
        this.filterChapters(this.chosenCourse.cspaFilter)
      })
    )
  }

  private getAvailableChapters(chapters: Chapter[]) {
    return chapters.filter(chapter => {
        const chapterAvail = this.availabilitiesMap.get(chapter.path)
        return chapterAvail && chapterAvail.assigned && chapterAvail.available
      }
    )
  }

  mapExerciseSets(exSets: ExerciseSet[]) {
    exSets.forEach(exSet =>
    this.exerciseSetsMap.set(exSet.path, exSet))
  }

  mapChapters(chapters: Chapter[]) {
    chapters.forEach(chapter =>
    this.chaptersMap.set(chapter.path, chapter))
  }

  mapAvailabilities(availabilities: Array<ChapterAvailability | ExerciseSetAvailability>) {
    availabilities.forEach(availability =>
      this.availabilitiesMap.set(availability.path, availability))
  }

  getChapters() {
    return Array.from(this.chaptersMap.keys())
            .sort((aKey, bKey) =>
             this.availabilitiesMap.get(bKey).lastSubmit - this.availabilitiesMap.get(aKey).lastSubmit)
            .map(chapKey =>
              this.chaptersMap.get(chapKey))
  }

  getExerciseSet(chapterPath: string) {
    return this.exerciseSetsMap.get(chapterPath.split('_')[0])
  }

  getAvailability(chapterPath: string) {
    return this.availabilitiesMap.get(chapterPath)
  }

  filterChapters(language: string) {
    if(!language)
      this.chapters = this.getChapters()
    else
    this.chapters = this.getChapters().filter(
      chapter => chapter.path.startsWith(`${language}_`))
  }

  isActive(filter: FilterDefinition) {
    return filter && this.chosenCourse && filter.code === this.chosenCourse?.code;
  }

  refreshData() {
    this.refreshDataSubscription = this.spinner.trace(
      this.provider.books().pipe(
        switchMap(api => api.refreshData(0))
      )
    ).subscribe()
  }

  getLanguageTranslation(lang: FilterDefinition) {
    return "EBOOKS.FILTER_LANGS." + lang?.translationCode
  }

  setChosenCourse(lang: FilterDefinition) {
    this.chosenCourse = lang
    this.filterChapters(lang?.cspaFilter)
  }
}
