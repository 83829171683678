import { Component, OnInit } from '@angular/core';
import { CourseFilterComponent } from 'src/app/utils/course-filter/course-filter.component';
import {forkJoin, switchMap, tap} from "rxjs";
import {AuthorizationServiceProvider} from "../../../../auth_profile/services/authorization-service.provider";
import {StudentRestServiceImpl} from "../../../services/student/student-rest-impl.service";
import {ApiCourseContext} from "../../../model/rest-model";

@Component({
    selector: 'app-progress-card-list',
    templateUrl: './progress-card-list.component.html',
    styleUrls: ['./progress-card-list.component.scss'],
    standalone: false
})
export class ProgressCardListComponent implements OnInit {

  CourseFilterComponent = CourseFilterComponent
  studentId: number
  contextMap = new Map<string, ApiCourseContext>()
  constructor(private authService: AuthorizationServiceProvider,
              private studentRest: StudentRestServiceImpl) { }

  ngOnInit(): void {
    this.authService.getAuthDetailsService().pipe(
      switchMap(api => api.getSelfStudentId()),
      tap((studentId) => {
        this.studentId = studentId
      }),
      switchMap(_ => this.getProgressMap())
    ).subscribe()
  }

  private getProgressMap() {
    const sources = CourseFilterComponent.getFilters()
      .slice(1)
      .map(lang => lang.filter)
      .map(langCode => this.studentRest.getProductContext(this.studentId, langCode).pipe(
        tap(context => {
          if(context != null && context.courseContexts.length > 0)
            this.contextMap.set(context.product.code, context?.courseContexts?.reverse()[0])
        })
      ));

    return forkJoin(sources)
  }

  hasResults() {
    if(this.contextMap.size == 0) return false
    return Array.from(this.contextMap.values()).filter(value => value != null).length > 0;
  }

  getContextList() {
    return Array.from(this.contextMap)
  }
}
