import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Page, Pageable } from 'src/app/utils/pageable';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { ApiLessonBundle } from '../../../model/rest-model';
import { StudentRestServiceImpl } from '../../../services/student/student-rest-impl.service';
import { ProductContextService } from 'src/app/col/services/product-context.service';

@Component({
    selector: 'app-purchase-history',
    templateUrl: './purchase-history.component.html',
    styleUrls: ['./purchase-history.component.scss'],
    standalone: false
})
export class PurchaseHistoryComponent implements OnInit, OnDestroy {
  pageable: Pageable = Pageable.of(0, 5, ['lb.date,desc']);
  results: Page<ApiLessonBundle>;
  _langCode: string
  private _studentId: number;
  isLoading = true;
  productContextSubscription: Subscription

  @Input() set studentId(id: number) {
    this._studentId = id;
  }
  @Input() set langCode(langCode: string) {
    this._langCode = langCode;
    this.initQuery();
  }
  constructor(
    private spinner: SpinnerService,
    private studentRest: StudentRestServiceImpl,
    private productContextService: ProductContextService) {}

  ngOnInit(): void {
    this.productContextSubscription = this.productContextService.productContext.subscribe(_ => this.initQuery())
  }

  ngOnDestroy(): void {
    if(this.productContextSubscription) this.productContextSubscription.unsubscribe()
  }

  private initQuery() {
    this.spinner
      .trace<Page<ApiLessonBundle>>(this.getAvailableBundles())
      .subscribe((result) => {
        this.results = result;
        this.isLoading = false;
      });
  }

  private getAvailableBundles(): Observable<Page<ApiLessonBundle>> {
    return this.studentRest.getStudentLessonBundles(
      this._studentId,
      this._langCode,
      this.pageable,
      null
    );
  }

  getResults() {
    return this.results?.content || [];
  }

  onPageChange($event) {
    this.pageable = $event;
    this.initQuery();
  }
}
