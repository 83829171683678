import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {defaultIfEmpty, map, of, Subscription, switchMap, take, tap, zip} from 'rxjs';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { ItemAvailability } from '../../model/cspa/personal';
import { Chapter, ExerciseSet, Section } from '../../model/cspa/struct';
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {CspaApi} from "../../services/api/cspa.api";
import {LogsService} from "../../../utils/services/logs.service";

@Component({
    selector: 'exercise-details',
    templateUrl: './exercise-details.component.html',
    styleUrls: ['./exercise-details.component.scss'],
    standalone: false
})
export class ExerciseDetailsComponent implements OnInit, OnDestroy {
  exerciseSet: ExerciseSet
  chapter: Chapter
  sectionsMap = new Map<string, Section>()
  availabilitiesMap = new Map<string, ItemAvailability>()
  private updateSubscription: Subscription
  private refreshSubscription: Subscription
  private refreshDataSubscription: Subscription;
  private loadDataSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private provider: NativeServiceApiProvider,
    private router: Router,
    private spinner: SpinnerService,
    private logger: LogsService
    ) {}

  ngOnInit(): void {
    this.loadDataSubscription = this.loadData().subscribe()
    this.refreshSubscription = this.provider.books().pipe(
      take(1),
      map(api => api.subscribeForRefreshingEvents()),
      switchMap(taskProgressEmitter => taskProgressEmitter),
      switchMap(observable => this.spinner.trace(observable))
    ).subscribe()

    this.updateSubscription = this.provider.books().pipe(
      switchMap(api => api.listenForDataUpdates()),
      defaultIfEmpty("anything"),
    ).subscribe(_ =>
      this.refreshData()
    )
  }

  ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe()
    this.refreshSubscription?.unsubscribe()
    this.loadDataSubscription?.unsubscribe()
    this.refreshDataSubscription?.unsubscribe()
  }

  loadData() {
    this.logger.log("Loading exercise details view data")
    return this.spinner.trace(
      zip(this.route.paramMap, this.provider.cspa()).pipe(
        switchMap(([params, api]) => this.findExerciseSet(api, params).pipe(
          switchMap(_ => this.syncStructure()),
          switchMap(_ => this.findChapter(api, params)),
          switchMap(_ => this.findChapterAvailabilities(api))
          )
        )
      )
    )
  }

  private findExerciseSet(api: CspaApi, params: ParamMap) {
    return api.listExerciseSets().pipe(
      map(exerciseSets =>
        exerciseSets.find(exerciseSet =>
          exerciseSet.path === params.get("exerciseSet")
        )
      ),
      tap<ExerciseSet>( exerciseSet =>
        this.exerciseSet = exerciseSet
      )
    )
  }

  private syncStructure() {
    return this.provider.cspaBridge().pipe(
      switchMap( bridge =>
        bridge.syncStructure(`${this.exerciseSet.path}`)
      ),
      defaultIfEmpty( _ => of("struct synced"))
    )
  }

  private findChapter(api: CspaApi, params: ParamMap) {
    return  api.getChapters(`${this.exerciseSet.path}_`).pipe(
      map<Chapter[], Chapter>( chapters =>
        chapters.find( chapter => chapter.path === params.get("chapter"))
      ),
      tap<Chapter>(chapter => {
        this.chapter = chapter
      })
    )
  }

  private findChapterAvailabilities(api: CspaApi) {
    return api.listChapterAvailabilities(`${this.chapter.path}_`).pipe(
      tap(availabilities => this.mapAvailabilities(availabilities)),
      tap( _ =>
        this.mapSections(
          this.chapter.sections.filter( section =>
            this.availabilitiesMap.has(section.path)
          )
        )
      )
    )
  }

  refreshData() {
    this.refreshDataSubscription = this.spinner.trace(this.provider.books().pipe(
      switchMap(api => api.refreshData(0))
    )).subscribe()
  }

  getExerciseSet(){
    return this.exerciseSet?.name
  }

  getChapter() {
    return this.chapter?.shortName
  }

  getAccent() {
    return `accent stg-${this.chapter?.path.split('_')[1]}`
  }

  getSections() {
    return this.chapter?.sections
  }

  mapSections(sections: Section[]) {
    sections.forEach(section =>
      this.sectionsMap.set(section.path, section))
  }

  mapAvailabilities(availabilities: ItemAvailability[]) {
    availabilities.forEach(availability =>
      this.availabilitiesMap.set(availability.path, availability))
  }

  getAvailabilities(section: Section) {
    return new Map([...this.availabilitiesMap.entries()].filter(av =>
      av[0].includes(section.path)))
  }

  goToExerciseSetsWithFilter() {
    this.router.navigate(
      ['../../'],
      {
        relativeTo: this.route,
        queryParams: { language: `${this.exerciseSet.path}`},
        queryParamsHandling: 'merge'
      }
    ).then()
  }
}
