import {Component, Input, OnInit} from '@angular/core';
import {StudentShoppingCartService} from "../../../services/student-shopping-cart.service";
import {Product} from "../../../model/products";

@Component({
    selector: 'app-shopping-cart-product',
    templateUrl: './shopping-cart-product.component.html',
    styleUrls: ['./shopping-cart-product.component.scss'],
    standalone: false
})
export class ShoppingCartProductComponent implements OnInit {
  @Input() product: Product
  constructor(
    private shoppingCart: StudentShoppingCartService
  ) {}

  ngOnInit(): void {}

  getName() {
    return this.product.product.defaultVariantName?.name || this.product.product.defaultProductName?.name
  }

  getQuantity() {
    return this.product.quantity
  }

  getSum() {
    return `${(this.getQuantity() * this.product.product.defaultPrice.price).toFixed(2)}
    ${this.shoppingCart.getCurrencySign()}`
  }

  increment() {
    this.shoppingCart.increment(this.product)
  }

  decrement() {
    this.shoppingCart.decrement(this.product)
  }

  delete() {
    this.shoppingCart.delete(this.product)
  }
}
