import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject } from "rxjs";

declare var bootstrap: any;

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: false
})
export class ModalComponent implements OnInit {

  @ViewChild("modal", {static: true})
    modalRef: ElementRef;
  @Input()
    scrollable = true;
  @Input()
    size: string = "md";
  state = new BehaviorSubject(false);
  modal: any = null

  isExtraLarge() {
    return this.size === 'xl'? true : false;
  }

  isLarge(){
    return this.size === 'lg'? true : false;
  }

  isSmall() {
    return this.size === 'sm'? true : false;
  }

  constructor() {}

  ngOnInit() {
    this.modalRef.nativeElement.addEventListener('shown.bs.modal', () => this.state.next(true));
    this.modalRef.nativeElement.addEventListener('hidden.bs.modal', () => this.state.next(false));
    this.modal = new bootstrap.Modal(this.modalRef.nativeElement)
  }

  public show(cancellable?: boolean) {
    if (cancellable === false) {
      this.modal._config['backdrop'] = 'static';
      this.modal._config['keyboard'] = false;
      this.modal._config['focus'] = true;
    }
    this.modal.show();
  }

  public hide() {
    if(this.modal)
      this.modal.hide();
  }
}