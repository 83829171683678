import {Component, Input, OnInit} from '@angular/core';
import {Page, Pageable} from "../../../../utils/pageable";
import {ApiLessonBundle, ApiProductContext} from "../../../model/rest-model";
import {concat, Observable, Subscription, switchMap, tap} from "rxjs";
import {AppEventsService} from "../../../services/app-events.service";
import {StudentRestServiceImpl} from "../../../services/student/student-rest-impl.service";
import {SpinnerService} from "../../../../utils/services/spinner.service";
import {WebSocketService} from "../../../services/web-socket.service";
import {environment} from "../../../../../environments/environment";
import {CourseFilterComponent} from "../../../../utils/course-filter/course-filter.component";
import {CourseFilter} from "../../../../model/CourseFilters";

@Component({
    selector: 'app-student-credits-short',
    templateUrl: './student-credits-short.component.html',
    styleUrls: ['./student-credits-short.component.scss'],
    standalone: false
})
export class StudentCreditsShortComponent implements OnInit {

  bundles: Page<ApiLessonBundle>;
  pageable: Pageable = Pageable.of(0, 5, null);
  creditsTotal: number;
  private _studentId: number;
  isLoading = true
  wsSubscription: Subscription
  scheduleSubscription: Subscription;
  private langs: string[]
  activeCourses = new Array<ApiProductContext>()
  activeCoursesBundles = new Map<ApiProductContext, number>()

  @Input() set studentId(id: number) {
    this._studentId = id;
    this.listenToWebSocketCreditsUpdate()
    this.initQuery();
  }

  constructor(
    private appEvents: AppEventsService,
    private studentRest: StudentRestServiceImpl,
    private spinner: SpinnerService,
    private webSocket: WebSocketService
  ) {}

  ngOnInit(): void {
    this.scheduleSubscription = this.appEvents.creditsUpdate.subscribe((_) => {
      this.initQuery();
    });
  }

  ngOnDestroy(): void {
    if (this.scheduleSubscription) {
      this.scheduleSubscription.unsubscribe();
    }
    if(this.wsSubscription) this.wsSubscription.unsubscribe()
  }

  getLangs() {
    if(this.langs == null)
      this.langs = CourseFilter.filters.map( f => f.filter).slice(1)
    return this.langs
  }

  private initQuery() {
    this.spinner.trace<Page<ApiLessonBundle>>(
      concat(...this.getLangs()
        .map(lang => this.studentRest.getProductContext(this._studentId, lang))
      ).pipe(
        tap(pc => {
          if(pc) this.activeCourses.push(pc)
        }),
        switchMap(_ => this.getAvailableBundles())
      )
      ).subscribe((result) => {
        this.bundles = result;
        this.creditsTotal = 0;
        this.bundles?.content.forEach(
          (bundle) => (this.creditsTotal += bundle.available)
        );
        this.appEvents.credits(null).next(this.creditsTotal);
        this.mapBundles()
        this.isLoading = false
      });
  }


  private mapBundles() {
    this.activeCourses.forEach(c => {
      let bundles = this.getBundles().filter(b => b[0] == c.product.code).map(b => b[1]).reduce((acc, n) => acc + n, 0)
      this.activeCoursesBundles.set(c, bundles)
    })
  }

  private getAvailableBundles(): Observable<Page<ApiLessonBundle>> {
    return this.studentRest.getStudentLessonBundles(
      this._studentId,
      null,
      this.pageable
    )
  }

  getBundles() {
    let groupedBundles =
      this.bundles?.content.reduce(
        (entryMap, e) => entryMap.set(e.product.code, [...entryMap.get(e.product.code)||[], e]),
        new Map()
      );
    let reducedBundles = new Map<string, number>()
    groupedBundles?.forEach((value, key) => reducedBundles.set(key, this.countAvailable(value)))
    return Array.from(reducedBundles);
  }

  countAvailable(value) {
    if(value.length == 0) return 0
    if(value.length == 1) return value[0].available
    return value.reduce((prev, curr) => (prev?.available || 0) + curr.available)
  }

  onPageChange($event) {
    this.pageable = $event;
    this.initQuery();
  }

  listenToWebSocketCreditsUpdate(): any {
    if (!this._studentId) {return; }
    this.wsSubscription = this.webSocket.establish(environment.lessonWsEndpoint)
      .pipe(
        switchMap(connection => connection.subscribe(
            `/col/student/${this.studentId}/creditsUpdate`
          )
        )
      ).subscribe(() =>
        this.initQuery()
      )
  }

  getFilterDefinitionByLang(langCode) {
    return CourseFilterComponent.getFiltersShort().find(filter => filter.filter == langCode)
  }

  getLanguageTranslation(langCode: string) {
    let filter = this.getFilterDefinitionByLang(langCode)
    if(!filter) return ''
    return "EBOOKS.FILTER_LANGS." + filter.translationCode
  }
}
