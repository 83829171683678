import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-styling',
    templateUrl: './styling.component.html',
    styleUrls: ['./styling.component.scss'],
    standalone: false
})
export class StylingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
