import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {of, switchMap} from 'rxjs';
import {AuthorizationServiceProvider} from 'src/app/auth_profile/services/authorization-service.provider';
import {LangProductMapper} from '../../utils/lang-mappers';
import {StudentShoppingCartService} from "../../services/student-shopping-cart.service";
import {FilterDefinition} from "../../../model/CourseFilters";
import { CourseFilterComponent } from '../../../utils/course-filter/course-filter.component';
import {SpinnerService} from "../../../utils/services/spinner.service";

@Component({
    selector: 'app-course-products-page',
    templateUrl: './course-products-page.component.html',
    styleUrls: ['./course-products-page.component.scss'],
    standalone: false
})
export class CourseProductsPageComponent implements OnInit, AfterViewInit {
  studentId: number;
  currency: string = 'eur';
  CourseFilterComponent = CourseFilterComponent;
  _chosenCourse: FilterDefinition

  set chosenCourse(lang: FilterDefinition) {
    this._chosenCourse = lang
  }
  get chosenCourse() {
    return this._chosenCourse
  }

  constructor(private auth: AuthorizationServiceProvider,
              private route: ActivatedRoute,
              private router: Router,
              private shoppingCartService: StudentShoppingCartService,
              private spinner: SpinnerService,
              private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.auth
      .getAuthDetailsService()
      .pipe(switchMap((authDetailsApi) => authDetailsApi.getSelfStudentId()))
      .subscribe((studentId) => (this.studentId = studentId));

    this.shoppingCartService.getCurrency().subscribe(currency =>
      this.currency = currency
    )
    if(!this.chosenCourse || this.chosenCourse.fullName == 'ALL')
      this.spinner.trace(
        of(null)
      ).subscribe()
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  getCourseName() {
    return LangProductMapper.mapLangCodeToLangName(this.chosenCourse.filter)
  }
}
