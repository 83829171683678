import { Component, OnInit } from '@angular/core';
import { QuestionComponentBase } from 'src/app/model/QuestionComponentBase';
import { ArrayUtils } from 'src/app/utils/arrays';
import { Images, ImagesAnswer, PlaceholderAnswer } from '../../../../model/cspa/questions';
import { QuestionService } from '../../../../services/api/impl/rest/question.service';
import { PictureCardItem } from './picture-card/picture-card.component';
import {LogsService} from "../../../../../utils/services/logs.service";
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';

@Component({
    selector: 'pic',
    templateUrl: './pic.component.html',
    styleUrls: ['./pic.component.scss'],
    standalone: false
})
export class PicComponent  extends QuestionComponentBase<ImagesAnswer, Images> implements OnInit {

  pictures: PictureCardItem[] = [];
  selectedParis: Array<[PictureCardItem, PictureCardItem]> = [];
  selectedItem: PictureCardItem = null;

  constructor(private questionService: QuestionService,
              logs: LogsService,
              authProvider: AuthorizationServiceProvider) {
      super(logs, authProvider);
  }

  ngOnInit() {
    this.questionSubscription = this.questionService.answerRequest.subscribe(sendAnswer => {
      sendAnswer &&
      sendAnswer['answerType'] === 'pct' &&
      sendAnswer['getNewAnswer'] === true &&
      this.getAnswerForSubmit()
    })
      this.prepareQuestionData()
  }

  getAnswerForSubmit() {
    this.applySelectedPairsOnAnswer();
    if(this.answer.answers)
      this.sendAnswer()
  }

  setUpAnswer(answer: ImagesAnswer) {
    if (!answer) {
      this.answer = new ImagesAnswer();
      this.answer.answers = [];
    } else {
      this.answer = answer;
    }
    this.readSelectionsFromAnswer();
  }

  readSelectionsFromAnswer() {
    this.selectedParis = [];
    if (!this.pictures || this.pictures.length === 0 || !this.answer) {
      return;
    }

    this.pictures.forEach( p => {
      p.reversed = false;
      p.selected = false;
    });

    for (const answerEntry of this.answer.answers.filter( e => e.name && e.val && e.val.length === 1)) {
      const answerWordItem = this.pictures.find( p => p.word === answerEntry.name );
      const answerImageItem = this.pictures.find( p => p.image === answerEntry.val[0]);

      if (answerImageItem && answerWordItem) {
        answerWordItem.reversed = answerImageItem.reversed = true;
        this.selectedParis.push([answerWordItem, answerImageItem]);
      }
    }
  }

  applySelectedPairsOnAnswer() {
    if (!this.answer) {
      return;
    }

    this.answer.answers = [];
    this.answer.answers = this.selectedParis.map( ([l, r]) => {
      const word = l.word ? l.word : r.word;
      const image = l.image ? l.image : r.image;
      const res = new PlaceholderAnswer();
      res.name = word;
      res.val = [image];
      return res;
    });
  }

  prepareQuestionData(): void {
    if (!this.question) {
      return;
    }

    this.pictures = this.question.question.definition.words
      .map( word => new PictureCardItem(word, null)).concat(
        this.question.question.definition.images.map(
          image => new PictureCardItem(null, image)
        )
      );
    ArrayUtils.shuffleArray(this.pictures);
    this.readSelectionsFromAnswer();
  }

  onCardClick(item: PictureCardItem) {
    const foundInPairIndex = this.selectedParis.findIndex( ([w, i]) => item === w || item === i);
    // if found in selected paris remove the pair,
    // unselect what is selected and select clicked item only
    if (foundInPairIndex >= 0) {
      this.selectedParis[foundInPairIndex][0].reversed = false;
      this.selectedParis[foundInPairIndex][1].reversed = false;
      this.selectedParis.splice(foundInPairIndex, 1);
      if (this.selectedItem ) {
        this.selectedItem.selected = false;
      }
      this.selectedItem = item;
      item.selected = true;
    } else if (this.selectedItem == null) {
      // if nothing is selected
      item.selected = true;
      this.selectedItem = item;
    } else if (item === this.selectedItem) {
      // on selected item click unselect it
      item.selected = false;
      this.selectedItem = null;
    } else if (
      (item.image == null && this.selectedItem.image == null)
      || (item.word == null && this.selectedItem.word == null)
    ) {
      // both clicked are the same type, unselect previous
      this.selectedItem.selected = false;
      item.selected = true;
      this.selectedItem = item;
    } else {
      // correct pair was selected
      this.selectedItem.selected = item.selected = false;
      this.selectedItem.reversed = item.reversed = true;
      this.selectedParis.push([this.selectedItem, item]);
      this.selectedItem = null;
    }
    if(this.selectedParis.length === this.pictures.length/2)
      this.inProgressEmitter.emit(false)
    else
      this.inProgressEmitter.emit(true)
  }
}
