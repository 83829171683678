import {Component, OnInit} from '@angular/core';
import {StudentShoppingCartService} from "../../../services/student-shopping-cart.service";
import {switchMap, tap} from "rxjs";
import {Cart, Product} from "../../../model/products";

@Component({
    selector: 'app-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.scss'],
    standalone: false
})
export class ShoppingCartComponent implements OnInit {

  isLoading = false
  cart: Cart
  products: Product[]
  constructor(
    private shoppingCart: StudentShoppingCartService
  ) { }

  ngOnInit(): void {
    this.shoppingCart.getCart().pipe(
      tap(cart => {
        this.cart = cart
        this.products = this.cart.items
      }),
      switchMap(_ => this.shoppingCart.orderComplete)
    ).subscribe( _ => {
      this.isLoading = !this.isLoading
    })
  }

  isCartEmpty() {
    return !this.cart?.total
  }

  getTotal() {
    return `${this.cart.total} ${this.shoppingCart.getCurrencySign()}`
  }

  checkout() {
    this.shoppingCart.checkout()
  }
}
