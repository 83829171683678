import { formatDate } from '@angular/common';
import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {forkJoin, map, Observable, switchMap, take, tap} from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { IdentifiedRoomTemplate, RoomDef, ScheduleRow } from '../../models/video.model';
import {SchedulesListComponent, SchedulesListProvider} from '../../components/schedules-list/schedules-list.component';
import { DateUtils } from '../../utils/date-utils';
import { Page, Pageable } from 'src/app/utils/pageable';
import { TemplatesListProvider } from '../../components/templates-list/templates-list.component';
import { RoomsListProvider } from '../../components/rooms-list/rooms-list.component';
import {VideoApi} from "../../services/video-rest.service";
import {LoadComponentDirective} from "../../../utils/load-component/load-component.directive";

@Component({
    selector: 'lessons-planer',
    templateUrl: './lessons-planer.component.html',
    styleUrls: ['./lessons-planer.component.scss'],
    standalone: false
})
export class LessonsPlanerComponent implements OnInit {

  schoolId: number
  studentId: number
  dayStart = new Date(DateUtils.weekStart(DateUtils.getTodayDate()))
  dayEnd = new Date(DateUtils.weekEnd(DateUtils.getTodayDate()))
  weekOffset = 0
  scheduleListsProvider: SchedulesListProvider
  templateListsProvider: TemplatesListProvider
  roomListsProvider: RoomsListProvider
  @ViewChild(LoadComponentDirective, { static: true}) loadComponent!: LoadComponentDirective

  constructor(private route: ActivatedRoute,
              private auth: AuthorizationServiceProvider,
              private apiProvider: NativeServiceApiProvider,
              private router: Router,
              private authProvider: AuthorizationServiceProvider) {}

  ngOnInit(): void {
    this.prepareDates()
    this.auth.getAuthDetailsService().pipe(
      switchMap( authDetailsApi =>
        forkJoin([authDetailsApi.getSelfStudentId(), authDetailsApi.getSelfSchoolId()])
      ),
      tap( ([studentId, schoolId]) => {
        this.studentId = studentId
        this.schoolId = schoolId
      }),
      switchMap(_ => this.route.paramMap),
      tap(params => {
        if(!(+params.get("studentId") && +params.get("schoolId"))) {
          this.router.navigate(
            [`school/${this.schoolId}/student/${this.studentId}`],
            {
              relativeTo: this.route,
              queryParamsHandling: 'merge'
            }
          )
        }
      }),
      switchMap(_ => this.apiProvider.video()),
      tap(api => this.prepareProviders(api)),
      tap(_ => this.onLoadComponent())
    ).subscribe()
  }

  private prepareProviders(api: VideoApi) {
    const schoolId = this.schoolId
    const studentId = this.studentId
    this.scheduleListsProvider = new class implements SchedulesListProvider {
      listSchedules(focusDate: number): Observable<ScheduleRow[]> {
        return api.listStudentSchedules(schoolId, studentId, focusDate)
      }
    }
    this.templateListsProvider = new class implements TemplatesListProvider {
      listGroups(pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
        return api.listStudentGroups(schoolId, studentId, pageable)
      }
    }
    this.roomListsProvider = new class implements RoomsListProvider {
      listRooms(pageable: Pageable): Observable<Page<RoomDef>> {
        return api.listStudentRooms(schoolId, studentId, pageable)
      }
    }
  }

  getDate() {
    return `${formatDate(this.dayStart, 'd', 'en_US')} -
    ${formatDate(this.dayEnd, 'd', 'en_US')}
    ${this.dayEnd.toLocaleString('en-us', { month: 'short' })}`
  }

  goToToday() {
    this.weekOffset = 0
    this.prepareDates()
  }

  getNextWeek() {
    this.weekOffset++
    this.prepareDates()
  }

  getPreviousWeek() {
    this.weekOffset--
    this.prepareDates()
  }

  prepareDates() {
    this.dayStart = new Date(DateUtils.weekStart(DateUtils.queryDate(this.weekOffset)))
    this.dayEnd = new Date(DateUtils.weekEnd(DateUtils.queryDate(this.weekOffset)))
    this.onLoadComponent()
  }

  onLoadComponent() {
    this.loadComponent.viewContainerRef.clear()
    let componentRef;
      componentRef = this.loadComponent.viewContainerRef.createComponent(SchedulesListComponent)
      componentRef.instance.schedulesProvider = this.scheduleListsProvider
      componentRef.instance.dayStart = this.dayStart
      componentRef.instance.dayEnd = this.dayEnd
      componentRef.instance.redirectPathPrefix = "./schedule"
  }
}
