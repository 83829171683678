import {Component, Input, OnInit} from '@angular/core';
import {tap} from 'rxjs';
import {ApiProduct, ProductsUtils} from 'src/app/col/model/products';
import {StudentRestServiceImpl} from 'src/app/col/services/student/student-rest-impl.service';
import {LangProductMapper} from 'src/app/col/utils/lang-mappers';
import {SpinnerService} from 'src/app/utils/services/spinner.service';
import {FilterDefinition} from "../../../../../model/CourseFilters";

@Component({
    selector: 'app-english-business-products',
    templateUrl: './english-business-products.component.html',
    styleUrls: ['./english-business-products.component.scss'],
    standalone: false
})
export class EnglishBusinessProductsComponent implements OnInit {
  upfront: ApiProduct[];
  instalment: ApiProduct[];
  _currency: string;
  _studentId: number
  _chosenCourse: FilterDefinition
  @Input()
  set chosenCourse(lang: FilterDefinition) {
    this._chosenCourse = lang
  }
  get chosenCourse() {
    return this._chosenCourse
  }
  @Input()
  set studentId(value: number) {
    this._studentId = value;
  }
  get studentId() {
    return this._studentId;
  }
  @Input()
  set currency(currency: string) {
    this._currency = currency;
    this.refreshProducts();
  }

  constructor(private studentRest: StudentRestServiceImpl,
              private spinner: SpinnerService) {}

  ngOnInit(): void {
    this.spinner
      .trace<ApiProduct[]>(
        this.studentRest
          .getProductsList('en', this._currency)
          .pipe(tap((products) => this.filterProducts(products)))
      ).subscribe();
  }

  refreshProducts(): any {
    if (!this._currency) {return; }
    this.studentRest.getProductsList('en', this._currency).subscribe(products => this.filterProducts(products));
  }

  getLangName() {
    return LangProductMapper.mapLangCodeToLangName('en.bs');
  }

  filterProducts(products: ApiProduct[]): void {
    const filtered = ProductsUtils.filterByProductLabel(
      products,
      ProductsUtils.EN_BS_PRODUCT_LABEL
    );
    this.upfront = ProductsUtils.filterByVariantLabel(
      filtered,
      'category:lessons.en.bs.upfront'
    );
    this.instalment = ProductsUtils.filterByVariantLabel(
      filtered,
      'category:lessons.en.bs.instalment'
    );
  }
}
