import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from "@angular/router";
import {Observable, map, switchMap, of, tap} from "rxjs";
import { AuthorizationServiceProvider } from "./authorization-service.provider";
import {UserRole} from "./api/authorization.api";

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private authServiceProv: AuthorizationServiceProvider,
    private router: Router) {}

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const roles = route.data['roles'] as Array<UserRole>;
    return this.authServiceProv.getCommonRoutines().pipe(
      switchMap( auth => {
        if (auth.isNativeAuthorization()) return of(true);
        return this.authServiceProv.getAuthDetailsService().pipe(
          switchMap( detailsApi => detailsApi.getUserRole()),
          map( userRole => roles.some( expectedRole => expectedRole === userRole)),
          tap( hasRole => !hasRole && this.router.navigateByUrl('/forbidden'))
        )
      }));
  }
}
