import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FilterDefinition} from "../../../model/CourseFilters";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {switchMap} from "rxjs";
import { CourseFilterComponent } from 'src/app/utils/course-filter/course-filter.component';

@Component({
    selector: 'app-teacher-list-page',
    templateUrl: './teacher-list-page.component.html',
    styleUrls: ['./teacher-list-page.component.scss'],
    standalone: false
})
export class TeacherListPageComponent implements OnInit, AfterViewInit {
  CourseFilterComponent = CourseFilterComponent;
  studentId: number
  chosenCourse: FilterDefinition

  constructor(private authService: AuthorizationServiceProvider,
              private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.authService.getAuthDetailsService().pipe(
      switchMap(api => api.getSelfStudentId())
    ).subscribe((studentId) => {
      this.studentId = studentId
    })
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
