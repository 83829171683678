import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {LangService} from "../../services/lang.service";

@Component({
    selector: 'app-book-details-entry-point',
    templateUrl: './book-details-entry-point.component.html',
    styleUrls: ['./book-details-entry-point.component.scss'],
    standalone: false
})
export class BookDetailsEntryPointComponent implements OnInit {

  constructor(private router: Router,
    private langService: LangService,
    private route: ActivatedRoute) {
      this.route.paramMap.subscribe(params => {
          this.router.navigate([`student/ebooks/${params.get('ebookId')}`]);
          if (params['lang']) {
            this.langService.changeLang(params['lang'])
          }
        }
      )
    this.route.queryParams.subscribe(params => {

    })
  }

  ngOnInit(): void {
  }

}
