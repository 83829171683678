import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap, switchMap } from 'rxjs';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { RoomDef } from '../../models/video.model';
import {SpinnerService} from "../../../utils/services/spinner.service";

@Component({
    selector: 'app-teacher-room-details-page',
    templateUrl: './teacher-room-details-page.component.html',
    styleUrls: ['./teacher-room-details-page.component.scss'],
    standalone: false
})
export class TeacherRoomDetailsPageComponent implements OnInit {

  teacherId: number;
  schoolId: number;
  roomId: string;
  room: RoomDef

  constructor(private route: ActivatedRoute,
              private provider: NativeServiceApiProvider,
              private spinnerService: SpinnerService) {
    this.route.paramMap.subscribe(params => {
        this.teacherId = +params.get('teacherId')
        this.schoolId = +params.get('schoolId')
        this.roomId = params.get('roomId')
      })
  }

  ngOnInit(): void {
    this.spinnerService.trace(
      this.provider.video().pipe(
        switchMap(api => api.getTeacherRoomDetails(
          this.schoolId, this.teacherId, this.roomId)
        ),
        tap<RoomDef>(room => this.room = room)
      )
    ).subscribe()
  }

  getScheduleName() {
    return this.room?.name
  }
}
