import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[loadComponent]',
    standalone: false
})
export class LoadComponentDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
