import { Component, OnInit } from '@angular/core';
import {switchMap, tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Page, Pageable} from "../../../utils/pageable";
import {IdentifiedRoomTemplate} from "../../models/video.model";
import {School} from "../../../auth_profile/model/casa/casa-model";
import {LocalStateService} from "../../../utils/services/local-state.service";
import {CasaRestService} from "../../../auth_profile/services/api/impl/rest/casa-rest.service";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {LocalState, SortingRule} from "../../utils/sorting-utils";
import {VideoRestService} from "../../services/video-rest.service";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {SpinnerService} from "../../../utils/services/spinner.service";

class VideoGroupsSorting extends SortingRule{
  constructor(public name: string, sorting: string[]) {
    super(sorting)
  }
  static ByName = new VideoGroupsSorting("GROUPS.SORTING.BY_NAME", ['details.name,asc']);
  static ByNameDesc = new VideoGroupsSorting("GROUPS.SORTING.BY_NAME_DESC", ['details.name,desc']);
  static ByDate = new VideoGroupsSorting("GROUPS.SORTING.BY_DATE", ['created,asc']);
  static ByDateDesc = new VideoGroupsSorting("GROUPS.SORTING.BY_DATE_DESC", ['created,desc']);
  static Offline = new VideoGroupsSorting("GROUPS.SORTING.OFFLINE", ['details.name,asc']);
  static Archived = new VideoGroupsSorting("GROUPS.SORTING.DELETED", ['created,desc']);
  static values = [
    VideoGroupsSorting.ByName, VideoGroupsSorting.ByNameDesc, VideoGroupsSorting.ByDate,
    VideoGroupsSorting.ByDateDesc,
    VideoGroupsSorting.Offline,
    VideoGroupsSorting.Archived
  ]
}

@Component({
    selector: 'app-teacher-groups-page',
    templateUrl: './teacher-groups-page.component.html',
    styleUrls: ['./teacher-groups-page.component.scss'],
    standalone: false
})
export class TeacherGroupsPageComponent implements OnInit {

  schoolId: number;
  teacherId: number;
  sortingRules = VideoGroupsSorting.values;
  currentState: LocalState<VideoGroupsSorting>
  school: School;
  groupsPage: Page<IdentifiedRoomTemplate>;

  constructor(
    route: ActivatedRoute,
    private rest: VideoRestService,
    private router: Router,
    private casaRest: CasaRestService,
    private authProvider: AuthorizationServiceProvider,
    private localStateService: LocalStateService,
    private apiProvider: NativeServiceApiProvider,
    private spinnerService: SpinnerService
  ) {
    authProvider.getAuthDetailsService().pipe(
      switchMap(api => api.getSelfTeacherId()),
      tap(teacherId => this.teacherId = teacherId),
      switchMap(_ => route.paramMap),
      tap( params => this.schoolId = Number(params.get('schoolId'))),
    ).subscribe();
  }

  ngOnInit() {
    this.spinnerService.trace(
      this.loadSchool().pipe(
        switchMap( _ => this.restoreGroupLocalState()),
        switchMap( state => this.loadPage(state))
      )
    ).subscribe()
  }

  private restoreGroupLocalState() {
    const contextPath = `/schools/${this.schoolId}/groups`;
    return this.localStateService.get<LocalState<VideoGroupsSorting>>(contextPath,
      () => new LocalState<VideoGroupsSorting>("", VideoGroupsSorting.ByDateDesc, Pageable.of(0, 10, null))
    )
  }

  private loadPage(state: LocalState<VideoGroupsSorting>) {
    this.currentState = state;
    state.page.sort = state.sortingRule.sorting;
    return this.apiProvider.video().pipe(
      switchMap(api => {
        return api.listTeacherGroups(this.schoolId, this.teacherId, this.currentState.page);
      }),
      tap(groupsPage => this.groupsPage = groupsPage)
    )
  }

  private loadSchool() {
    return this.authProvider.getAuthDetailsService().pipe(
      switchMap(api => api.getSelfTeacherId()),
      switchMap(teacherId => this.casaRest.getTeacherSchool(teacherId)),
      tap(school => this.school = school)
    );
  }

  getGroupsNumber() {
    if (!this.groupsPage) return null;
    return this.groupsPage.totalElements;
  }

  doSearch() {
    this.loadPage(this.currentState).subscribe();
  }

  restartSearch() {
    this.currentState.page = this.currentState.page.first();
    this.doSearch();
  }

  loadNewPage(pageToLoad: Pageable) {
    this.currentState.page = pageToLoad;
    this.doSearch();
  }

  getName(template: IdentifiedRoomTemplate) {
    return template.details.name;
  }

  getTeacherName(template: IdentifiedRoomTemplate) {
    const teacher = template.participants.find( p => p.details.role = 'Teacher');
    let email = teacher.details.email;
    if (email == null) email = "empty"
    return `${teacher.details.name} (${email})`;
  }

  getNbOfStudents(template: IdentifiedRoomTemplate) {
    return template.participants.length - 1;
  }
}
