import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LangService} from "../../services/lang.service";

@Component({
    selector: 'app-login-entry-point',
    templateUrl: './login-entry-point.component.html',
    styleUrls: ['./login-entry-point.component.scss'],
    standalone: false
})
export class LoginEntryPointComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private langService: LangService) {
    this.router.navigate(['in-app/login'])
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['lang']) {
        this.langService.changeLang(params['lang'])
      }
    })
  }

}
