import { Component, OnInit } from '@angular/core';
import { QuestionComponentBase } from 'src/app/model/QuestionComponentBase';
import { PatternParser } from 'src/app/utils/placeholder-parser';
import { environment } from 'src/environments/environment';
import { Jumble, JumbleAnswer, PlaceholderAnswer } from '../../../../model/cspa/questions';
import { QuestionService } from '../../../../services/api/impl/rest/question.service';
import {LogsService} from "../../../../../utils/services/logs.service";
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';

class QuestionToken {
  isPlaceholder(): boolean {
    return this instanceof PlaceholderToken;
  }
}

class StaticTextToken extends QuestionToken {
  constructor(public content: string) {
    super();
  }
}

class TokenAnswer {
  constructor(public content: string) {}
}

class PlaceholderToken extends QuestionToken {
  public answer: TokenAnswer;
  constructor(public placeholderName: string) {
    super();
  }
}

@Component({
    selector: 'jmbl',
    templateUrl: './jmbl.component.html',
    styleUrls: ['./jmbl.component.scss'],
    standalone: false
})

export class JmblComponent extends QuestionComponentBase<JumbleAnswer, Jumble> implements OnInit {

  questionTokens: (PlaceholderToken | StaticTextToken)[];
  questionAnswers: TokenAnswer[];

  constructor(private questionService: QuestionService,
              logs: LogsService,
              authProvider: AuthorizationServiceProvider) {
      super(logs, authProvider);
   }

  ngOnInit(): void {
      this.prepareQuestionTokens()
      this.inProgressEmitter.emit(true)
      this.questionSubscription = this.questionService.answerRequest.subscribe(sendAnswer =>
        sendAnswer &&
        sendAnswer['answerType'] === 'jmb' &&
        sendAnswer['getNewAnswer'] === true &&
        this.getAnswerForSubmit()
      )
  }

  getAnswerForSubmit() {
    this.mapTokensOnAnswers();
    this.sendAnswer()
  }

  setUpAnswer(answer: JumbleAnswer) {
    if (answer?.answer) {
      this.answer = answer;
    } else {
      this.answer = new JumbleAnswer();
      this.answer.answer = [];
    }
    this.mapAnswerOnTokens();
  }

  getAnswer(token: any) {
    return token instanceof PlaceholderToken? token.answer : null
  }

  getContent(token: any) {
    return token.content
  }

  mapAnswerOnTokens() {
    if (!this.questionTokens || !this.questionAnswers || !this.answer) {
      return;
    }

    this.questionTokens
      .filter( qt => qt instanceof PlaceholderToken)
      .forEach( qt => this.unassignAnswer(qt as PlaceholderToken));

    this.answer.answer
      .filter(ae => ae.name && ae.val && ae.val.length === 1)
      .forEach( ae => {
        const targetToken = this.questionTokens
          .find( t => (t instanceof PlaceholderToken) && t.placeholderName === ae.name ) as PlaceholderToken;
        const targetAnswer = this.questionAnswers.find( qa => qa.content === ae.val[0]);
        if (!targetToken || !targetAnswer) {
          return;
        }
        this.assignAnswer(targetAnswer, targetToken);
      });
  }

  mapTokensOnAnswers() {
    if (!this.questionTokens || !this.questionAnswers) {
      return;
    }

    this.answer.answer = [];
    this.answer.answer = this.questionTokens
      .filter( qt => qt instanceof PlaceholderToken)
      .map ( qt => qt as PlaceholderToken)
      .filter( qt => qt.answer)
      .map( qt => {
        const res = new PlaceholderAnswer();
        res.name = qt.placeholderName;
        res.val = [qt.answer.content];
        return res;
      });
  }

  // find possible target if empty, remove from assigned, clear target, assign to the target
  assignAnswer(tokenAnswer: any, targetQuestionToken?: PlaceholderToken) {
    if (targetQuestionToken && !targetQuestionToken.isPlaceholder()) {
      return;
    }

    const target: PlaceholderToken = targetQuestionToken ? targetQuestionToken :
      this.questionTokens
        .filter( qt => qt instanceof PlaceholderToken)
        .map (qt => qt as PlaceholderToken)
        .find( qt => !qt.answer);

    if (!target) {
      this.unassignAnswer(this.questionTokens.find(qt => qt instanceof PlaceholderToken))
      this.assignAnswer(tokenAnswer)
      return;
    }

    const source = this.questionTokens
      .find( qt => (qt instanceof PlaceholderToken) && qt.answer === tokenAnswer);
    if (source) {
      this.unassignAnswer(source as PlaceholderToken);
    }

    if (target.answer) {
      this.unassignAnswer(target);
    }

    const answerIndex = this.questionAnswers.indexOf(tokenAnswer);
    if (answerIndex < 0) {
      return;
    }

    this.questionAnswers.splice(answerIndex, 1);
    target.answer = tokenAnswer;
    this.inProgressEmitter.emit(false)
  }

  unassignAnswer(target: any) {
    if (!target.answer) {
      return;
    }
    this.questionAnswers.push(target.answer);
    target.answer = null;
    this.inProgressEmitter.emit(true)
  }

  hasImage() {
    return this.question && this.question.question.definition.img;
  }

  getImageSrc() {
    return environment.awsBase + this.question.question.definition.img;
  }

  prepareQuestionTokens(): void {
    this.questionTokens = PatternParser.parse(this.question.question.definition.question)
      .map( item => item.isPlaceholder ?
          new PlaceholderToken(item.content) : new StaticTextToken(item.content)
    );
    this.questionAnswers = this.question.question.definition.answers
    .map( answer => new TokenAnswer(answer));

    this.mapAnswerOnTokens();
  }
}
