import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { Authorization } from '../../model/prototypes';
import {forkJoin, switchMap} from "rxjs";
import {AuthorizationServiceProvider} from "../../services/authorization-service.provider";
import {ToastNotification, ToastNotificationsService} from "../../../utils/services/toast-notification.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
    standalone: false
})
export class LoginPageComponent implements OnInit {
  auth = new Authorization();
  errorMessage: string;

  constructor(private apiProvider: AuthorizationServiceProvider,
    private router: Router,
              private notificationService: ToastNotificationsService,
              private translationService: TranslateService) {

  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (!this.auth || !this.auth.username || this.auth.username.trim().length < 3 || !this.auth.password || this.auth.password.length < 3)
      return
    this.errorMessage = null;
    // console.log(this.auth.username)
    // this.apiProvider.getCommonRoutines().pipe(
    //   switchMap(api => api.login(this.auth))
    // ).subscribe({
    //   next: () => {
    //     this.router.navigate([''])
    //   },
    //   error: e => {
    //     this.errorMessage = e.message;
    //   }
    // });
    throw new Error("Unsupported");
  }

  ssoLogin() {
    this.apiProvider.getMobile().pipe(
      switchMap( api => api.ssoLogin())
    ).subscribe({
      error: err => {
        // forkJoin([
        //   this.translationService.get("LOGIN.ERROR.TITLE"),
        //   this.translationService.get("LOGIN.ERROR.MESSAGE")
        // ]).subscribe(([title, message]) => {
        //   this.notificationService.display(new ToastNotification(title, message, true, 3000))
        // })
      }
    })
  }
}
