import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import * as Sentry from "@sentry/angular";
import { ToastNotification, ToastNotificationsService } from "src/app/utils/services/toast-notification.service";

@Component({
    selector: 'app-toast-notifications',
    templateUrl: './toast-notifications.component.html',
    styleUrls: ['./toast-notifications.component.scss'],
    standalone: false
})
export class ToastNotificationsComponent implements OnInit, OnDestroy {

  private notificationsSubs: Subscription;
  messages: ToastNotification[] = [];
  closeBtnShown: boolean = false
  isOnTop: boolean = true
  constructor(
    private toastNotificationsService: ToastNotificationsService
  ) { }

  ngOnInit() {
    this.notificationsSubs = this.toastNotificationsService.toDisplay.subscribe(
      message => this.displayMessage(message)
    );
  }

  ngOnDestroy(): void {
    this.notificationsSubs.unsubscribe();
  }

  private displayMessage(message: ToastNotification) {
    if(this.messages.find(m => m.title == message.title))
      return
    this.messages.splice(0,0, message);
    if(message.timeout) {
      setTimeout(() => this.removeMessage(message), message.timeout);
    }
    else {
      this.closeBtnShown = true
    }
  }

  private removeMessage(message: ToastNotification) {
    const messageIndex = this.messages.indexOf(message);
    if (messageIndex < 0) return;
    this.messages.splice(messageIndex, 1);
  }

  getMessage(notification: ToastNotification) {
    if(notification.message) return notification.message
    else return " "
  }

  getTitle(notification: ToastNotification) {
    if(notification.title) return notification.title
    else return " "
  }

  getClasses(notification: ToastNotification) {
    return notification.toastClasses
  }

  getDuration(notification: ToastNotification) {
    return ""
  }

  isMsgShown(notification: ToastNotification) {
    return notification.isMsgShown
  }

  onClose(notification: ToastNotification) {
    this.removeMessage(notification)
  }

  reportAnError(notification: ToastNotification){
    Sentry.showReportDialog({
      eventId: notification.eventId,
      onLoad: () => {
        this.isOnTop = false
      },
      onClose: () => {
        this.isOnTop = true
      }
    })
  }
}
