import { Component, Input, OnInit } from '@angular/core';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { OwnershipLocalState } from '../../model/book-model';
import {switchMap} from "rxjs";
import {LogsService} from "../../../utils/services/logs.service";

@Component({
    selector: 'book-list-item',
    templateUrl: './book-list-item.component.html',
    styleUrls: ['./book-list-item.component.scss'],
    standalone: false
})
export class BookListItemComponent implements OnInit {
  private _ownershipLS: OwnershipLocalState;

  @Input()
  set ownershipLS(ownershipLS: OwnershipLocalState) {
    this._ownershipLS = ownershipLS;
    if (!ownershipLS) return;
    this.isContentLoading = false;
    this.logService.log(`showing book ${ownershipLS.ownership.eBook.id}:${ownershipLS.ownership.eBook.release.id}`)
    this.reloadCover();
  }
  get ownershipLS() {
    return this._ownershipLS;
  }

  isImgLoading = true
  isContentLoading = true
  ebookCover = null

  constructor(
    private apiProvider: NativeServiceApiProvider,
    private logService: LogsService) {}

  ngOnInit(): void {

  }

  private reloadCover() {
    if (!this.ownershipLS) return;
    this.logService.log(`asking for book ${this.ownershipLS.ownership.eBook.id}:${this.ownershipLS.ownership.eBook.release.id} cover`)
    this.isImgLoading = true;
    this.apiProvider.books().pipe(
      switchMap( api => api.getImageUrl(this.ownershipLS.ownership.eBook.id, this.ownershipLS.ownership.eBook.release.id))
    ).subscribe( data => {
      this.logService.log(`got book ${this.ownershipLS.ownership.eBook.id}:${this.ownershipLS.ownership.eBook.release.id} cover: ${data}`)
      this.ebookCover = data;
      this.isImgLoading = false;
    });
  }

  getEbookName() {
    return this.ownershipLS?.ownership.eBook.name
  }

  getLanguage() {
    return this.ownershipLS && this.ownershipLS.ownership.eBook.categories && this.ownershipLS.ownership.eBook.categories[0]
  }

  getEbookId() {
    return this.ownershipLS?.ownership.eBook.id
  }

  getImage() {
    return this.ebookCover
  }


  getEbookStateClass() {
    if(this.ownershipLS?.downloaded)
      return 'ebook-downloaded'

    if(!this.ownershipLS?.downloaded &&
      this.ownershipLS?.ownership.availableShares === 0)
      return 'ebook-not-downloaded'

    return '';
  }
}
