import {Component, Input, OnInit} from '@angular/core';
import {LangProductMapper} from "../../../utils/lang-mappers";
import {ApiCourseContext} from "../../../model/rest-model";

@Component({
    selector: 'app-progress-card-short',
    templateUrl: './progress-card-short.component.html',
    styleUrls: ['./progress-card-short.component.scss'],
    standalone: false
})
export class ProgressCardShortComponent implements OnInit {
   private _langCode: string
   private _progress: ApiCourseContext
  @Input()
  set langCode(lang: string) {
    this._langCode = lang
  }
  get langCode() {
   return this._langCode
  }
  @Input()
  set progress(progress: ApiCourseContext) {
    this._progress = progress
  }
  get progress() {
   return this._progress
  }

  constructor() {}

  ngOnInit(): void {}

  getCourseName() {
    return LangProductMapper.mapLangCodeToLangName(this.langCode);
  }

  getCourseClass() {
    return this.langCode === 'en.bs'
      ? 'course-enbus'
      : `course-${this.langCode}`;
  }

  getStage() {
     if(this.langCode === 'en.bs') return ''
     return `Stage ${this.progress.course.name.split(' ').reverse()[0]}`
  }

  getProgress() {
    return this.progress.lessonsProgress
      ? this.progress.lessonsProgress * 100
      : 0;
  }
}
