import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/utils/modal/modal.component';

@Component({
    selector: 'app-instruction-modal',
    templateUrl: './instruction-modal.component.html',
    styleUrls: ['./instruction-modal.component.scss'],
    standalone: false
})
export class InstructionModalComponent implements OnInit {

  exStates = [ 'ready', 'locked', 'bad', 'enough', 'good', 'perfect']

  @ViewChild("instructionModal", {static: true})
  instructionModal: ModalComponent;

  @Input() sectionPath: string;
  @Input() exerciseName: string;

  constructor() { }

  ngOnInit(): void { }

  show(){
    this.instructionModal.show()
  }
}
