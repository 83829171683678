import { Directive, Input } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';

@Directive({
    selector: '[appAfterDate]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: AfterDateValidatorDirective,
            multi: true,
        },
    ],
    standalone: false
})
export class AfterDateValidatorDirective implements Validator {
  @Input()
  appAfterDate: Date;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.appAfterDate ? dateAfter(this.appAfterDate)(control) : null;
  }
}

export function dateAfter(date: Date): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let forbidden = true;
    const value = control.value;
    if (value) {
      if (new Date(value).getTime() > new Date(date).getTime()) {
        forbidden = false;
      }
    }
    return forbidden ? { invalidDate: { value: control.value } } : null;
  };
}
