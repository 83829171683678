import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, tap } from 'rxjs';
import { RoomDef } from '../../models/video.model';
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";

@Component({
    selector: 'app-room-details-page',
    templateUrl: './room-details-page.component.html',
    styleUrls: ['./room-details-page.component.scss'],
    standalone: false
})
export class RoomDetailsPageComponent implements OnInit {
  studentId: number;
  schoolId: number;
  roomId: string;
  room: RoomDef

  constructor(private route: ActivatedRoute,
    private provider: NativeServiceApiProvider) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      map(params => {
        this.studentId = +params.get('studentId')
        this.schoolId = +params.get('schoolId')
        this.roomId = params.get('roomId')
      }),
      switchMap( _ => this.provider.video()),
      switchMap(api => api.getStudentRoomDetails(
        this.schoolId, this.studentId, this.roomId)
      ),
      tap<RoomDef>(room => this.room = room)
    ).subscribe()
  }

  getScheduleName() {
    return this.room?.name
  }
}
