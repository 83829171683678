import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {switchMap, forkJoin, of, finalize, tap} from "rxjs";
import { AuthorizationServiceProvider } from "../../services/authorization-service.provider";
import {EmulatedAuthService} from "../../services/api/authorization.api";

@Component({
    selector: 'app-oauth',
    templateUrl: './oauth.component.html',
    styleUrls: ['./oauth.component.scss'],
    standalone: false
})
export class OauthComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authServiceProv: AuthorizationServiceProvider,
    private router: Router
    ) {
      this.activatedRoute.queryParams.subscribe(params => {
          this.processOauthCode(params);
      });
  }

  private processOauthCode(params) {
    const oauthCode = this.mapOauthParams(params);
    this.authServiceProv.getOnWebAuthorization().pipe(
      switchMap( authApi =>
        authApi.authorizeCode(oauthCode.code).pipe(
          switchMap(_ => this.authServiceProv.getEmulatedAuthService()),
          switchMap( (authEmulator:EmulatedAuthService) =>
            authEmulator ? authEmulator.assignDeviceId() : of(null)),
          tap(
            _ => {
              if (oauthCode.state) {
                const finalState = authApi.resolveState(oauthCode.state);
                this.router.navigateByUrl(finalState).then(() => {
                  // nothing to do for now
                });
              } else {
                this.router.navigate(['/']);
              }
            }
          )
        )
      )
    ).subscribe()
  }

  private mapOauthParams(params): any {
    const res = {code: params.code, state: null as string};
    if (params.state) {
      res.state = atob(params.state);
    }
    return res;
  }

  ngOnInit() {
  }

}
