import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LangService} from 'src/app/services/lang.service';
import {NativeServiceApiProvider} from "../../services/native-api-provider.service";
import {SpinnerService} from "../../utils/services/spinner.service";
import {AuthorizationServiceProvider} from "../../auth_profile/services/authorization-service.provider";
import {UserRole} from "../../auth_profile/services/api/authorization.api";

@Component({
    selector: 'app-app-entry-point',
    templateUrl: './app-entry-point.component.html',
    styleUrls: ['./app-entry-point.component.scss'],
    standalone: false
})
export class AppEntryPointComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private provider: NativeServiceApiProvider,
    private progress: SpinnerService,
    private langService: LangService,
    private authService: AuthorizationServiceProvider) {
    // this.router.navigate(['student']).then()
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['lang']) {
        this.langService.changeLang(params['lang'])
      }
    })
    // this.router.navigate(['student']);

    this.authService.getMobile().subscribe(
      mobileApi => mobileApi.getUserRole().subscribe(
        userRole => {
          if (userRole === UserRole.Student) this.router.navigate(['student'])
          else if (userRole === UserRole.Teacher) this.router.navigate(['teacher'])
          else if (userRole === UserRole.Unaffiliated) this.router.navigate(['unaffiliated'])
          else this.router.navigate(['forbidden'])
        }
      )
    )
  }

}
