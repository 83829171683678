import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {switchMap, zip} from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import { ProfileService } from 'src/app/auth_profile/services/teacher/profile.service';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import { AppVersionService } from 'src/app/services/app-version.service';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { ThemeService } from 'src/app/services/theme.service';
import {FunctionalAccessService} from "../../utils/services/functional-access.service";
import {environment} from "../../../environments/environment";
import * as Sentry from "@sentry/angular";

@Component({
    selector: 'teacher-main-nav',
    templateUrl: './teacher-main-nav.component.html',
    styleUrls: ['./teacher-main-nav.component.scss'],
    standalone: false
})
export class TeacherMainNavComponent implements OnInit, AfterViewInit {

  @ViewChild('main', {static: true})
    public mainMenuRef: ElementRef;

  // @ViewChild('profileMenu', {static: true})
  //   public profileMenu: ElementRef;

  @Input() activeSegments: string[]
  @Input() isOffcanvas: boolean = false
  @Input()
  set theme(value: string) {
    this._theme = value
  }
  get theme() {
    return this._theme
  }
  @Input() profilePhoto?: string
  public hasBack: boolean = false;
  private nestedStack: HTMLElement[] = [];
  teacherFullName: string
  links = document.getElementsByClassName('nav-item')
  schoolName: string
  teacherSupported = false;
  disableVideo = true;
  lessonLink: string
  schoolId: number;
  teacherId: number;
  private _theme: string

  private _isAppNative = true;
  private colFunctionality = environment.colFunctionality

  constructor(private authService: AuthorizationServiceProvider,
              private profileService: ProfileService,
              public themeService: ThemeService ,
              private provider: NativeServiceApiProvider,
              private authProvider: AuthorizationServiceProvider,
              functionalityAccessService: FunctionalAccessService,
              public appVersionService : AppVersionService
  ) {
    functionalityAccessService.getAccessState().subscribe(
      accessList => {
        this.disableVideo = accessList.videoDisabled
      }
    )
    this.authProvider.getCommonRoutines().subscribe(auth =>
      this._isAppNative = auth.isNativeAuthorization()
    )
  }

  /**
   * if native supports the casa profile use profile to get teacher name and school
   * @private
   */
   private loadProfileData() {
    this.profileService.getSelfPerson().subscribe(self => {
      this.teacherFullName = PersonUtils.getPersonName(self.details)
    })
    this.profileService.getSelfSchool().subscribe( school => {
      this.schoolName = school.details.name
      this.schoolId = school.id
    })
  }

  /**
   * if native doesn't support casa just load in an old way
   * @private
   */
  private loadAppData() {
    this.authService.getAuthDetailsService().pipe(
      switchMap( api => api.getUserName())
    ).subscribe(
      userName => {
        this.teacherFullName = userName
        this.schoolName = ""
      }
    )
  }

  ngOnInit(): void {
    this.authService.getAuthDetailsService().pipe(
      switchMap(api =>
        zip(api.getSelfSchoolId(), api.getSelfTeacherId())
      ),
    ).subscribe(([schoolId, teacherId]) => {
      this.schoolId = schoolId
      this.teacherId = teacherId
    })

    for (let i = 0; i < this.links.length; i++) {
      this.links[i].addEventListener("click", () => {
        if(this.nestedStack) this.nestedStack.pop();
        this.hasBack = false
      })
    }
    this.provider.casa().subscribe( api => {
      if (api.isImplemented()) {
        this.teacherSupported = true;
        this.loadProfileData();
      } else {
        this.loadAppData();
      }
    })
  }

  ngAfterViewInit(): void {
    const feedback = Sentry.feedbackIntegration();
    const buttons = document.querySelectorAll("#reportABugButton")
    if (buttons.length && !Array.from(buttons).every(button => button.hasAttribute("data-feedback-attached"))) {
      buttons.forEach(button => {
        feedback.attachTo(button)
        button.setAttribute("data-feedback-attached", "true")
      })
    }
  }

  getTeacherName() {
    return this.teacherFullName
  }

  getTeacherRole() {
    return this.schoolName? `${this.schoolName} teacher` : ''
  }

  getDismiss() {
    return this.isOffcanvas && "offcanvas"
  }

  isHidden(menu: HTMLElement) {
    if (!this.nestedStack.length && menu === this.mainMenuRef.nativeElement)
      return false;
    return this.nestedStack[this.nestedStack.length - 1] !== menu;
  }

  isActivePath(segment: string) {
    return (this.activeSegments[1] === 'teacher' &&
      this.activeSegments[2].includes(segment))
  }

  onOpen(menu: HTMLElement) {
    this.nestedStack.push(menu);
    this.hasBack = true;
  }

  onBack() {
    this.nestedStack.pop();
    this.hasBack = this.nestedStack.length > 0;
  }

  onClose(): void {
    if(this.nestedStack)
      this.nestedStack.pop();
    this.hasBack = false
  }

  onLogout() {
    this.authService.getCommonRoutines().subscribe(service => service.logout())
  }

  getAvatar() {
    return {background: this.profilePhoto? `url('${this.profilePhoto}')` : null}
  }

  isCallanOnlineTeacher() {
    return this.schoolId === 1
  }

  isColFunctionalityOn() {
    return this.colFunctionality && !this._isAppNative
  }

  isAppNative() {
    return this._isAppNative
  }

  getLessonLink() {
    if(this.schoolId == 1)
      return `./col-lessons/school/${this.schoolId}/teacher/${this.teacherId}`
    else
      return `./lessons`
  }
}
