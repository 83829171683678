import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-contact-us-entry-point',
    templateUrl: './contact-us-entry-point.component.html',
    styleUrls: ['./contact-us-entry-point.component.scss'],
    standalone: false
})
export class ContactUsEntryPointComponent implements OnInit {

  constructor(private router: Router) {
    this.router.navigate(['in-app/contact-us'])
  }

  ngOnInit(): void {
  }

}
