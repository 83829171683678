import { Component, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs';
import { Page, Pageable } from 'src/app/utils/pageable';
import {
  ApiLessonBundle,
  ApiLessonCommit,
  StudentCommitsFilter,
} from '../../../model/rest-model';
import { StudentRestServiceImpl } from '../../../services/student/student-rest-impl.service';

export class WalletRow {
  date: Date;
  action: string;
  bundleName: string;
  amount: number;
  balance: number;

  constructor(date: Date, action: string, bundleName: string, amount: number) {
    this.date = date;
    this.action = action;
    this.bundleName = bundleName;
    this.amount = amount;
  }
}

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss'],
    standalone: false
})
export class TransactionsComponent implements OnInit {
  pageable: Pageable = new Pageable(0, 5, ['lessonCommit.updateDate,desc']);
  private searchFilter: StudentCommitsFilter;
  availableLessons: number;
  viewRows: WalletRow[];
  allListedResults: Page<ApiLessonCommit>;
  isLoading = true;
  _langCode: string

  @Input() studentId: number;
  @Input() set langCode(langCode: string) {
    this._langCode = langCode
    this.getAvailableStudentLessonBundles();
  }
  get langCode() {
    return this._langCode
  };

  constructor(private studentRest: StudentRestServiceImpl) {}

  ngOnInit(): void {
    this.getAvailableStudentLessonBundles();
  }

  getAvailableStudentLessonBundles() {
    return this.studentRest
      .getStudentLessonBundles(
        this.studentId,
        this.langCode,
        Pageable.of(0, 100, null)
      )
      .pipe(
        tap((availableBundlesPage) => {
          this.availableLessons = 0;
          availableBundlesPage.content.forEach(
            (b) => (this.availableLessons += b.available)
          );
          this.searchFilter = new StudentCommitsFilter(
            this.langCode,
            null,
            null,
            null
          );
        })
      )
      .subscribe((_) => {
        this.getCommits();
        this.isLoading = false;
      });
  }

  getCommits(): any {
    this.studentRest
      .getCommits(this.studentId, this.searchFilter, this.pageable)
      .subscribe((page) => {
        this.allListedResults = page;
        this.calculateViewRows();
      });
  }

  calculateViewRows() {
    this.viewRows = [];
    const bundles: { [id: number]: ApiLessonBundle } = {};

    this.allListedResults.content.forEach(
      (lessonCommit) => (bundles[lessonCommit.bundle.id] = lessonCommit.bundle)
    );

    for (const bundle in bundles) {
      if (bundles.hasOwnProperty(bundle)) {
        const element = bundles[bundle];
        this.viewRows.push(
          new WalletRow(
            element.date,
            'purchased',
            element.name,
            element.registeredLessons
          )
        );
      }
    }

    this.allListedResults.content.forEach((commit) => {
      if (commit.status === 'PENDING') {
        this.viewRows.push(
          new WalletRow(commit.createDate, 'reserved', commit.bundle.name, -1)
        );
      } else if (commit.status === 'CANCELLED') {
        this.viewRows.push(
          new WalletRow(commit.createDate, 'reserved', commit.bundle.name, -1)
        );
        this.viewRows.push(
          new WalletRow(commit.updateDate, 'cancelled', commit.bundle.name, +1)
        );
      } else {
        this.viewRows.push(
          new WalletRow(commit.updateDate, 'finished', commit.bundle.name, -1)
        );
      }
    });

    this.viewRows = this.viewRows.sort((l, r): number => {
      return new Date(r.date).getTime() - new Date(l.date).getTime();
    });

    let currentlyAvailable = this.availableLessons;
    this.viewRows.forEach((r) => {
      r.balance = currentlyAvailable;
      currentlyAvailable -= r.amount;
    });
  }

  onPageChange($event) {
    this.pageable = $event;
    this.getAvailableStudentLessonBundles();
  }

  getActionName(viewRow: WalletRow) {
    return `COL.COURSES.CREDITS.ACTIONS.${viewRow.action.toUpperCase()}`;
  }
}
