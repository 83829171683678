import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../services/spinner.service';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: false
})
export class SpinnerComponent implements OnInit {

  isLoading = true
  constructor(private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.spinner.isLoading$.subscribe(isLoading =>
      this.isLoading = isLoading)
  }

}
