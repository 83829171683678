import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-contact-us-result-page',
    templateUrl: './contact-us-result-page.component.html',
    styleUrls: ['./contact-us-result-page.component.scss'],
    standalone: false
})
export class ContactUsResultPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}