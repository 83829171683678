import {formatDate} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  Address,
  ContactEntry,
  ContactType,
  FileItem,
  Gender,
  Language,
  PersonDetails,
  StudentPersonDetails
} from '../../model/casa/casa-model';
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {EMPTY, map, switchMap} from "rxjs";
import {UploadEventType, UploadProgressEvent, UploadResponse} from "../../services/api/casa-native-api";
import {AuthorizationServiceProvider} from "../../services/authorization-service.provider";
import {ModalComponent} from "../../../utils/modal/modal.component";


@Component({
    selector: 'personal-profile-form',
    templateUrl: './personal-profile-form.component.html',
    styleUrls: ['./personal-profile-form.component.scss'],
    standalone: false
})
export class PersonalProfileFormComponent implements OnInit {
  countries: string[]
  reasonsForStudy: string[]
  private _profile: PersonDetails | StudentPersonDetails
  _phone: ContactEntry
  _skype: ContactEntry
  uploadProgress: number;

  @ViewChild('deleteAccountInfoModal', { static: true })
  deleteAccountInfoModal: ModalComponent;
  @Input()
  saved: boolean = false

  @Input() email

  @Input()
  role: "student" | "teacher"

  @Input()
  busy: boolean

  @Input()
  set profile(value: PersonDetails | StudentPersonDetails) {
    this._profile = value;
    if (value) {
      if (!value.contacts) value.contacts = [];
      if (!value.address) value.address = new Address();
      this.decomposeContacts();
      this.convertBirthDate();
    }
  }

  get profile(){
    return this._profile;
  }

  get phoneNumber() {
    return this.profile.contacts.find( c => c.entryType === ContactType.PHONE).value;
  }

  set phoneNumber(value: string) {
    this._phone = this.profile.contacts.find( c => c.entryType === ContactType.PHONE);
    this._phone.isDefault = true
    this._phone.name = "Phone"
    this._phone.value = value
  }

  get skypeInfo() {
    return this.profile.contacts.find( c => c.entryType === ContactType.SKYPE).value;
  }

  set skypeInfo(value: string) {
    this._skype = this.profile.contacts.find( c => c.entryType === ContactType.SKYPE);
    this._skype.isDefault = true
    this._skype.name = "skype"
    this._skype.value = value
  }

  @Output()
  savePerson = new EventEmitter<PersonDetails | StudentPersonDetails>();

  constructor(
    private provider: NativeServiceApiProvider,
    private authProvider: AuthorizationServiceProvider
    ) { }


  ngOnInit(): void {
    this.provider.casa().pipe(
      switchMap( api => api.getCountries())
    ).subscribe(
      countries => this.countries = countries.map(it => it.name)
    )
  }

  private decomposeContacts() {
    if (!this.profile) return;
    let contacts = this.profile.contacts;
    if (contacts == null) {
      contacts = [];
      this.profile.contacts = contacts;
    }
    this._phone = contacts.find( c => c.entryType === ContactType.PHONE);
    if (this._phone == null) {
      this._phone = new ContactEntry();
      this._phone.entryType = ContactType.PHONE;
      this._phone.name = "Phone";
      contacts.push(this._phone);
    }
    this._skype = contacts.find( c => c.entryType === ContactType.SKYPE);
    if (this._skype == null) {
      this._skype = new ContactEntry();
      this._skype.entryType = ContactType.SKYPE;
      this._skype.name = "Skype";
      contacts.push(this._skype);
    }
  }

  private convertBirthDate() {
    if (this.profile && this.profile.birthDate) { // && isNumeric(this.profile.birthDate)
      this.profile.birthDate = formatDate(this.profile.birthDate, "yyyy-MM-dd", this.profile.locale) as unknown as number;
    }
  }

  isBusy() {
    return this.busy
  }

  isDisabled() {
    return this.busy
  }

  isStudentForm() {
    return this.role === 'student'
  }

  onSubmit() {
    return this.savePerson.emit(this.profile)
  }

  getStudentProfile() {
    return this._profile as StudentPersonDetails
  }

  getProfilePhoto() {
    return this._profile.photo? 'url(' + this._profile.photo?.publicAccessUrl +')' : null
  }

  getCountries() {
    return this.countries
  }

  getLanguages() {
    return Object.keys(Language)
  }

  getGenders() {
    return [Gender.Female, Gender.Male, Gender.Other]
  }

  getLanguageByCode(code: string) {
    return Language[code]
  }

  getButtonContent() {
    return this.saved? 'BUTTON.PROFILE_SAVED' : 'BUTTON.SAVE'
  }

  onImageUpload(event:any) {
    let files = event.target.files
    if (!event.target.files || !event.target.files[0]) return;
    this.provider.casa().pipe(
      switchMap( api => {
        if (!api.supportsFileSending()) return EMPTY;
        this.uploadProgress = 0;
        return api.uploadFile(files[0])
      })
    ).subscribe(event => {
        if (event.eventType === UploadEventType.Progress) {
          const eventAsProgress = event as UploadProgressEvent<FileItem>
          this.uploadProgress = Math.round(100 * eventAsProgress.loaded / eventAsProgress.total);
        } else if (event.eventType === UploadEventType.Result) {
          this.profile.photo = (event as UploadResponse<FileItem>).body;
          this.uploadProgress = null;
        }
      }, () => this.uploadProgress = null);
  }
  isImageUploadSupported() {
    return this.provider.casa().pipe(
      map( api => api.supportsFileSending())
    )
  }

  getUsersLocale(defaultValue: string): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }

  deleteAccount() {
    let locale = this.getUsersLocale('en')
    this.provider.casa().pipe(
      switchMap(api => api.deleteSelf(locale)),
    ).subscribe(_ => this.deleteAccountInfoModal.show())
  }

  logout() {
    this.authProvider.getCommonRoutines()
      .subscribe(service => service.logout())
  }
}
