import {Component, Input, OnInit} from '@angular/core';
import {ExerciseSessionQuestion} from "../../../../cspa/model/cspa/personal";
import {Audio, AudioAnswer} from "../../../../cspa/model/cspa/questions";

@Component({
    selector: 'app-audio-result',
    templateUrl: './audio-result.component.html',
    styleUrls: ['./audio-result.component.scss'],
    standalone: false
})
export class AudioResultComponent  implements OnInit {
  @Input() questions: ExerciseSessionQuestion<AudioAnswer, Audio> []
  constructor() {
  }

  ngOnInit(): void {
  }


  getQuestion(q: ExerciseSessionQuestion<AudioAnswer, Audio>) {
    return q.question.definition.audioTranscription
  }

  getStudentAnswer(q: ExerciseSessionQuestion<AudioAnswer, Audio>) {
    return q.answer?.answer?.map(a => a.val.join("; ")) || "-"
  }

  getCorrectAnswer(q: ExerciseSessionQuestion<AudioAnswer, Audio>) {
    return q.question.definition.answer.answer.map(a => a.val.join(", ")).join("; ")
  }

  isAnswerIncorrect(q: ExerciseSessionQuestion<AudioAnswer, Audio>) {
    return q.score == 0 && q.answer
  }
}
