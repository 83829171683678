import { ErrorHandler, inject, NgModule, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TypographyComponent } from './pages/typography/typography.component';
import { StylingComponent } from './pages/styling/styling.component';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { StudentDashboardLayoutComponent } from './layouts/student-dashboard-layout/student-dashboard-layout.component';
import { StudentMainNavComponent } from './components/student-main-nav/student-main-nav.component';
import { UtilsModule } from './utils/utils.module';
import { ProfileModule } from './auth_profile/profile.module';
import { BooksModule } from './books/books.module';
import { CspaModule } from './cspa/cspa.module';
import { HttpErrorsInterceptorService } from './utils/services/interceptor.service';
import { environment } from 'src/environments/environment';
import { JwtInterceptorService } from './auth_profile/services/jwt-interceptor.service';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeHr from '@angular/common/locales/hr';
import localeJa from '@angular/common/locales/ja';
import localeRu from '@angular/common/locales/ru';
import localePt from '@angular/common/locales/pt';
import localeUk from '@angular/common/locales/uk';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactUsPageComponent } from './pages/contact-us/contact-us-page/contact-us-page.component';
import { ContactUsComponent } from './pages/contact-us/contact-us/contact-us.component';
import { ContactUsResultPageComponent } from './pages/contact-us/contact-us-result-page/contact-us-result-page.component';
import { ContactUsResultComponent } from './pages/contact-us/contact-us-result/contact-us-result.component';
import { StudentContactUsPageComponent } from './pages/contact-us/student-contact-us-page/student-contact-us-page.component';
import { FormsModule } from '@angular/forms';
import { MobileModule } from './mobile/mobile.module';
import { LessonsModule } from './lessons/lessons.module';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { NotificationListComponent } from './pages/notifications/notification-list/notification-list.component';
import { NotificationDetailsComponent } from './pages/notifications/notification-details/notification-details.component';
import { FaqListComponent } from './pages/faq/faq-list/faq-list.component';
import { AppEntryPointComponent } from './pages/app-entry-point/app-entry-point.component';
import { TeacherDashboardLayoutComponent } from './layouts/teacher-dashboard-layout/teacher-dashboard-layout.component';
import { TeacherMainNavComponent } from './components/teacher-main-nav/teacher-main-nav.component';
import { TeacherContactUsPageComponent } from './pages/contact-us/teacher-contact-us-page/teacher-contact-us-page.component';
import { TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { ColModule } from './col/col.module';
import { UnaffiliatedDashboardLayoutComponent } from './layouts/unaffiliated-dashboard-layout/unaffiliated-dashboard-layout.component';
import { UnaffiliatedMainNavComponent } from './components/unaffiliated-main-nav/unaffiliated-main-nav.component';
import {DisableRightClickDirective} from "./directives/disable-right-click.directive";
import { SessionInfoService } from './services/session-info.service';

registerLocaleData(localePl)
registerLocaleData(localeEs)
registerLocaleData(localeIt)
registerLocaleData(localeDe)
registerLocaleData(localeHr)
registerLocaleData(localeJa)
registerLocaleData(localeRu)
registerLocaleData(localePt)
registerLocaleData(localeUk)


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({ 
    declarations: [
        AppComponent,
        TypographyComponent,
        StylingComponent,
        DashboardComponent,
        StudentDashboardLayoutComponent,
        StudentMainNavComponent,
        ContactUsPageComponent,
        ContactUsComponent,
        ContactUsResultPageComponent,
        ContactUsResultComponent,
        StudentContactUsPageComponent,
        ForbiddenComponent,
        NotificationListComponent,
        NotificationDetailsComponent,
        FaqListComponent,
        AppEntryPointComponent,
        TeacherDashboardLayoutComponent,
        TeacherMainNavComponent,
        TeacherContactUsPageComponent,
        UnaffiliatedDashboardLayoutComponent,
        UnaffiliatedMainNavComponent,
        DisableRightClickDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        UtilsModule,
        ProfileModule.forRoot(environment),
        BooksModule,
        CspaModule,
        BrowserAnimationsModule,
        FormsModule,
        MobileModule,
        LessonsModule,
        ColModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'en'
        })], 
        providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        TranslateStore,
        SessionInfoService,
        provideHttpClient(withInterceptorsFromDi()),
        provideAppInitializer(() => {
            const traceService = inject(Sentry.TraceService)
        })
    ],
    bootstrap: [AppComponent] 
})
export class AppModule { }
