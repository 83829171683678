import {Component, OnDestroy, OnInit} from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from "@angular/router";
import {Subject, filter, forkJoin, switchMap, take, takeUntil} from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import { ProfileService } from 'src/app/auth_profile/services/student/profile.service';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import { ThemeService } from 'src/app/services/theme.service';
import {NativeServiceApiProvider} from "../../services/native-api-provider.service";
import {LogsService} from "../../utils/services/logs.service";
import {LangService} from "../../services/lang.service";
import { OffcanvasMenuService } from 'src/app/services/offcanvas-menu.service';

@Component({
    selector: 'app-student-dashboard-layout',
    templateUrl: './student-dashboard-layout.component.html',
    styleUrls: ['./student-dashboard-layout.component.scss'],
    standalone: false
})
export class StudentDashboardLayoutComponent implements OnInit, OnDestroy {

  activePath: string
  activeSegments: string[]
  studentFullName: string
  schoolName: string
  theme: string
  studentSupported = false;
  profilePhoto

  menuType: string
  private unsubscribe = new Subject<void>()

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private authService: AuthorizationServiceProvider,
    public themeService: ThemeService,
    public provider: NativeServiceApiProvider,
    public logs: LogsService,
    private langService: LangService,
    private offcanvasMenuService: OffcanvasMenuService
  ) {
    router.events.pipe(
      filter((e: Event): e is NavigationStart | NavigationEnd | NavigationCancel | NavigationError => e instanceof NavigationStart || 
             e instanceof NavigationEnd || 
             e instanceof NavigationCancel || 
             e instanceof NavigationError)
            ).subscribe((e: RouterEvent) => {
              this.activePath = e['urlAfterRedirects']
              this.activePath? this.activeSegments = this.activePath.split('/') : ''
            });

    this.langService.initialize()
  }

  /**
   * if native supports the casa profile use profile to get student name and school
   * @private
   */
  private loadProfileData() {
    forkJoin([
      this.profileService.getSelfPerson().pipe(take(1)),
      this.profileService.getSelfSchool().pipe(take(1))
    ]).subscribe({
      next: ([self, school]) => {
        this.studentFullName = PersonUtils.getPersonName(self.details)
        this.profilePhoto = self?.details.photo?.publicAccessUrl || null
        this.schoolName = school.details.name
      },
      error: err => {
        this.logs.error("got error when asking for self profile data in student dashboard", err);
        this.loadAppData();
      }
    });
  }

  /**
   * if native doesn't support casa just load in an old way
   * @private
   */
  private loadAppData() {
    this.authService.getAuthDetailsService().pipe(
      switchMap( api => api.getUserName())
    ).subscribe(
      userName => {
        this.studentFullName = userName
        this.schoolName = ""
      }
    )
  }

  ngOnInit(): void {
    this.provider.casa().subscribe( api => {
        if (api.isImplemented()) {
          this.studentSupported = true;
          this.loadProfileData();
        } else {
          this.loadAppData();
        }
      }
    )
    this.themeService.getTheme().subscribe(theme => this.theme = theme)
    this.offcanvasMenuService.getCurrentMenu().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(menuType => this.menuType = menuType)
  }

  ngOnDestroy(){
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  getStudentName(){
    return this.studentFullName
  }

  getStudentRole() {
    return this.schoolName? `${this.schoolName} student` : ''
  }

  onLogout() {
    this.authService.getCommonRoutines().subscribe(service => service.logout())
  }

  getAvatar() {
    return {background: this.profilePhoto? `url('${this.profilePhoto}')` : null}
  }

}
