import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq-list',
    templateUrl: './faq-list.component.html',
    styleUrls: ['./faq-list.component.scss'],
    standalone: false
})
export class FaqListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
