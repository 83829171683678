import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterDefinition, CourseFilter} from "../../model/CourseFilters";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseService} from "../../services/course.service";
import {Subscription, zip} from "rxjs";

@Component({
    selector: 'app-course-filter',
    templateUrl: './course-filter.component.html',
    styleUrls: ['./course-filter.component.scss'],
    standalone: false
})
export class CourseFilterComponent implements OnInit {

  @Output() languageEmitter = new EventEmitter<FilterDefinition>()
  @Input() showAll = false
  @Input()
  set chosenCourseUpdate(lang: FilterDefinition) {
    if(lang && lang.code != this.chosenCourse.code)
      this.setCourse(lang)
  }
  chosenCourse: FilterDefinition
  languageSubscription: Subscription

  constructor(private router: Router,
              private route: ActivatedRoute,
              private courseService: CourseService) {}

  ngOnInit(): void {
    this.languageSubscription = zip(
      this.courseService.getCourse(),
      this.route.queryParams
    ).subscribe(([course, params]) => {
      this.chosenCourse = this.getFilter(course.filter)
      if(params['language'] != null) {
        this.chosenCourse = this.getFilter(params['language'])
      }
      if(course.code != this.chosenCourse.code && !(!this.showAll && course.code == "EN3" && this.chosenCourse.code == "EN")) {
        this.courseService.changeCourse(this.chosenCourse)
      }

      this.languageEmitter.emit(this.chosenCourse)
      this.filter()
    })
    this.languageSubscription.unsubscribe()

  }

  filter() {
    if (!this.chosenCourse.filter) {
      this.router.navigate([]).then();
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {language: `${this.chosenCourse.filter}`},
        queryParamsHandling: 'merge',
      }).then();
    }
  }

  setCourse(course: FilterDefinition) {
    this.chosenCourse = this.getFilter(course.filter)
    this.courseService.changeCourse(this.chosenCourse)
    this.languageEmitter.emit(this.chosenCourse)
    this.filter()

  }

  getFilters() {
    if(!this.showAll)
      return CourseFilter.filtersShort
    return CourseFilter.filters
  }

  getFilter(filterKey: string) {
    if(filterKey == 'all') return CourseFilter.filters[0]
    if(!this.showAll) {
      let _filterKey = filterKey
      if(filterKey == 'en3') _filterKey = 'en'
      else if(filterKey == 'en.ch') _filterKey = ''
      return CourseFilter.filtersShort.find(lang => _filterKey == lang.filter)
    }
    return CourseFilter.filters.find(lang => filterKey == lang.filter)
  }

  getLanguageTranslation(lang: FilterDefinition) {
    if(!lang) return ''
    return "EBOOKS.FILTER_LANGS." + this.getFilter(lang.filter).translationCode
  }

  isActive(filter: FilterDefinition) {
    return filter && this.chosenCourse && filter.code === this.chosenCourse?.code;
  }

  static getLanguageTranslation(lang: FilterDefinition) {
    if(!lang) return ''
    return "EBOOKS.FILTER_LANGS." + lang?.translationCode
  }

  static getFilters() {
    return CourseFilter.filters
  }
  static getFiltersShort() {
    return CourseFilter.filtersShort
  }
}
