import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { PasswordChangeRequest } from '../../model/casa/casa-model';
import {tap, finalize, switchMap} from 'rxjs'
import {AuthorizationServiceProvider} from "../../services/authorization-service.provider";

class FormData {
  username: string;
  password: string;
  passwordVerify: string;
}

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: false
})
export class ForgotPasswordComponent implements OnInit {

  formData = new FormData();
  inProgress: boolean = false
  isChanged: boolean = false
  errorMessage: string = null
  private sendCloseOnFinish: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiProvider: AuthorizationServiceProvider) { }

  ngOnInit(): void {
    this.sendCloseOnFinish = this.route.snapshot.data['sendCloseEventOnFinish']
  }

  onSubmit() {
    const request = new PasswordChangeRequest();
    request.email = this.formData.username;
    request.password = this.formData.password;

    this.apiProvider.getCommonRoutines().pipe(
      switchMap( api => api.changePassword(request)),
      tap( _ => this.inProgress = true),
      finalize( () => { this.inProgress = false })
    ).subscribe(
        {
          next: () => this.isChanged = true,
          error: () => {
            this.errorMessage = 'Something was wrong. Check internet connection or try again later';
          }
        }
    )
  }

  onCancel() {
    this.apiProvider.getCommonRoutines().subscribe(
      api => {
        if (api.isNativeAuthorization() && this.sendCloseOnFinish) {
          //api.close().subscribe();
        } else {
          this.router.navigate(
            ['../login'],
            {
              relativeTo: this.route,
              queryParamsHandling: 'merge'
            }
          )
        }
      }
    )
  }

  getErrorMessage() {
    return this.errorMessage;
  }

  isDifferentPassword() {
    return this.formData.password !== this.formData.passwordVerify
  }

  passwordIsChanged() {
    return this.isChanged;
  }

  isInProgress() {
    return this.inProgress;
  }
}
