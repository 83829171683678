import {Component, Input, OnInit} from '@angular/core';
import {ApiLearningUnitTeacher, ApiLessonInstance, ApiLessonStatus, ApiPersonalProfile} from "../../model/rest-model";
import {LessonTypeUtils} from "../../utils/lesson-utils";
import {PersonUtils} from "../../../auth_profile/utils/person-utils";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {interval, Subscription} from "rxjs";
import {Dates, TimeUnits} from "../../../utils/calendar-utils";

@Component({
    selector: 'app-lesson-card',
    templateUrl: './lesson-card.component.html',
    styleUrls: ['./lesson-card.component.scss'],
    standalone: false
})
export class LessonCardComponent implements OnInit {

  @Input() lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>
  @Input() redirectPathPrefix: string

  isSkype: boolean;
  roomUrl: SafeResourceUrl;
  plannedAt: Date;
  isComing: boolean;
  isStarted: boolean;
  shouldStart: boolean;
  mayStart: boolean;
  timeSubscription: Subscription;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.timeSubscription = interval(1000)
      .subscribe(_=> this.prepareDetails())
  }

  getEndTime() {
    return this.lesson.lessonMetric.plannedStartDate.getTime() + 30 * 60 * 1000
  }

  getScheduleBgClass() {
    const lessonStatus = ApiLessonStatus[this.lesson.lessonStatus];
    switch (lessonStatus) {
      case ApiLessonStatus.Booked:
        return 'bg-warning'
      case ApiLessonStatus.Complete:
        return 'bg-success'
      case ApiLessonStatus.Due:
        return 'bg-warning'
      case ApiLessonStatus.InProgress:
        return 'bg-info'
      case ApiLessonStatus.InvalidatedByTeacher:
      case ApiLessonStatus.Cancel:
      case ApiLessonStatus.InvalidatedByStudent:
        return 'bg-secondary'
      default:
        return '';
    }
  }

  getNumberOfParticipants() {
    return (this.lesson.students?.length || 0)  + 1
  }

  getTeacherName() {
    return PersonUtils.getPersonName(this.lesson.teacher.person)
  }

  getScheduleState() {
    return LessonTypeUtils.getStudentLessonStatus(this.lesson);
  }

  prepareDetails(): any {
    this.plannedAt = null;
    this.isComing = false;
    this.isStarted = false;
    this.shouldStart = false;
    this.mayStart = false;
    this.prepareRoomInfo();
    if (!this.lesson) {return; }
    const lessonStatus = ApiLessonStatus[this.lesson.lessonStatus];
    this.plannedAt = new Date(this.lesson.lessonMetric.plannedStartDate);
    this.isComing = lessonStatus === ApiLessonStatus.Booked || lessonStatus === ApiLessonStatus.Due;
    this.isStarted = lessonStatus === ApiLessonStatus.InProgress;
    this.shouldStart = this.isComing && Dates.diff(this.plannedAt, new Date()) > TimeUnits.Minutes(3).toMilis();
    this.mayStart = this.isComing && Dates.diff(new Date(), this.plannedAt) < TimeUnits.Minutes(5).toMilis();
  }

  prepareRoomInfo(): any {
    this.roomUrl = null;
    this.isSkype = false;
    if (!this.lesson || !this.lesson.roomUrl) {return; }
    if (this.lesson.roomUrl.startsWith('skype:')) {
      this.isSkype = true;
      const url = this.lesson.roomUrl + '?call';
      this.roomUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else if (this.lesson.roomUrl.startsWith('video_room_url:')) {
      const url = this.lesson.roomUrl.substring('video_room_url:'.length);
      this.roomUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  getRoomUrl(): SafeResourceUrl {
    return this.roomUrl;
  }

  mayEnterClassroom(): boolean {
    return this.roomUrl && (this.mayStart || this.shouldStart || this.isStarted);
  }

  mayEnterRoomBySkype(): boolean {
    return this.mayEnterClassroom() && this.isSkype;
  }

  mayEnterVideoRoom(): boolean {
    return this.mayEnterClassroom() && !this.isSkype;
  }
}
