import {Component, Input, OnInit} from '@angular/core';
import {RoomProgressReport} from "../../models/video.model";
import {Page} from "../../../utils/pageable";

@Component({
    selector: 'app-group-progress-history',
    templateUrl: './group-progress-history.component.html',
    styleUrls: ['./group-progress-history.component.scss'],
    standalone: false
})
export class GroupProgressHistoryComponent implements OnInit {

  @Input() progressHistory: Page<RoomProgressReport>

  constructor() { }

  ngOnInit(): void {}

  fix(val: string) {
    if (!val || val.trim().length == 0) return null;
    return val.trim();
  }

  showMissing(val: string, missingValue?: string) {
    if (!missingValue) missingValue = '-';
    const res = this.fix(val);
    if (res == null) return missingValue;
    return res;
  }

  getTeacherName(progress: RoomProgressReport) {
    return progress.teacher.name;
  }

  getCreated(progress: RoomProgressReport) {
    return progress.created;
  }

  getAttendance(progress: RoomProgressReport) {
    const numberOfStudents = progress.attenders.length;
    const attendantStudents = progress.attenders.filter( p => p.attended).length;
    return `A: ${attendantStudents} / ${numberOfStudents}`;
  }

  getHistoryItemHeader(historyItem: RoomProgressReport) {
    let res = this.showMissing(historyItem.details.stage, "missing stage") + " - ";
    res += this.getTeacherName(historyItem);
    return res;
  }

  getLessonType(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.lessonType, "missing type");
  }

  getReadingLesson(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.readingLesson);
  }

  getPara(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.paragraph);
  }

  getStart(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.startPage);
  }

  getHw(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.lastHw);
  }

  getReading(historyItem: RoomProgressReport) {
    let res = this.fix(historyItem.details.reading);
    if (res != null) return `up to p. ${res}`;
    return null;
  }

  getDict(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.dictation);
  }
}
