import {AfterViewChecked, Component, Input, OnInit} from '@angular/core';
import {
  HybridState,
  RoomTemplateBase,
  RoomTemplateDetailsBase,
  ScheduleCalendarDisplay,
  ScheduleRowSimplified
} from "../../models/video.model";
import {SchedulesListProvider} from "../schedules-list/schedules-list.component";
import {DateUtils} from "../../utils/date-utils";
import {SpinnerService} from "../../../utils/services/spinner.service";
import {ConflictResults, ScheduleSearchService} from "../../services/schedule-search.service";

@Component({
    selector: 'app-lessons-calendar',
    templateUrl: './lessons-calendar.component.html',
    styleUrls: ['./lessons-calendar.component.scss'],
    standalone: false
})
export class LessonsCalendarComponent implements OnInit, AfterViewChecked {

  private _provider: SchedulesListProvider
  conflicts: ConflictResults;
  @Input() dayStart: Date
  @Input() dayEnd: Date
  @Input()
  set schedulesProvider(provider: SchedulesListProvider) {
    this._provider = provider;
    this.reloadData();
  }

  conflictedSchedules: Set<number> = new Set();
  toShow:  ScheduleCalendarDisplay;
  @Input() schoolId: number;

  constructor(private spinnerService: SpinnerService, private scheduleSearchService: ScheduleSearchService) { }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData() {
    if (!this._provider) return;
    this.spinnerService.trace<ScheduleRowSimplified[]>(
      this._provider.listSchedules(this.dayStart.getTime())
    ).subscribe(rows => {
      this.toShow = this.prepareDisplayCalendar(rows)
      this.conflicts = this.scheduleSearchService.searchForConflicts(rows);
      this.findConflictedEvents();
    })
  }

  ngAfterViewChecked() {
    this.getCalendarStartPosition()
  }

  private findConflictedEvents() {
    this.conflictedSchedules.clear();
    this.conflicts.byClass.concat(this.conflicts.byTeacher)
      .forEach( it => {
          this.conflictedSchedules.add(it.left.schedule.id);
          this.conflictedSchedules.add(it.right.schedule.id);
        }
      )
  }

  private prepareDisplayCalendar(sortedDataSet: ScheduleRowSimplified[]) {
    const dates = new Set<number>();
    const rowsByDay = sortedDataSet.reduce((result: Map<number, ScheduleRowSimplified[]>, element) => {
      const dayDate = DateUtils.getDayStartDate(new Date(element.schedule.details.startDate)).getTime();
      dates.add(dayDate);
      let dayEvents = result.get(dayDate);
      if (!dayEvents) {
        dayEvents = [];
        result.set(dayDate, dayEvents);
      }
      dayEvents.push(element);
      return result;
    }, new Map<number,ScheduleRowSimplified[]>());

    return new ScheduleCalendarDisplay(
      Array.from(dates).sort(),
      rowsByDay
    )
  }

  private isOffline(row: ScheduleRowSimplified) {
    if (row.schedule.details.hybridState === HybridState.Offline) return true;
    return row.schedule.details.hybridState == null
      && row.template.details.hybridState === HybridState.Offline;
  }

  private getCalendarStartPosition() {
    let schedules = document.body.getElementsByClassName("bg-calendar")
    if(schedules.length) {
      let schedule = (schedules[0] as HTMLElement)
      let tableHeader = document.getElementById("table-header")

      document.getElementById("calendar")
        .scrollTo({
          left: 0,
          top: (schedule.offsetParent as HTMLElement).offsetTop - tableHeader.clientHeight
        })
    }
  }

  getCalendarDisplayViewDay(day: number): ScheduleRowSimplified[] {
    const rowsByDay = (this.toShow as ScheduleCalendarDisplay).rowsByDay;
    if (!rowsByDay) return [];
    const rows = rowsByDay.get(day);
    if (!rows) return [];
    return rows;
  }

  dataIsEmpty() {
    return !this.toShow?.daysSorted?.length
  }

  getWeekDates() {
    let weekDays = [this.dayStart?.getTime()]
    let date = this.dayStart?.getTime()
    for (let i = 0; i < 6; i++) {
      date = date + 24 * 60 * 60 * 1000
      weekDays.push(date)
    }
    return weekDays
  }

  isScheduleInDateTime(schedule, hour, dayIndex) {
    return (new Date(schedule.schedule.details.startDate)).getHours() == hour
      && new Date(schedule.schedule.details.startDate).toDateString() === new Date(this.getWeekDates()[dayIndex]).toDateString()
  }

  getBadgeColorClassByStatus(row: ScheduleRowSimplified) {
    if (this.conflictedSchedules.has(row.schedule.id)) {
      return 'bg-danger';
    }

    switch(row.schedule.state) {
      case 'COMPLETE': return 'bg-success';
      case 'PLANED': return 'bg-warning';
      case 'PENDING': return 'bg-info';
      case 'IN_PROGRESS': return 'bg-info';
    }
    return '';
  }

  getTooltipContent(row: ScheduleRowSimplified) {
    if (this.conflictedSchedules.has(row.schedule.id)) {
      return 'Conflict detected';
    }

    switch(row.schedule.state) {
      case 'COMPLETE': return 'LESSONS.LESSON_STATUS.COMPLETE';
      case 'PLANED': return 'LESSONS.LESSON_STATUS.PLANED';
      case 'PENDING': return 'LESSONS.LESSON_STATUS.PENDING';
      case 'IN_PROGRESS': return 'LESSONS.LESSON_STATUS.IN_PROGRESS';
    }
    return '';
  }

  getEndDate(row: ScheduleRowSimplified) {
    if (!row || !row.schedule || !row.schedule.details.durationMin) return 0;
    return row.schedule.details.startDate + row.schedule.details.durationMin * 60 * 1000;
  }

  getPlace(row: ScheduleRowSimplified) {
    if (!row || !row.schedule) return null;
    if (row.schedule.details.place) return row.schedule.details.place;
    return row.template.details.place;
  }

  hasAnyOffline(schedule: ScheduleRowSimplified) {
    return (this.isOffline(schedule) || schedule.schedule.details.participants.find( p => p.offline));
  }

  getGroupName(group: RoomTemplateBase<RoomTemplateDetailsBase>) {
    return group.details.name;
  }
}
