import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'dateTranslation',
    standalone: false
})
export class DateTranslationPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}
  transform(value: Date | string, format = 'mediumDate'): string {
    const datePipe = new DatePipe(this.translate.currentLang || 'en')
    return datePipe.transform(value, format)
  }

}
