import {Component, OnInit, ViewChild} from '@angular/core';
import {finalize, forkJoin, map, switchMap, tap, zip} from 'rxjs';
import {AuthorizationServiceProvider} from 'src/app/auth_profile/services/authorization-service.provider';
import {CalendarEntry, ClickEvent, LessonType} from 'src/app/col/model/calendar';
import {
  ApiCourseProduct,
  ApiLearningUnitStudent,
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiPerson,
  ApiPersonalProfile,
  ApiTeacherProfile,
  RecurringType,
  RecurringTypeUtils,
} from 'src/app/col/model/rest-model';
import { TeacherRestServiceImpl } from 'src/app/col/services/teacher/teacher-rest-impl.service';
import { LangProductMapper } from 'src/app/col/utils/lang-mappers';
import { Dates } from 'src/app/utils/calendar-utils';
import { ModalComponent } from 'src/app/utils/modal/modal.component';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import {
  LocalDateTime,
  ProductAvailabilityDetails,
  ProductAvailabilityRequest,
  ScheduleDefinition,
  SimpleProductAvailability,
  SimpleScheduleEvents
} from "../../../model/rest-model-v2";
import { Page, Pageable } from 'src/app/utils/pageable';
import { Router } from '@angular/router';

@Component({
    selector: 'app-teacher-main-calendar',
    templateUrl: './teacher-main-calendar.component.html',
    styleUrls: ['./teacher-main-calendar.component.scss'],
    standalone: false
})
export class TeacherMainCalendarComponent implements OnInit {
  weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  @ViewChild('createWorktimeModal', { static: true })
  createWorktimeModal: ModalComponent;
  @ViewChild('availableWorktimeModal', { static: true })
  availableWorktimeModal: ModalComponent;

  private _teacherId: number;
  _focusDate: Date;
  loading = true;
  calendarEntries: CalendarEntry[] = [];
  profile: ApiTeacherProfile;
  availableProducts: string[];

  availabilityToDelete: SimpleProductAvailability;
  deleteRecurring = false;
  allProductsCode = '__all__';
  private eventIdToDelete: number;
  eventChosen: CalendarEntry;

  newWorktimeDate: Date;
  newWorktimeFinishTime: string;
  newWorktimeProduct: string = this.allProductsCode;
  newWorktimeStartTime: string;
  newWorktimeDays: { [dayName: string]: boolean };
  studentById: { [studentId: number]: ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>> } = {};
  newWorktimeOvertake = 0;

  newWorktimeRecurring = RecurringType.Single;
  newRecurringFinishDate: Date;
  recurringTypes = RecurringType;
  recurringUtils = RecurringTypeUtils;
  minRecurring = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);

  pageable: Pageable;
  pageablePast: Pageable;
  langCode: string
  pastLessons: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  bookedLessons: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  isLoading: boolean
  dateNow: Date
  schoolId: number

  set focusDate(date: Date) {
    this._focusDate = date;
    this.loadWeekEvents();
  }

  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.loadWeekEvents();
    this.loadTeacherCompetences();
    this.loadLessons()
  }

  constructor(
    private teacherRest: TeacherRestServiceImpl,
    private auth: AuthorizationServiceProvider,
    private spinner: SpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.auth
      .getAuthDetailsService()
      .pipe(
        switchMap(authDetailsApi => 
          forkJoin({
            schoolId: authDetailsApi.getSelfSchoolId(),
            teacherId: authDetailsApi.getSelfTeacherId()    
          })
        )
      )
      .subscribe(({schoolId, teacherId}) => {
        this.schoolId = schoolId
        this.teacherId = teacherId
      })
    }

  loadTeacherCompetences() {
    if (!this._teacherId) {
      return;
    }

    this.spinner
      .trace<ApiTeacherProfile>(this.teacherRest.getProfile(this._teacherId))
      .subscribe((profile) => {
        this.profile = profile;
        this.availableProducts = profile.competences.map(
          (pCompetence) => pCompetence.product.code
        );
      });
  }

  onCalendarClick(event: ClickEvent) {
    if (!event.entry) {
      this.openWorktimeDialog(event.clickDate);
    } else if (event.entry.relatedObject instanceof SimpleProductAvailability) {
      this.availabilityToDelete = event.entry.relatedObject;
      this.eventChosen = event.entry
      this.eventIdToDelete = event.entry.eventId
      this.deleteRecurring = false;
      this.availableWorktimeModal.show();
    } else if (event.entry.classes[0].includes("schedule")){
      this.dateNow = new Date()
      const pastOrBooked = this.dateNow > event.entry.dateFrom ? "past" : "booked"
      this.loadStudents(this.calendarEntries.filter(calendarEntry => calendarEntry.id === event.entry.id))
      const lessonId = event.entry.relatedObject.events[0].eventData.lesson.lessonId
      this.router.navigateByUrl(
          this.getLessonLink(lessonId, this.getStudentId(lessonId, pastOrBooked))
        )
      
    }
  }

  openWorktimeDialog(clickDate: Date): any {
    if (clickDate.getTime() < new Date().getTime()) {
      return;
    }
    this.newWorktimeDate = Dates.roundDateIntoMiddleOfClick(clickDate);
    this.newWorktimeStartTime = this.getTimeStr(this.newWorktimeDate);

    const finishingDate = new Date(
      this.newWorktimeDate.getTime() + 1000 * 60 * 60 * 8
    );
    this.newWorktimeFinishTime = this.getTimeStr(finishingDate);
    this.newWorktimeDays = {};
    RecurringTypeUtils.days.forEach( day => this.newWorktimeDays[day] = true );
    this.createWorktimeModal.show();
  }

  getTimeStr(date: Date): string {
    return (
      date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0')
    );
  }

  loadWeekEvents() {
    if (!this._teacherId || !this._focusDate) {
      return;
    }
    this.loading = true;
    this.spinner
      .trace<CalendarEntry[]>(
        zip(
          this.teacherRest
            .listAvailabilities(this._teacherId, this._focusDate)
            .pipe(
              map((worktimesArray) =>
                this.mapSimpleProductAvailabilityToCalendarEntry(worktimesArray)
              )
            ),
          this.teacherRest
            .listLessons(this._teacherId, this._focusDate)
            .pipe(
              map((schedulesArray) =>
                this.mapSimpleScheduleEventsToCalendarEntry(schedulesArray)
              ),
              tap((schedulesArray) => this.loadStudents(schedulesArray))
            )
        ).pipe(
          map(([worktimeEntries, scheduleEntries]) =>
            worktimeEntries.concat(scheduleEntries)
          ),
          tap((calendarEntries) => (this.calendarEntries = calendarEntries)),
          finalize(() => (this.loading = false))
        )
      )
      .subscribe();
  }

  loadStudents(schedulesArray: CalendarEntry[]): void {
    const studentIds = schedulesArray
      .map((schedule) => schedule.relatedObject as ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>)
      .map((lesson) => lesson.students)
      .filter((studentsArray) => studentsArray && studentsArray.length > 0)
      .reduce(
        (
          src: Array<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>,
          arg: Array<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>
        ) => src.concat(arg),
        []
      )
      .map((student) => student.id);

    const studentsToDownload = Array.from(new Set(studentIds)).filter(
      (studentId) => !this.studentById[studentId]
    );

    this.teacherRest
      .queryForStudentsById(this._teacherId, studentsToDownload)
      .subscribe((studentsPage) =>
        studentsPage.content.forEach(
          (student) => (this.studentById[student.id] = student)
        )
      );
  }

  loadLessons(){
    this.pageable = new Pageable(0, 100, [
      'lesson.teacher.metricDetails.plannedStartDate,ASC',
    ]);
    this.pageablePast = new Pageable(0, 100, [
      'lesson.teacher.metricDetails.plannedStartDate,DESC',
    ]);
      this.teacherRest.getLessons(
        this._teacherId,
        this.pageable,
        LessonType.Upcoming,
        this.langCode).subscribe(lessons => (this.bookedLessons = lessons))
      this.teacherRest.getLessons(
          this._teacherId,
          this.pageablePast,
          LessonType.Past,
          this.langCode).subscribe(lessons => (this.pastLessons = lessons))
  }

  getStudentId(lessonId: number, pastOrBooked: string){
    const lesson = (pastOrBooked == "past" ? this.pastLessons : this.bookedLessons).content.filter(lesson => lesson.id === lessonId)
    return lesson[0]?.students[0]?.id
  }

  getLessonLink(lessonId: number, studentId: number) {
    if(this.schoolId == 1)
      return `teacher/col-lessons/school/${this.schoolId}/teacher/${this._teacherId}/lesson/${lessonId}/student/${studentId}`
    else
      return `teacher/panel/lessons/lesson/${lessonId}/student/${studentId}`
  }

  getAvailabilityName(av: SimpleProductAvailability) {
    return `${av.details.product.code}[${av.details.competences.map(c => c.code).join(", ")}]`
  }

  mapSimpleProductAvailabilityToCalendarEntry(schedules: SimpleProductAvailability[]): CalendarEntry[] {
    let entries = new Array<CalendarEntry>()
    schedules.forEach(schedule => schedule.events.forEach(ev => {
      const dateTo = new Date();
      dateTo.setTime(ev.eventDate.getTime() + ev.duration);
      let entry = new CalendarEntry(
        schedule.schedule.id,
        ev.eventId,
        ev.eventDate,
        dateTo,
        () => this.getAvailabilityName(schedule),
        null,
        schedule,
        'worktime'
      )
      if(schedules.length > 1)
        entry = entry.narrowIntersected()

      entries.push(entry);
    }))
    return entries
  }
  mapSimpleScheduleEventsToCalendarEntry(schedules: SimpleScheduleEvents[]): CalendarEntry[] {
    let entries = new Array<CalendarEntry>()
    schedules.forEach(schedule => schedule.events.forEach(ev => {
      const dateTo = new Date();
      dateTo.setTime(ev.eventDate.getTime() + ev.duration);
      let entry = new CalendarEntry(
        schedule.schedule.id,
        ev.eventId,
        ev.eventDate,
        dateTo,
        () => schedule.schedule.name,
        null,
        schedule,
        'schedule'
      )
      entries.push(entry);
    }))
    return entries
  }

  //modals
  deleteWorktime() {
    this.loading = true;
    this.teacherRest
      .deleteAvailability(
        this._teacherId,
        this.availabilityToDelete.schedule.id,
        this.eventIdToDelete,
        this.deleteRecurring
      )
      .subscribe({
        next: () => {
          this.availabilityToDelete = null;
          this.loadWeekEvents();
        },
        error: () => (this.loading = false),
      });
    this.availableWorktimeModal.hide();
  }

  saveWorktime() {
    this.loading = true;
    const productsArray =
      this.newWorktimeProduct === this.allProductsCode
        ? this.availableProducts
        : [this.newWorktimeProduct];

    forkJoin([
      ...productsArray.map((pCode) => this.saveWorktimeForProduct(pCode)),
    ]).subscribe({
      next: () => this.loadWeekEvents(),
      error: () => (this.loading = false),
    });

    this.createWorktimeModal.hide();
  }

  getProductName(code: string): string {
    return LangProductMapper.mapLangCodeToLangName(code);
  }

  saveWorktimeForProduct(targetProduct: string) {
    const startDate = this.setTimeWithStr(
      this.newWorktimeDate,
      this.newWorktimeStartTime
    );
    let finishDate = this.setTimeWithStr(
      this.newWorktimeDate,
      this.newWorktimeFinishTime
    );

    // if the hour is less than starting time move date one day forward
    if (finishDate.getTime() < startDate.getTime()) {
      finishDate = this.setTimeWithStr(
        new Date(this.newWorktimeDate.getTime() + 1000 * 60 * 60 * 24),
        this.newWorktimeFinishTime
      );
    }

    const request = this.prepareWorktimeDefinition(
      targetProduct,
      startDate,
      finishDate
    );

    return this.teacherRest.createAvailability(this._teacherId, request);
  }

  prepareWorktimeDefinition(
    targetProduct: string,
    startDate: Date,
    finishDate: Date
  ) {
    const duration = finishDate.getTime() - startDate.getTime();

    let request = new ProductAvailabilityRequest()
    request.availabilityDetails = new ProductAvailabilityDetails()
    request.availabilityDetails.product = new ApiCourseProduct()
    request.availabilityDetails.product.code = targetProduct
    request.availabilityDetails.competences = this.profile.competences.find( pc => pc.product.code === targetProduct).competences;
    request.availabilityDetails.overtake = this.newWorktimeOvertake
    request.scheduleDetails = new ScheduleDefinition()
    request.scheduleDetails.recurring = this.createRecurringString()
    request.scheduleDetails.ending = this.newRecurringFinishDate
    request.scheduleDetails.duration = duration
    request.scheduleDetails.time = new LocalDateTime()
    request.scheduleDetails.time.starting = startDate
    request.scheduleDetails.time.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return request
  }

  createRecurringString() {
    let weekDays = []
    for (const dayName in this.newWorktimeDays) {
      if (this.newWorktimeDays.hasOwnProperty(dayName)) {
        const isActive = this.newWorktimeDays[dayName];
        if (!isActive) { continue; }
        +        weekDays.push(dayName);
      }
    }
    return RecurringTypeUtils.getRecurring(this.newWorktimeRecurring, weekDays)
  }


  setTimeWithStr(date: Date, time: string) {
    const timeSplit = time.split(':');
    const hour = Number(timeSplit[0]);
    const minutes = Number(timeSplit[1]);

    const res = new Date(date);
    res.setHours(hour);
    res.setMinutes(minutes);

    return res;
  }

  getWeekDayName(day: string) {
    return `WEEKDAY.${day.toUpperCase()}`;
  }

  getCourse(){
    return this.eventChosen?.relatedObject?.details?.product?.name
  }
}
