import {Component, OnInit} from '@angular/core';
import {QuestionComponentBase} from "../../../../../model/QuestionComponentBase";
import {Audio as QAudio, AudioAnswer, PlaceholderAnswer} from "../../../../model/cspa/questions";
import {QuestionService} from "../../../../services/api/impl/rest/question.service";
import {LogsService} from "../../../../../utils/services/logs.service";
import {AuthorizationServiceProvider} from "../../../../../auth_profile/services/authorization-service.provider";
import {environment} from "../../../../../../environments/environment";
import {QuestionActionRequest, QuestionActionRequestWithAnimation, QuestionActionRequestWithValue} from "../../../../../model/prototypes";
import {Subscription} from "rxjs";
import {ShortcutService} from "../../../../services/shortcut.service";

class TokenAnswer {
  constructor(public content: string) {}
}

@Component({
    selector: 'app-audio',
    templateUrl: './audio.component.html',
    styleUrls: ['./audio.component.scss'],
    standalone: false
})
export class AudioComponent extends QuestionComponentBase<AudioAnswer, QAudio> implements OnInit {

  questionAnswers: TokenAnswer[];
  selectedAnswer: TokenAnswer
  audio= new Audio()
  qaRequestStartPulsating: (QuestionActionRequestWithAnimation | QuestionActionRequestWithValue)[] = [
    {
      text: 'speaker',
      assetUrl: 'volume-up',
      value: this.audio.volume * 100
    },
    {
      text: 'play',
      assetUrl: 'play-arrow-filled',
      animation: 'pulsating'
    }
  ]
  qaRequestStartNoPulsating: (QuestionActionRequestWithAnimation | QuestionActionRequestWithValue)[] = [
    {
      text: 'speaker',
      assetUrl: 'volume-up',
      value: this.audio.volume * 100
    },
    {
      text: 'play',
      assetUrl: 'play-arrow-filled',
      animation: 'none'
    }
  ]
  qaRequestPause: (QuestionActionRequest | QuestionActionRequestWithValue)[] = [
    {
      text: 'speaker',
      assetUrl: 'volume-up',
      value: this.audio.volume * 100
    },
    {
      text: 'pause',
      assetUrl: 'pause'
    }
  ]
  qaRequestStop: (QuestionActionRequest | QuestionActionRequestWithValue)[] = [
    {
      text: 'speaker',
      assetUrl: 'volume-up',
      value: this.audio.volume * 100
    },
    {
      text: 'stop',
      assetUrl: 'play-arrow-disabled'
    }
  ]
  disableAnswerButton: boolean
  private shortcutButtonSubscription: Subscription;

  constructor(private questionService: QuestionService,
              logs: LogsService,
              private shortcut: ShortcutService,
              authProvider: AuthorizationServiceProvider) {
    super(logs, authProvider);
  }

  ngOnInit(): void {
    this.sendRequest(this.qaRequestStartPulsating)
    this.prepareQuestionTokens()
    this.inProgressEmitter.emit(true)
    this.questionSubscription = this.questionService.answerRequest.subscribe(sendAnswer =>
      sendAnswer &&
      sendAnswer['answerType'] === 'audio' &&
      sendAnswer['getNewAnswer'] === true &&
      this.getAnswerForSubmit()
    )
    this.shortcutButtonSubscription = this.shortcut.btnRequest.subscribe(btn => {
      btn.includes('speaker') && this.setAudioVolume(+btn.split(':')[1])
      btn === 'play' && this.play()
      btn === 'pause' && this.stop()
    })
    this.disableAnswerButton = true
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.stop()
    if (this.shortcutButtonSubscription) this.shortcutButtonSubscription.unsubscribe()

  }

  isButtonDisabled(){
    return this.disableAnswerButton
  }

  sendRequest(audioRequest: QuestionActionRequest[]) {
    this.updateActionMenu.emit(audioRequest)
  }

  getAnswerForSubmit() {
    this.mapTokensOnAnswers();
    this.sendAnswer()
  }

  mapTokensOnAnswers() {
    if (!this.questionAnswers) {
      return;
    }

    this.answer.answer = [];
    const res = new PlaceholderAnswer();
    res.name = "1";
    res.val = [this.selectedAnswer.content];
    this.answer.answer = [res];
  }

  setUpAnswer(answer: AudioAnswer) {
    if (answer?.answer) {
      this.answer = answer;
    } else {
      this.answer = new AudioAnswer();
      this.answer.answer = [];
    }
    this.mapAnswerOnTokens();
  }

  getContent(token: any) {
    return token.content
  }

  mapAnswerOnTokens() {
    if (!this.questionAnswers || !this.answer) {
      return;
    }

    this.answer.answer
      .filter(ae => ae.name && ae.val && ae.val.length === 1)
      .forEach( ae => {
        const targetAnswer = this.questionAnswers.find( qa => qa.content === ae.val[0]);
        if (!targetAnswer) {
          return;
        }
        this.assignAnswer(targetAnswer);
      });
  }

  assignAnswer(tokenAnswer: any) {
    this.selectedAnswer = tokenAnswer
    this.inProgressEmitter.emit(false)
  }

  getAudioSrc() {
    return environment.awsBase + this.question.question.definition.audio + ".mp3";
  }

  prepareQuestionTokens(): void {
    this.questionAnswers = this.question.question.definition.answers
      .map( answer => new TokenAnswer(answer));

    this.mapAnswerOnTokens();
  }

  play() {
    (<QuestionActionRequestWithValue>this.qaRequestStartNoPulsating[0]).value = this.audio.volume * 100;
    (<QuestionActionRequestWithValue>this.qaRequestPause[0]).value = this.audio.volume * 100
    this.sendRequest(this.qaRequestPause)
    this.audio.src = this.getAudioSrc();
    this.audio.onended = () => {
      this.sendRequest(this.qaRequestStop)
      this.disableAnswerButton = false
    };
    this.audio.load();
    this.audio.play().then(
      null,
      () => this.sendRequest(this.qaRequestStartNoPulsating)
    );
  }

  stop() {
    (<QuestionActionRequestWithValue>this.qaRequestStartNoPulsating[0]).value = this.audio.volume * 100;
    this.sendRequest(this.qaRequestStartNoPulsating)
    this.audio.pause()
  }

  setAudioVolume(volume: number) {
    if (!volume) return
    (<QuestionActionRequestWithValue>this.qaRequestStartNoPulsating[0]).value = volume;
    (<QuestionActionRequestWithValue>this.qaRequestPause[0]).value = volume;
    if (this.audio) this.audio.volume = volume / 100
  }
}
