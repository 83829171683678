import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Schedule, ScheduleRow, ScheduleRowSimplified} from "../../models/video.model";
import {SchedulesListProvider} from "../schedules-list/schedules-list.component";
import {SpinnerService} from "../../../utils/services/spinner.service";

@Component({
    selector: 'app-lessons-list',
    templateUrl: './lessons-list.component.html',
    styleUrls: ['./lessons-list.component.scss'],
    standalone: false
})
export class LessonsListComponent implements OnInit {

  schoolId: number
  teacherId: number
  scheduleRows: ScheduleRow[]
  private _provider: SchedulesListProvider
  private _dayStart: number

  @Input()
  set schedulesProvider(provider: SchedulesListProvider) {
    this._provider = provider;
    this.loadData();
  }
  @Input()
  set dayStart(dayStart: Date) {
    this._dayStart = dayStart.getTime()
  }

  constructor(private route: ActivatedRoute, private spinner: SpinnerService) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      this.schoolId = +params.get("schoolId")
      this.teacherId = +params.get("teacherId")
      this.loadData()
    })
  }

  loadData() {
    if (!this._provider) return;
    this.spinner.trace<ScheduleRow[]>(this._provider.listSchedules(
      this._dayStart
    )).subscribe(scheduleRows => this.scheduleRows = scheduleRows)
  }

  getScheduleStartTime(schedule: Schedule){
    return schedule.details.startDate
  }

  getScheduleEndTime(schedule: Schedule){
    return schedule.details.startDate + schedule.details.durationMin * 60 * 1000
  }

  getBadgeColorClassByStatus(row: ScheduleRowSimplified) {
    switch(row.schedule.state) {
      case 'COMPLETE': return 'bg-success';
      case 'PLANED': return 'bg-warning';
      case 'PENDING': return 'bg-info';
      case 'IN_PROGRESS': return 'bg-info';
    }
    return '';
  }

  getBadgeTranslationByStatus(row: ScheduleRowSimplified) {
    return `LESSONS.LESSON_STATUS.${row.schedule.state}`

  }
}
