import { Component, Input, OnInit } from '@angular/core';
import { Observable, zip, tap } from 'rxjs';
import {
  ChapterAvailability,
  ItemAvailability,
} from 'src/app/cspa/model/cspa/personal';
import { Chapter, ExerciseSet } from 'src/app/cspa/model/cspa/struct';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { StudentRestServiceImpl } from '../../../services/student/student-rest-impl.service';
import { LangProductMapper } from '../../../utils/lang-mappers';

@Component({
    selector: 'app-exercise-set-host',
    templateUrl: './exercise-set-host.component.html',
    styleUrls: ['./exercise-set-host.component.scss'],
    standalone: false
})
export class ExerciseSetHostComponent implements OnInit {
  availability: ItemAvailability;
  chapter: Chapter;
  private _langCode: string;
  availableChapters: Chapter[];
  availabilityByPath: Map<string, ItemAvailability>;
  chaptersByPath: Map<string, Chapter>;
  selectedChapter: Chapter;
  prevChapter: Chapter;
  selectedIndex = 0;
  nextChapter: Chapter;
  setDefinition: ExerciseSet;
  isLoading = true;

  @Input() set langCode(langCode: string) {
    this._langCode = langCode;
    if (langCode)
      this.loadExerciseSetData(
        LangProductMapper.mapLangCodeToLangCodeCspa(this.langCode)
      ).subscribe();
  }
  get langCode() {
    return this._langCode;
  }

  constructor(
    private spinner: SpinnerService,
    private studentRest: StudentRestServiceImpl
  ) {}

  ngOnInit(): void {}

  loadExerciseSetData(path: string): Observable<any> {
    return this.spinner.trace<any>(
      zip(
        this.studentRest.getExerciseSets(),
        this.studentRest.getChapters(path + '_'),
        this.studentRest.getAvailabilities(path + '_', 3)
      ).pipe(
        tap(
          ([setsDefinition, chaptersDefinition, availabilities]: [
            ExerciseSet[],
            Chapter[],
            ItemAvailability[]
          ]) => {
            this.prepareSetDefinition(setsDefinition);
            this.prepareChaptersDefinitionsMap(chaptersDefinition);
            const avByPath = this.prepareAvailabilitiesByPath(availabilities);
            this.extractAvailableChapters(chaptersDefinition, avByPath);
            this.selectChapter(this.findTheLatestChapter());
            this.isLoading = false;
          }
        )
      )
    );
  }

  prepareSetDefinition(setsDefinition: ExerciseSet[]) {
    this.setDefinition = setsDefinition.find((s) => s.path === this.langCode);
  }

  prepareChaptersDefinitionsMap(chaptersDefinition: Chapter[]) {
    this.chaptersByPath = new Map(
      chaptersDefinition.map((ch) => [ch.path, ch])
    );
  }

  prepareAvailabilitiesByPath(availablities: ItemAvailability[]) {
    this.availabilityByPath = new Map(availablities.map((av) => [av.path, av]));
    return this.availabilityByPath;
  }

  extractAvailableChapters(
    chaptersDefinition: Chapter[],
    avByPath: Map<string, ItemAvailability>
  ) {
    this.availableChapters = chaptersDefinition
      .sort((l, r) => l.orderNumber - r.orderNumber)
      .filter((c) => {
        const chapterAv = avByPath.get(c.path);
        return chapterAv && chapterAv.assigned && chapterAv.available;
      });
  }

  selectChapter(path: string) {
    this.prevChapter = this.nextChapter = this.selectedChapter = null;
    // iterate to find the path and fill previous and next elements also
    for (const chapter of this.availableChapters) {
      if (chapter.path === path) {
        this.selectedChapter = chapter;
        continue;
      }
      if (this.selectedChapter) {
        this.nextChapter = chapter;
        break;
      }
      this.prevChapter = chapter;
    }
    this.selectedChapter = this.chaptersByPath.get(path);
    this.selectedIndex = this.availableChapters.indexOf(this.selectedChapter);
  }

  findTheLatestChapter(): string {
    const res = this.availableChapters
      .map((ch) => this.availabilityByPath.get(ch.path))
      .filter((av) => av != null)
      .sort((l, r) => {
        const lCasted = l as ChapterAvailability;
        const rCasted = r as ChapterAvailability;
        const lSubmit = lCasted.lastSubmit ? lCasted.lastSubmit : 0;
        const rSubmit = rCasted.lastSubmit ? rCasted.lastSubmit : 0;
        return rSubmit - lSubmit;
      })[0]?.path;
    return res;
  }

  getAvailabileChapters() {
    return this.availableChapters;
  }

  getShortName() {
    return this.chapter ? this.chapter.shortName.split(' ')[1] : '';
  }

  getChapterAvailability(chapter: Chapter) {
    return this.availabilityByPath.get(chapter.path);
  }

  onChapterClick(chapter: Chapter) {
    this.selectChapter(chapter.path);
  }

  getChapterSelectionClass(chapter: Chapter) {
    if (chapter === this.selectedChapter) {
      return 'selected';
    } else if (chapter === this.prevChapter) {
      return 'prev';
    } else if (chapter === this.nextChapter) {
      return 'next';
    }
    if (this.availableChapters.indexOf(chapter) < this.selectedIndex) {
      return 'left';
    } else {
      return 'right';
    }
  }

  getChapterName() {
    return this.selectedChapter ? this.selectedChapter.shortName : '';
  }

  hasNext() {
    return !!this.nextChapter;
  }

  hasPrev() {
    return !!this.prevChapter;
  }

  onFooterInteract(event: string) {
    if (event === 'left' && this.prevChapter) {
      this.selectChapter(this.prevChapter.path);
    } else if (event === 'right' && this.nextChapter) {
      this.selectChapter(this.nextChapter.path);
    }
  }

  getSelectedChapter() {
    return this.selectedChapter;
  }

  getAvailabilities() {
    return this.availabilityByPath;
  }
}
