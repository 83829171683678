import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';
import { LangProductMapper } from '../../utils/lang-mappers';

@Component({
    selector: 'app-course-details-page',
    templateUrl: './course-details-page.component.html',
    styleUrls: ['./course-details-page.component.scss'],
    standalone: false
})
export class CourseDetailsPageComponent implements OnInit {

  langCode: string
  studentId: number
  constructor(private route: ActivatedRoute, private auth: AuthorizationServiceProvider) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params =>
      this.langCode = params.get("langCode"))
      this.auth
      .getAuthDetailsService()
      .pipe(switchMap((authDetailsApi) => authDetailsApi.getSelfStudentId()))
      .subscribe((studentId) => (this.studentId = studentId));
  }

  getCourseName() {
    return LangProductMapper.mapLangCodeToLangName(this.langCode)
  }

}
