import { Component, OnInit } from '@angular/core';
import { map, Observable, switchMap, tap} from "rxjs";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {ActivatedRoute} from "@angular/router";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {IdentifiedRoomTemplate, RoomDef, RoomProgressReport, ScheduleRow} from "../../models/video.model";
import {formatDate} from "@angular/common";
import {DateUtils} from "../../utils/date-utils";
import {VideoApi} from "../../services/video-rest.service";
import {SchedulesListProvider} from "../../components/schedules-list/schedules-list.component";
import {Page, Pageable} from "../../../utils/pageable";
import {SpinnerService} from "../../../utils/services/spinner.service";
import {RoomsListProvider} from "../../components/rooms-list/rooms-list.component";

@Component({
    selector: 'app-teacher-group-details-page',
    templateUrl: './teacher-group-details-page.component.html',
    styleUrls: ['./teacher-group-details-page.component.scss'],
    standalone: false
})
export class TeacherGroupDetailsPageComponent implements OnInit {

  schoolId: number;
  teacherId: number;
  groupId: number;
  group: IdentifiedRoomTemplate
  weekOffset: number = 0
  dayStart: Date
  dayEnd: Date
  scheduleListsProvider: SchedulesListProvider
  progressPageable = new Pageable(0, 5, ['createDate,desc'])
  progressHistory: Page<RoomProgressReport>
  roomListsProvider: RoomsListProvider

  constructor(private authProvider: AuthorizationServiceProvider,
              private route: ActivatedRoute,
              private apiProvider: NativeServiceApiProvider,
              private spinnerService: SpinnerService) {
    authProvider.getAuthDetailsService().pipe(
      switchMap(api => api.getSelfTeacherId()),
      tap(teacherId => this.teacherId = teacherId),
      switchMap(_ => route.paramMap),
      tap( params => {
        this.schoolId = Number(params.get('schoolId'))
        this.groupId = Number(params.get('groupId'))
      })
    ).subscribe()
  }

  ngOnInit(): void {
    this.spinnerService.trace(
      this.apiProvider.video().pipe(
        tap(api => {
          this.prepareDates()
          this.prepareProviders(api)
          this.getTemplateDetails(api)
          this.loadTemplateProgressHistory()
        })
      )
    ).subscribe()

  }

  private getTemplateDetails(api: VideoApi) {
    api.getTeacherTemplateDetails(
      this.schoolId, this.teacherId, this.groupId
    ).subscribe(group => this.group = group)
  }

  private prepareProviders(api: VideoApi) {
    const schoolId = this.schoolId
    const teacherId = this.teacherId
    const groupId = this.groupId
    this.scheduleListsProvider = new class implements SchedulesListProvider {
      listSchedules(focusDate: number): Observable<ScheduleRow[]> {
        return api.listTeacherSchedules(schoolId, teacherId, focusDate).pipe(
          map(schedules => schedules.filter(schedule => schedule.template.id === groupId))
        )
      }
    }
    this.roomListsProvider = new class implements RoomsListProvider {
      listRooms(pageable: Pageable): Observable<Page<RoomDef>> {
        return api.listTemplateRooms(schoolId, teacherId, groupId, pageable)
      }
    }
  }

  loadTemplateProgressHistory(pageable?: Pageable) {
    if(pageable) this.progressPageable = pageable
    this.spinnerService.trace(
      this.apiProvider.video().pipe(
        switchMap(api => api.getProgressHistory(this.schoolId, this.groupId, this.progressPageable))
      )
    ).subscribe(progressHistory => this.progressHistory = progressHistory)
  }

  getDate() {
    return `${formatDate(this.dayStart, 'd', 'en_US')} -
    ${formatDate(this.dayEnd, 'd', 'en_US')}
    ${this.dayEnd.toLocaleString('en-us', { month: 'short' })}`
  }

  goToToday() {
    this.weekOffset = 0
    this.prepareDates()
  }

  getNextWeek() {
    this.weekOffset++
    this.prepareDates()
  }

  getPreviousWeek() {
    this.weekOffset--
    this.prepareDates()
  }

  prepareDates() {
    this.dayStart = new Date(DateUtils.weekStart(DateUtils.queryDate(this.weekOffset)))
    this.dayEnd = new Date(DateUtils.weekEnd(DateUtils.queryDate(this.weekOffset)))
  }

  getRoleTranslationKey(role: string) {
    return `GROUPS.PARTICIPANT_${role}`
  }
  
}
