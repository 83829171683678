import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {interval, map, merge, Observable, Subscription, switchMap, tap} from 'rxjs';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import { Dates, TimeUnits } from 'src/app/utils/calendar-utils';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { environment } from 'src/environments/environment';
import {
  ApiLearningUnitTeacher,
  ApiLessonFlag,
  ApiLessonInstance,
  ApiLessonProgress,
  ApiLessonStatus, ApiPersonalProfile, ApiTeacherProfile,
  LessonStatusUtils,
} from '../../../model/rest-model';
import { StudentRestServiceImpl } from '../../../services/student/student-rest-impl.service';
import { Utils } from '../../../utils/lesson-utils';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ModalComponent} from "../../../../utils/modal/modal.component";
import { WebSocketService } from 'src/app/col/services/web-socket.service';

@Component({
    selector: 'app-student-lesson-details',
    templateUrl: './student-lesson-details.component.html',
    styleUrls: ['./student-lesson-details.component.scss'],
    standalone: false
})
export class StudentLessonDetailsComponent implements OnInit, OnDestroy {
  READING_RECOMENDATION = 22;
  REVISION_LESSONS = 8;

  private lessonId: number;
  private _studentId: number;
  lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  _isNext: boolean;
  progress: ApiLessonProgress;
  timePhrase: string;
  timeSubscription: Subscription;
  reloadLessonIntervalSubscription: Subscription;
  wsSubscription: Subscription


  isSkype: boolean;
  startedAt: Date = null;
  roomUrl: SafeResourceUrl;
  plannedAt: Date;
  isComing: boolean;
  isStarted: boolean;
  shouldStart: boolean;
  mayStart: boolean;
  isAfterPlannedStartDate: boolean;

  cancelable: boolean = false
  cancelReason: string
  @ViewChild('cancelLessonModal', { static: true })
  cancelLessonModal: ModalComponent;

  private teacherSkype: string;
  @ViewChild("classroomFallback", {static: true})
  classroomFallbackModal: ModalComponent
  @Input() set studentId(id: number) {
    this._studentId = id;
    this.loadLessonDetails();
    this.listenToWebSocket();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studentRest: StudentRestServiceImpl,
    private spinner: SpinnerService,
    private sanitizer: DomSanitizer,
    private webSocket: WebSocketService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.lessonId = Number(params.get('lessonId'));
      this.loadLessonDetails();
    });
    this.timeSubscription = interval(1000).pipe(
      tap( () => this.prepareDetails()),
      map(() => this.prepareTimePhrase())
    ).subscribe( phrase => this.timePhrase = phrase);
  }

  ngOnDestroy(): void {
    this.timeSubscription.unsubscribe();
    this.wsSubscription.unsubscribe();
  }

  getTeacherName() {
    return (
      this.lesson?.teacher &&
      PersonUtils.getPersonName(this.lesson.teacher.person)
    );
  }

  getInitials() {
    return (
      this.lesson?.teacher &&
      PersonUtils.getInitials(this.lesson.teacher.person)
    );
  }

  getLessonDate() {
    return this.lesson?.lessonMetric?.plannedStartDate || null;
  }

  getCourseName() {
    return this.lesson?.course.name || '';
  }

  isProva() {
    return this.lesson && this.lesson.lessonType === 'Prova';
  }

  getCourseLangName() {
    return (
      (this.lesson &&
        ((this.isSpanish() && 'COL.COURSES.SP') ||
          (this.isBusiness() && 'COL.COURSES.ENBUS') ||
          (this.isKidsEnglish() && 'COL.COURSES.ENCFK') ||
          (this.isEnglishCore() && 'COL.COURSES.EN'))) ||
      ''
    );
  }

  isEnglishCore() {
    return this.lesson?.course?.code?.startsWith('en.');
  }

  isSpanish() {
    return this.lesson?.course?.code?.startsWith('sp.');
  }

  isBusiness() {
    return this.lesson?.course?.code?.startsWith('en.bs.');
  }

  isKidsEnglish() {
    return this.lesson.course.code.startsWith('en.ch.');
  }

  listenToWebSocket(): any {
    if (!this._studentId) return
    this.wsSubscription = this.webSocket.establish(environment.lessonWsEndpoint).pipe(
      switchMap(connection => merge(
        connection.subscribe(`/col/student/${this._studentId}/LessonListUpdate`),
        connection.subscribe(`/col/student/${this._studentId}/LessonUpdate`)
      )),
    ).subscribe(() => {
      this.loadLessonDetails()
    })
  }

  loadLessonDetails(): any {
    if (!this.lessonId || !this._studentId) {
      return;
    }
    this.spinner
      .trace<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
        this.studentRest.getLesson(this._studentId, this.lessonId)
      )
      .subscribe((lesson) => {
        this.lesson = lesson;
        this.checkIfIsTheLatest();
        this.prepareDetails()
      });
  }

  checkIfIsTheLatest() {
    this.studentRest
      .findUpcomingNextLesson(this._studentId)
      .subscribe((upcoming) => {
        this._isNext = upcoming && upcoming.id === this.lesson.id;
        this.prepareProgress();
      });
  }

  prepareProgress() {
    this.studentRest
      .getStudentProgress(this._studentId)
      .pipe(
        map((progressList) => {
          let progress = progressList.find(
            (p) => p.productCode === this.lesson.course.product.code
          );
          if (!progress) {
            progress = new ApiLessonProgress();
            progress.nwp = 1;
            progress.reading = 1;
            progress.start = 1;
          }
          return progress;
        })
      )
      .subscribe((progress) => {
        progress = Utils.jsonClone(progress);
        progress.start -= this.REVISION_LESSONS;
        if (progress.start < 1) {
          progress.start = 1;
        }
        this.progress = progress;
      });
  }

  public getLessonTeacherPhotoUrl() {
    let teacherPerson = this.lesson?.teacher?.person?.personalProfile;
    return teacherPerson?.profilePhoto
      ? environment.fileEndpoint +
          '/img/' +
          teacherPerson?.profilePhoto.id +
          '?fileAccessToken=' +
          teacherPerson.profilePhoto.accessToken
      : null;
  }

  isStudentNoShow() {
    if (!this.lesson) {
      return false;
    }
    return (
      this.lesson.flags &&
      this.lesson.flags.some(
        (f) => f.flagType === ApiLessonFlag.FtStudentNotShow
      )
    );
  }

  isTutorNoShow() {
    if (!this.lesson) {
      return false;
    }
    return (
      this.lesson.flags &&
      this.lesson.flags.some(
        (f) => f.flagType === ApiLessonFlag.FtTeacherNotShow
      )
    );
  }

  isLessonStatus(...expectedStatuses: string[]): boolean {
    if (!this.lesson || !expectedStatuses) {
      return false;
    }
    return expectedStatuses.some((es) => es === this.lesson.lessonStatus);
  }

  isInvalidated() {
    return this.isLessonStatus('InvalidatedByStudent', 'InvalidatedByTeacher');
  }

  isCancelled() {
    return this.isLessonStatus('Cancel');
  }

  isFinished() {
    return this.isInvalidated() || this.isCancelled() || this.isComplete();
  }

  isInProgress() {
    return this.isLessonStatus('InProgress');
  }

  isByStudent() {
    if (!this.lesson || !this.lesson.cancellationDetails) {
      return false;
    }
    return !this.lesson.cancellationDetails.cancelledByTeacher;
  }

  isClose() {
    if (!this.lesson) {
      return false;
    }
    const timeToTheLesson = Dates.diff(
      new Date(),
      this.lesson.lessonMetric.plannedStartDate
    );
    return !(timeToTheLesson === 0 ||
      timeToTheLesson > TimeUnits.Hours(12).toMilis());

  }

  isFuture() {
    if (!this.lesson) {
      return false;
    }

    const timeToTheLesson = Dates.diff(
      new Date(),
      this.lesson.lessonMetric.plannedStartDate
    );
    return timeToTheLesson >= 0;

  }

  isComplete() {
    return this.isLessonStatus('Complete');
  }

  isNext() {
    return this._isNext;
  }

  hasCancellationDetails() {
    return this.lesson.cancellationDetails;

  }

  getMessage() {
    return this.lesson ? this.lesson.message.message : '';
  }

  getCancellationReason() {
    if (!this.lesson || !this.lesson.cancellationDetails) {
      return '';
    }
    return this.lesson.cancellationDetails.cancellationReason;
  }

  getStatusDescription() {
    if (!this.lesson) {
      return '';
    }
    const status = ApiLessonStatus[this.lesson.lessonStatus];
    let result = this.lesson.lessonStatus;
    if (status === ApiLessonStatus.Complete && this.isStudentNoShow()) {
      result = 'Student_No_Show';
    }
    return 'COL.LESSONS.DETAILS.STATUS_DESCRIPTIONS.' + result.toUpperCase();
  }

  public getRecommendedReading() {
    if (!this.progress || !this.progress.nwp) {
      return 1;
    }
    return Math.max(this.progress.nwp - this.READING_RECOMENDATION, 1);
  }

  prepareDetails(): any {
    this.startedAt = null;
    this.plannedAt = null;
    this.isComing = false;
    this.isStarted = false;
    this.shouldStart = false;
    this.mayStart = false;
    this.prepareRoomInfo();
    if (!this.lesson) {return; }
    if (this.lesson.lessonMetric.started) {
      this.startedAt = new Date(this.lesson.lessonMetric.started);
    }
    const lessonStatus = ApiLessonStatus[this.lesson.lessonStatus];
    this.plannedAt = new Date(this.lesson.lessonMetric.plannedStartDate);
    this.isComing = lessonStatus === ApiLessonStatus.Booked || lessonStatus === ApiLessonStatus.Due;
    this.isStarted = lessonStatus === ApiLessonStatus.InProgress;
    this.shouldStart = this.isComing && Dates.diff(this.plannedAt, new Date()) > TimeUnits.Minutes(3).toMilis();
    this.isAfterPlannedStartDate = this.isComing && Dates.diff(this.plannedAt, new Date()) > 0;
    this.mayStart = this.isComing && Dates.diff(new Date(), this.plannedAt) < TimeUnits.Minutes(5).toMilis();
    this.cancelable =
      LessonStatusUtils.cancelable.indexOf(lessonStatus) >= 0 &&
      !this.shouldStart &&
      !this.mayStart;
  }

  displayClassroomInfo() {
    if(!this.plannedAt) return false
    return Dates.diff(new Date(), this.plannedAt) < TimeUnits.Minutes(30).toMilis()
  }

  prepareRoomInfo(): any {
    this.roomUrl = null;
    this.isSkype = false;
    if (!this.lesson || !this.lesson.roomUrl) {return; }
    if (this.lesson.roomUrl.startsWith('skype:')) {
      this.isSkype = true;
      const url = this.lesson.roomUrl + '?call';
      this.roomUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else if (this.lesson.roomUrl.startsWith('video_room_url:')) {
      const url = this.lesson.roomUrl.substring('video_room_url:'.length);
      this.roomUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  prepareTimePhrase(): string {
    if (!this.lesson) {return ''; }
    const timeReference = this.isStarted ? this.startedAt : this.plannedAt;
    return this.toTimeStr(Dates.diffAbs(timeReference, new Date()));
  }

  toTimeStr(time: number): string {
    return Dates.toTimeStr(time);
  }

  isLessonGoingToStartRegularly(): boolean {
    return this.isComing && !this.shouldStart && !this.isAfterPlannedStartDate && !(this.roomUrl && this.mayStart);
  }

  getTeacherSkype() {
    return this.teacherSkype;
  }

  getTeacherSkypeUrl() {
    if (!this.teacherSkype) return null;
    return this.sanitizer.bypassSecurityTrustResourceUrl(`skype:${this.teacherSkype}`);
  }

  openSkypeHelp() {
    this.loadTeacherSkype().subscribe();
    this.classroomFallbackModal.show(true);
  }

  private loadTeacherSkype(): Observable<ApiTeacherProfile> {
    return this.studentRest.getTeachers(this._studentId, [this.lesson.teacher.id])
      .pipe(
        map( teachers => teachers && teachers.length > 0 ? teachers[0] : null),
        tap( teacher => teacher ? this.teacherSkype = teacher.skype : this.teacherSkype = null)
      )
  }

  mayEnterClassroom(): boolean {
    return this.roomUrl && (this.mayStart || this.shouldStart || this.isStarted);
  }

  isTeacherMissingToStartLesson(): boolean {
    return this.isAfterPlannedStartDate && !this.roomUrl;
  }

  isLessonReadyToStart(): boolean {
    return this.roomUrl && ( (this.isComing && this.mayStart) || this.shouldStart || this.isStarted);
  }

  mayEnterRoomBySkype(): boolean {
    return this.mayEnterClassroom() && this.isSkype;
  }

  mayEnterVideoRoom(): boolean {
    return this.mayEnterClassroom() && !this.isSkype;
  }

  getLessonSkypeAddress(): string {
    if (this.lesson.roomUrl.startsWith('skype:')) {
      return this.lesson.roomUrl.substring('skype:'.length);
    }
    return "missing address"
  }

  getRoomUrl(): SafeResourceUrl {
    return this.roomUrl;
  }

  moveToInstructions() {
    this.router.navigate(['student', this._studentId, 'tutorials', 'prova-booked'],
      {queryParams: {back: '/'}}).then();
  }

  cancelLesson() {
    let cancellationObservable: Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
    if (
      (this.lesson.lessonStatus === 'Booked' ||
        this.lesson.lessonStatus === 'Initializing') &&
      !this.mayStart
    ) {
      cancellationObservable = this.studentRest.cancelLesson(
        this._studentId,
        this.lessonId,
        this.cancelReason
      );
    } else if (
      this.lesson.lessonStatus === 'Due' ||
      this.lesson.lessonStatus === 'Booked'
    ) {
      cancellationObservable = this.studentRest.squanderLesson(
        this._studentId,
        this.lessonId,
        this.cancelReason
      );
    }

    if (cancellationObservable) {
      cancellationObservable
        .pipe(switchMap(() => this.loadLessonDetails()))
        .subscribe();
    }

    this.cancelLessonModal.hide();
  }
}
