import { Component, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { ApiProduct, ProductsUtils } from '../model/products';
import { StudentRestServiceImpl } from '../services/student/student-rest-impl.service';
import {StudentShoppingCartService} from "../services/student-shopping-cart.service";
import {FilterDefinition} from "../../model/CourseFilters";

@Component({
    template: '',
    standalone: false
})
export abstract class CoreProductsBase implements OnInit {
  @Input()
  set currency(currency: string) {
    this._currency = currency;
    this.refreshProducts();
  }

  _currency: string;
  _studentId: number
  _chosenCourse: FilterDefinition
  @Input()
  set chosenCourse(lang: FilterDefinition) {
    this._chosenCourse = lang
  }
  get chosenCourse() {
    return this._chosenCourse
  }
  @Input()
  set studentId(value: number) {
    this._studentId = value;
  }
  get studentId() {
    return this._studentId;
  }
  lights: ApiProduct[];
  std: ApiProduct[];
  intensive: ApiProduct[];
  pag: ApiProduct[];
  books: ApiProduct[];

  protected constructor(
    private studentRest: StudentRestServiceImpl,
    private spinner: SpinnerService,
    private shoppingCart: StudentShoppingCartService
  ) {}

  ngOnInit(): void {
    this.spinner
      .trace<ApiProduct[]>(
        this.studentRest
          .getProductsList('en', this._currency)
          .pipe(tap((products) => this.filterProducts(products)))
      ).subscribe();
  }

  abstract getEbookCategoryLabel(): string;
  abstract getPaygCategoryLabel(): string;
  abstract getIntensiveCategoryLabel(): string;
  abstract getStdCategoryLabel(): string;
  abstract getLightCategoryLabel(): string;
  abstract getProductsLabel(): string;

  filterProducts(products: ApiProduct[]): void {
    const englishProducts = ProductsUtils.filterByProductLabel(
      products,
      this.getProductsLabel()
    );
    this.lights = ProductsUtils.orderByOrderLabel(
      ProductsUtils.filterByVariantLabel(
        englishProducts,
        this.getLightCategoryLabel()
      )
    );
    this.std = ProductsUtils.orderByOrderLabel(
      ProductsUtils.filterByVariantLabel(
        englishProducts,
        this.getStdCategoryLabel()
      )
    );
    this.intensive = ProductsUtils.orderByOrderLabel(
      ProductsUtils.filterByVariantLabel(
        englishProducts,
        this.getIntensiveCategoryLabel()
      )
    );
    this.pag = ProductsUtils.orderByOrderLabel(
      ProductsUtils.filterByVariantLabel(
        englishProducts,
        this.getPaygCategoryLabel()
      )
    );

    this.books = ProductsUtils.orderByOrderLabel(
      ProductsUtils.filterByProductLabel(products, this.getEbookCategoryLabel())
    );
  }

  getImage(product: ApiProduct) {
    const imageLabel = product.productLabels.find((l) =>
      l.startsWith('image:')
    );
    if (!imageLabel) {
      return '';
    }
    return imageLabel.slice('image:'.length);
  }

  addItem(product: ApiProduct) {
    this.shoppingCart.addItem(product)
  }

  refreshProducts(): any {
    if (!this._currency) {return; }
    this.studentRest.getProductsList('EN', this._currency).subscribe(products => this.filterProducts(products));
  }
}
