import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import { Dates, TimeUnits } from 'src/app/utils/calendar-utils';
import { ModalComponent } from 'src/app/utils/modal/modal.component';
import { Pageable, Page } from 'src/app/utils/pageable';
import { SpinnerService } from 'src/app/utils/services/spinner.service';
import { environment } from 'src/environments/environment';
import { LessonType } from '../../../model/calendar';
import {
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiLessonStatus,
  ApiPerson, ApiPersonalProfile, ApiPersonalProfileBaseWithPhoto,
} from '../../../model/rest-model';
import { AppEventsService } from '../../../services/app-events.service';
import { StudentRestServiceImpl } from '../../../services/student/student-rest-impl.service';
import { LessonTypeUtils } from '../../../utils/lesson-utils';

@Component({
    selector: 'app-student-lessons',
    templateUrl: './student-lessons.component.html',
    styleUrls: ['./student-lessons.component.scss'],
    standalone: false
})
export class StudentLessonsComponent implements OnInit {
  private _lessonType: LessonType;
  private _langCode: string;
  pageable: Pageable;
  lessons: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  schoolId: number = null;
  _studentId: number = null;
  isLoading = true;

  @Input()
  set studentId(value: number) {
    this._studentId = value;
    this.getLessons(this.pageable).subscribe();
  }
  get studentId() {
    return this._studentId;
  }

  @Input()
  public set lessonType(lessonType: LessonType) {
    this._lessonType = lessonType;
    this.getLessons(this.pageable).subscribe();
  }
  get lessonType() {
    return this._lessonType;
  }

  @Input()
  public set langCode(langCode: string) {
    this._langCode = langCode;
    this.reloadCurrent()
  }
  get langCode() {
    return this._langCode;
  }

  // cancel lesson
  @ViewChild('cancelLessonModal', { static: true })
  cancelLessonModal: ModalComponent;
  _clLessonToCancel: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  _clReason: string;
  scheduleReservationSubscription: Subscription;

  constructor(
    private spinner: SpinnerService,
    private studentRest: StudentRestServiceImpl,
    private router: Router,
    private appEvents: AppEventsService,
    private route: ActivatedRoute
  ) {
    if (this._lessonType === LessonType.Upcoming) {
      this.pageable = new Pageable(0, 5, [
        'lessonStudent.metricDetails.plannedStartDate,ASC',
      ]);
    } else {
      this.pageable = new Pageable(0, 5, [
        'lessonStudent.metricDetails.plannedStartDate,DESC',
      ]);
    }
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => (this.schoolId = data['schoolId']));
    if (this.appEvents) {
      this.scheduleReservationSubscription =
        this.appEvents.scheduleReservedSubject.subscribe((_) => {
          if (this._lessonType === LessonType.Upcoming) {
            this.reloadCurrent();
          }
        });
    }
  }

  public hasResults(): boolean {
    return !!this.lessons;
  }

  public getTotalResults(): number {
    if (!this.lessons) return 0;
    return this.lessons.totalElements;
  }

  private reloadCurrent() {
    this.getLessons(this.pageable).subscribe();
  }

  getLessons(pageable: Pageable): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    if (
      this.lessonType === null ||
      this.lessonType === undefined ||
      !this.studentId ||
      this.langCode == undefined
    )
      return of(null);
    this.pageable = pageable;
    return this.spinner.trace<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(
      this.studentRest
        .getLessons(
          this.studentId,
          this.pageable,
          this.lessonType,
          this.langCode
        )
        .pipe(
          tap((lessons) => (this.lessons = lessons)),
          tap((_) => (this.isLoading = false))
        )
    );
  }

  getLessonsToDisplay() {
    return this.lessons.content;
  }

  public getLessonTeacherPhotoUrl(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    let teacherPerson = lesson?.teacher?.person?.personalProfile;
    return teacherPerson?.profilePhoto
      ? environment.fileEndpoint +
          '/img/' +
          teacherPerson?.profilePhoto.id +
          '?fileAccessToken=' +
          teacherPerson.profilePhoto.accessToken
      : null;
  }

  public getLessonTeacher(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    if (!lesson.teacher || !lesson.teacher.person) {
      return '';
    }
    return PersonUtils.getPersonName(lesson.teacher.person);
  }

  getLessonStatus(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return LessonTypeUtils.getStudentLessonStatus(lesson);
  }

  public getLessonDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): Date {
    return lesson.lessonMetric.plannedStartDate;
  }

  public lessonBadgeClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return this._lessonType === LessonType.Upcoming ||
      this._lessonType === LessonType.Past
      ? LessonTypeUtils.getStudentLessonColorClass(lesson)
      : '';
  }

  openDetails(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    this.router.navigate(['lesson', lesson.id], {
      relativeTo: this.route,
    }).then();
  }

  public mayBeCancelled(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (
      this._lessonType === undefined ||
      this._lessonType !== LessonType.Upcoming
    ) {
      return false;
    }
    const lessonStatus = ApiLessonStatus[lesson.lessonStatus];
    const timeDiff = Dates.diff(
      new Date(),
      lesson.lessonMetric.plannedStartDate
    );
    const moreThanMinimumCancellationPeriod =
      timeDiff > TimeUnits.Hours(12).toMilis();
    return (
      (lessonStatus === ApiLessonStatus.Booked ||
        lessonStatus === ApiLessonStatus.Due) &&
      moreThanMinimumCancellationPeriod
    );
  }

  public showLessonCancellation(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    this._clLessonToCancel = lesson;
    this._clReason = null;
    this.cancelLessonModal.show();
  }

  getLessonTypeName() {
    return this._lessonType === LessonType.Upcoming
      ? 'COL.LESSONS.LIST.FUTURE_LESSONS'
      : 'COL.LESSONS.LIST.PAST_LESSONS';
  }

  getInitials(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    return person ? PersonUtils.getInitials(person) : '';
  }

  public cancelLesson() {
    let observable: Observable<null | ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
    if (this._clLessonToCancel.lessonStatus === 'Due')
      observable = this.studentRest.squanderLesson(
        this.studentId,
        this._clLessonToCancel.id,
        this._clReason
      );
    else
      observable = this.studentRest.cancelLesson(
        this.studentId,
        this._clLessonToCancel.id,
        this._clReason
      );
    observable
      .pipe(
        tap((_) => {
          this.appEvents.lessonsUpdated.next(this._clLessonToCancel);
          this.appEvents.creditsUpdate.next(null);
          this._clLessonToCancel = null;
        }),
        switchMap((_) => this.getLessons(this.pageable))
      )
      .subscribe();
    this.cancelLessonModal.hide();
  }
  getLessonPlannedDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.lessonMetric.plannedStartDate;
  }
  public getCancellationTeacher(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return { teacher: this.getLessonTeacher(lesson) };
  }

  onPageChange($event) {
    this.getLessons($event).subscribe();
  }
}
