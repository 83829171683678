import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthorizationServiceProvider} from '../../services/authorization-service.provider';
import {UserRole} from "../../services/api/authorization.api";
import {LogsService} from "../../../utils/services/logs.service";
import {LangService} from "../../../services/lang.service";

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
    standalone: false
})
export class RootComponent implements OnInit {

  constructor(
    private authServiceProv: AuthorizationServiceProvider,
    private router: Router,
    private route: ActivatedRoute,
    private logger: LogsService,
    private langService: LangService
    ) { }
  initialized = false;
  ngOnInit() {
    this.authServiceProv.getCommonRoutines().subscribe(() => {
      this.initialized = true;
      this.routeToUserPage();
      this.langService.initialize()
    });
  }
  public logout() {
    this.authServiceProv.getCommonRoutines().subscribe(auth => auth.logout());
  }
  routeToUserPage() {

    this.authServiceProv.getAuthDetailsService().subscribe(auth => {
      if (!auth.isTokenValid()) this.logout();
      auth.getUserRole().subscribe(
        role => {
          this.logger.log(`redirecting to the ${role} service.`)
          if (role === UserRole.Student) this.router.navigate(['student']);
          else if (role === UserRole.Teacher) this.router.navigate(['teacher']);
          else if (role === UserRole.Unaffiliated) this.router.navigate(['unaffiliated'])
          else this.router.navigate(['forbidden']);
        }
      );
    });
  }
}
